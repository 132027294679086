import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { addField } from 'ra-core';
import {
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  TextField,
  SelectInput,
  TextInput,
  FileInput,
  FileField,
  Controller,
} from 'react-admin';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const config = require('../../../config');
const DigestFetch = require('digest-fetch');
const { Readable } = require('stream');


const dropzoneProps = {
 onDrop: (acceptedFiles, rejectedFiles, event) => {
    
    const client = new DigestFetch('samagra', 'impact@scale', {
      logger: console,
    });
    
    let file = acceptedFiles[0];
    let fileName = acceptedFiles[0].name;
    let fileAsText;

    const reader = new FileReader();
    const fileStream = new Readable();
    fileStream._read = () => {}; 

    let boundary = '--------------------------';
    for (let i = 0; i < 24; i++) {
      boundary += Math.floor(Math.random() * 10).toString(16);
    }

    reader.onload = () => {
      fileAsText = reader.result;
      fileStream.push(boundary);
      fileStream.push(
        `Content-Disposition: form-data; name="form_def_file"; filename="${fileName}"\r\n`
      );
      fileStream.push(`Content-Type: text/xml \r\n\r\n`);
      fileStream.push(fileAsText);
      fileStream.push(`\r\n${boundary}--\r\n`);
      fileStream.push(null);
    };
    reader.readAsText(file);

    const headers = {
      'content-length': file.size,
      'content-type': `multipart/form-data boundary=${boundary}`,
    };

    fileStream.on("readable", () => {
      console.log(fileStream.read());
    })

    const factory = () => ({
      method: 'POST',
      body: fileStream,
      headers,
    });
  
    client
      .fetch(
        'https://cors-anywhere.herokuapp.com/http://aggregate.cttsamagra.xyz:8080/formUpload',
        { factory }
      )
      .then((resp) => resp.text())
      .then( (data) => {
        console.log(data);
      })
      .catch((e) => console.log('DIGEST FETCH', e));
  }
  }

const stringParser = (text) => {
  return encodeURIComponent(text).replace(/[!'()*]/g, function (c) {
    return `%${ c.charCodeAt(0).toString(16).toUpperCase()}`;
  });
}


const ConditionalFormBroadcastField = ({ source, record = {}, ...props }) => {
  
  const { parts = [] } = record.data || {};
  const [transformers, setTransformers] = useState(parts.length ? parts : []);
  return (
    <div>
      {transformers.map((transformer, index) => {
        return (
          <>
          <Typography variant='h6'>Component {index+1}</Typography>
          <div className='transformer-row pl-16'>            
            <div className='transformer-element'>
              <label>Component Type</label>
              <br />
              <SelectInput
                className='form-control'
                choices={config.conversationTypeChoices}
                allowEmpty
                emptyText='None'
                translateChoice={false}
                label='Type'
                variant='outlined'
                source={`data.parts[${index}].type`}
                onChange={(e) => {
                  transformers[index].type = e.target.value;
                  const t = JSON.parse(JSON.stringify(transformers));
                  setTransformers(t);
                  // props.input.onChange(t);
                }}
              />
            </div>
            <div className='transformer-element'>
              <label>Component Channel</label>
              <br/>
              <SelectInput
                label='Channel'
                variant='outlined'
                source={`data.parts[${index}].channel`}
                choices={config.conversationChannelChoices}
              />
            </div>
            <div className='transformer-element'>
              <label>Component Provider</label>
              <br/>
              <SelectInput
                label='Provider'
                variant='outlined'
                source={`data.parts[${index}].provider`}
                choices={config.conversationProviderChoices}
              />
            </div>
            {transformer.type === 'form' ? (
              <div>
                <div className='transformer-element'>
                  <label>Select Form</label>
                  <br />
                  <SelectInput
                    className='form-control'
                    choices={props.forms}
                    translateChoice={false}
                    label='Form'
                    source={`data.parts[${index}].meta.formID`}
                    onChange={(e) => {
                      transformers[index].value = `${e.target.value}`;
                      const t = JSON.parse(JSON.stringify(transformers));
                      setTransformers(t);
                      // props.input.onChange(t);
                    }}
                  />
                </div>
                <div className='transformer-element'>
                  <label>Or Upload</label>
                  <br />
                  <FileInput
                    id={index.toString()}
                    source={`data.parts[${index}].file`}
                    label='Upload file (.xml)'
                    options={dropzoneProps}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </div>
              </div>
            ) : (
              <></>
            )}
            {transformer.type === 'broadcast' ? (
              <div className='transformer-element'>
                <label>Select Template</label>
                <br />
                <select
                  className='form-control'
                  onChange={(e) => {
                    transformers[index].template = e.target.value;
                    transformers[
                      index
                    ].value = `Broadcast::${e.target.value}::${transformer.channel}::${transformer.provider}`;

                    const t = JSON.parse(JSON.stringify(transformers));
                    setTransformers(t);
                    props.input.onChange(t);
                  }}
                >
                  <option value='' />
                  <option value='broadcast_template_1'>
                    Broadcast template 1
                  </option>
                  <option value='broadcast_template_2'>
                    Broadcast template 2
                  </option>
                </select>
              </div>
            ) : (
              <></>
            )}
            {transformer.type === 'template' ? (
              <div className='transformer-element'>
                <label>Message Text</label>
                <br />
                <TextInput
                  multiline
                  label='Message'
                  variant='outlined'
                  type='text'
                  onChange={(e) => {
                    transformers[index].msgCredits = `${e.target.value.length}`;
                    transformers[index].msg = `${e.target.value}`;
                    const t = JSON.parse(JSON.stringify(transformers));
                    setTransformers(t);
                    // props.input.onChange(t);
                  }}
                  // parse={stringParser}
                  source={`data.parts[${index}].msg`}
                />
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <TextField
                        source={`data.parts[${index}].msgCredits`}   
                        label='Credit Usage (per message)'                     
                    />
                  );
                }}
                </FormDataConsumer>
              </div>
            ) : (
              <></>
            )}
          </div>
          </>
        );
      })}

      <Button
        onClick={() => {
          transformers.push({});
          setTransformers(JSON.parse(JSON.stringify(transformers)));
        }}
        variant='outlined'
        startIcon={<Icon>add</Icon>}
      >
        {transformers.length ? 'Add another component' : 'Add a conversation component'}
      </Button>
    </div>
  );
};

ConditionalFormBroadcastField.propTypes = {
  label: PropTypes.string.isRequired,
  customUpdater: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
};
const ConditionalFormBroadcastInput = addField(ConditionalFormBroadcastField);

export default ConditionalFormBroadcastInput;
// {
//   /* <div */
// }
// {
//   /*  className={`custom-json-editor ${ */
// }
// {
//   /*    config.style.hideJSONEditorHeader ? ' hidden-header ' : '' */
// }
// {
//   /*  }`} */
// }
// {
//   /* > */
// }
// {
//   /*  <Editor */
// }
// {
//   /*    value={record && record[source] ? record[source] : {}} */
// }
// {
//   /*    onChange={(param) => { */
// }
// {
//   /*      props.input.onChange(param); */
// }
// {
//   /*    }} */
// }
// {
//   /*  /> */
// }
// {
//   /* </div> */
// }
