import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { createHashHistory } from 'history';
import {
  MuiThemeProvider,
  createMuiTheme,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { store } from './e/utils/localsotage.service';
import {
  UserCreate,
  UserEdit,
  UserList,
} from './react-admin-base/resources/Users';
import {
  SSUserCreate,
  SSUserEdit,
  SSUserList,
} from './react-admin-base/resources/SamikshaUsers';

import {
  // RoleCreate,
  RoleEdit,
  RoleList,
} from './react-admin-base/resources/Role';
import {
  ResourceCreate,
  ResourceEdit,
  ResourceList,
} from './react-admin-base/resources/Resources';

import {
  SchoolCreate,
  SchoolEdit,
  SchoolList,
} from './react-admin-base/resources/School';
import {
  StudentCreate,
  StudentEdit,
  StudentList,
} from './react-admin-base/resources/Student';
import { StudentDataList } from './react-admin-base/resources/StudentData';
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
} from './react-admin-base/resources/Employee';
import { EmployeeDataList } from './react-admin-base/resources/EmployeeData';
import {
  CampaignCreate,
  CampaignEdit,
  CampaignList,
} from './react-admin-base/resources/Campaign';
import {
  SegmentCreate,
  SegmentEdit,
  SegmentList,
} from './react-admin-base/resources/Segment';

import { ElemMentorVisitList } from './react-admin-base/resources/odk/ElemMentorVisit';
import { ElemMonitorVisitList } from './react-admin-base/resources/odk/ElemMonitorVisit';
import { ElemSSAVisitList } from './react-admin-base/resources/odk/ElemSSAVisit';
import { SamparkVisitList } from './react-admin-base/resources/odk/SamparkVisit';
import { SATVisitList } from './react-admin-base/resources/odk/SATVisit';
import { SATVisitList2 } from './react-admin-base/resources/odk/SATVisit2';
import { SecMentorVisitList } from './react-admin-base/resources/odk/SecMentorVisit';
import { SecMonitorVisitList } from './react-admin-base/resources/odk/SecMonitorVisit';
import { SecSSAVisitList } from './react-admin-base/resources/odk/SecSSAVisit';
import { SLOVisitV1List } from './react-admin-base/resources/odk/SLOVisitV1';
import { SLOVisitV2List } from './react-admin-base/resources/odk/SLOVisitV2';
import { CovidEmployeesList } from './react-admin-base/resources/odk/CovidEmployees';
import { CovidStudentList } from './react-admin-base/resources/odk/CovidStudents';

import { ComponentsIcon } from './fuse-base/components/all.components';
import CustomLayout from './react-admin-base/layout';
import Error404Page from './e/pages/Error404Page';
import UnauthorizedPage from './e/pages/UnauthorizedPage';
import ExternalModule from './e/external.module';
import LoginPage from './e/pages/login.page';
import authProviderPackage from './react-admin-base/authProvider';
import createAdminStore from './react-admin-base/createAdminStore';
import dP from './react-admin-base/dataProviders';
import { MentoringFeedbackList } from './react-admin-base/resources/odk/eMentoringFeedback';

const messages = {
  en: {
    ...englishMessages,
    resources: {
      roles: {
        fields: {
          resources: 'Resources',
          permissions: 'Permissions',
        },
      },
    },
  },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en', {
  allowMissing: true,
});

const config = require('./config');
const theme = require('./fuse-base/themes/initial-theme.json');

const history = createHashHistory();
const customRoutes = [
  <Route path='/e' component={ExternalModule} icon={ComponentsIcon} />,
];
const authProvider = authProviderPackage(config.fusionAuthConsole);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataProvider: null };
    this.getPermission = this.getPermission.bind(this);
  }

  componentDidMount() {
    dP.then((dataProvider) => {
      this.setState({ dataProvider });
    });

    //@ts-ignore
    if (window && window.navigation) {
      window.navigation.addEventListener('navigate', this.handleNavigation);
    }

    setInterval(() => {
      const cookies = document.cookie.split(';');

      // Iterate over all cookies
      cookies.forEach(cookie => {
        const [name, value] = cookie.split('=').map(c => c.trim());

        // Set the modified cookie with the Secure flag
        document.cookie = `${name}=${value}; Secure; HttpOnly; SameSite=Strict; path=/;`;
      });

    }, 1000)
  }

  handleNavigation = (e) => {
    if (e?.destination?.url?.includes('login')) {
      if (localStorage.getItem('Dashboard-App/local_sql/user') || localStorage.getItem('authToken')) {
        window.location.reload();
      }
    }
  }

  getPermission = (resource, permission, role = '') => {
    if (role === 'admin') return true;

    // eslint-disable-next-line consistent-return
    return store.getItem('permissions').then((permissionsForUser) => {
      if (permissionsForUser) {
        return (
          permissionsForUser[resource] &&
          permissionsForUser[resource].indexOf(permission) > -1
        );
      }
    });
  };

  render() {
    const { dataProvider } = this.state;
    if (!dataProvider) {
      return (
        <div className='flex flex-1 flex-col items-center justify-center h-384'>
          <CircularProgress
            variant='indeterminate'
            style={{ color: '#757575' }}
          />
          <Typography
            variant='h5'
            className='text-20 mb-16'
            color='textSecondary'
            style={{ marginTop: '1rem' }}
          >
            This page is loading, just a moment please.
          </Typography>
        </div>
      );
    }

    return (
      <Provider
        store={createAdminStore({
          authProvider,
          history,
          i18nProvider,
          dataProvider,
        })}
      >
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <Admin
            i18nProvider={i18nProvider}
            catchAll={Error404Page}
            layout={CustomLayout}
            authProvider={authProvider}
            dataProvider={dataProvider}
            customRoutes={customRoutes}
            loginPage={LoginPage}
            history={history}
            title='My Admin'
          >
            {(permissions) => [
              <Resource
                name='attendance'
                list={
                  permissions['Student Temp. and Attendance'] &&
                    permissions['Student Temp. and Attendance'].indexOf('list') >
                    -1
                    ? StudentDataList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='teacher_attendance_updated'
                list={
                  permissions['Employee Temp. and Attendance'] &&
                    permissions['Employee Temp. and Attendance'].indexOf('list') >
                    -1
                    ? EmployeeDataList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='userSamiksha-school'
                list={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('list') > -1
                    ? SSUserList
                    : UnauthorizedPage
                }
                create={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('create') > -1
                    ? SSUserCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('edit') > -1
                    ? SSUserEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='userSamiksha-diet'
                list={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('list') > -1
                    ? SSUserList
                    : UnauthorizedPage
                }
                create={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('create') > -1
                    ? SSUserCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('edit') > -1
                    ? SSUserEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='userSamiksha-mentor'
                list={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('list') > -1
                    ? SSUserList
                    : UnauthorizedPage
                }
                create={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('create') > -1
                    ? SSUserCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['Samiksha Users'] &&
                    permissions['Samiksha Users'].indexOf('edit') > -1
                    ? SSUserEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='school'
                list={
                  permissions['School List'] &&
                    permissions['School List'].indexOf('list') > -1
                    ? SchoolList
                    : UnauthorizedPage
                }
                create={
                  permissions['School List'] &&
                    permissions['School List'].indexOf('create') > -1
                    ? SchoolCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['School List'] &&
                    permissions['School List'].indexOf('edit') > -1
                    ? SchoolEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='student'
                list={
                  permissions.Students &&
                    permissions.Students.indexOf('list') > -1
                    ? StudentList
                    : UnauthorizedPage
                }
                create={
                  permissions.Students &&
                    permissions.Students.indexOf('create') > -1
                    ? StudentCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions.Students &&
                    permissions.Students.indexOf('edit') > -1
                    ? StudentEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='covid-positive-student-forms'
                list={
                  permissions['COVID Positive Students'] &&
                    permissions['COVID Positive Students'].indexOf('list') > -1
                    ? CovidStudentList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='covid-positive-teacher-form-v2s'
                list={
                  permissions['COVID Positive Employees'] &&
                    permissions['COVID Positive Employees'].indexOf('list') > -1
                    ? CovidEmployeesList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='ementfeedb-v2s'
                list={
                  permissions['Mentoring Feedback Survey'] &&
                    permissions['Mentoring Feedback Survey'].indexOf('list') > -1
                    ? MentoringFeedbackList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='employees_info'
                list={EmployeeList}
                create={EmployeeCreate}
                edit={EmployeeEdit}
                icon={ComponentsIcon}
              />,
              <Resource
                name='users'
                list={
                  permissions['Manage Console Users'] &&
                    permissions['Manage Console Users'].indexOf('list') > -1
                    ? UserList
                    : UnauthorizedPage
                }
                create={
                  permissions['Manage Console Users'] &&
                    permissions['Manage Console Users'].indexOf('create') > -1
                    ? UserCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['Manage Console Users'] &&
                    permissions['Manage Console Users'].indexOf('edit') > -1
                    ? UserEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='roles'
                list={
                  permissions.Roles && permissions.Roles.indexOf('list') > -1
                    ? RoleList
                    : UnauthorizedPage
                }
                // create={
                //   permissions.Roles && permissions.Roles.indexOf('create') > -1
                //     ? RoleCreate
                //     : UnauthorizedPage
                // }
                edit={
                  permissions.Roles && permissions.Roles.indexOf('edit') > -1
                    ? RoleEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='resources'
                list={
                  permissions.Resources &&
                    permissions.Resources.indexOf('list') > -1
                    ? ResourceList
                    : UnauthorizedPage
                }
                edit={
                  permissions.Resources &&
                    permissions.Resources.indexOf('edit') > -1
                    ? ResourceEdit
                    : UnauthorizedPage
                }
                create={
                  permissions.Resources &&
                    permissions.Resources.indexOf('create') > -1
                    ? ResourceCreate
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='conversations'
                list={
                  permissions.Conversations &&
                    permissions.Conversations.indexOf('list') > -1
                    ? CampaignList
                    : UnauthorizedPage
                }
                create={
                  permissions.Conversations &&
                    permissions.Conversations.indexOf('create') > -1
                    ? CampaignCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions.Conversations &&
                    permissions.Conversations.indexOf('edit') > -1
                    ? CampaignEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='segments'
                list={
                  permissions['User Segments'] &&
                    permissions['User Segments'].indexOf('list') > -1
                    ? SegmentList
                    : UnauthorizedPage
                }
                create={
                  permissions['User Segments'] &&
                    permissions['User Segments'].indexOf('create') > -1
                    ? SegmentCreate
                    : UnauthorizedPage
                }
                edit={
                  permissions['User Segments'] &&
                    permissions['User Segments'].indexOf('edit') > -1
                    ? SegmentEdit
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='ele-mentor-visits-v1s'
                list={
                  permissions['Elementary Mentor Visit'] &&
                    permissions['Elementary Mentor Visit'].indexOf('list') > -1
                    ? ElemMentorVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='ele-monitor-visits-v4s'
                list={
                  permissions['Elementary Monitor Visit'] &&
                    permissions['Elementary Monitor Visit'].indexOf('list') > -1
                    ? ElemMonitorVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='ele-ssa-visits-v1s'
                list={
                  permissions['Elementary SSA Visit'] &&
                    permissions['Elementary SSA Visit'].indexOf('list') > -1
                    ? ElemSSAVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sampark-pro-visits-v1s'
                list={
                  permissions['Sampark Program Visit'] &&
                    permissions['Sampark Program Visit'].indexOf('list') > -1
                    ? SamparkVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sat-visits'
                list={
                  permissions['SAT Visit'] &&
                    permissions['SAT Visit'].indexOf('list') > -1
                    ? SATVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sat-visit2s'
                list={
                  permissions['SAT Visit 2'] &&
                    permissions['SAT Visit 2'].indexOf('list') > -1
                    ? SATVisitList2
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sec-mentor-visits-v1s'
                list={
                  permissions['Secondary Mentor Visit'] &&
                    permissions['Secondary Mentor Visit'].indexOf('list') > -1
                    ? SecMentorVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sec-monitor-visits-v1s'
                list={
                  permissions['Secondary Monitor Visit'] &&
                    permissions['Secondary Monitor Visit'].indexOf('list') > -1
                    ? SecMonitorVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='sec-ssa-visits-v1s'
                list={
                  permissions['Secondary SSA Visit'] &&
                    permissions['Secondary SSA Visit'].indexOf('list') > -1
                    ? SecSSAVisitList
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='slo-visits-v1s'
                list={
                  permissions['SLO Visit V1'] &&
                    permissions['SLO Visit V1'].indexOf('list') > -1
                    ? SLOVisitV1List
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
              <Resource
                name='slo-visits-v2s'
                list={
                  permissions['SLO Visit V2'] &&
                    permissions['SLO Visit V2'].indexOf('list') > -1
                    ? SLOVisitV2List
                    : UnauthorizedPage
                }
                icon={ComponentsIcon}
              />,
            ]}
          </Admin>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
