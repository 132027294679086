import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import { client } from '../../api-clients/users-client';
import PublishIcon from '@material-ui/icons/Publish';
import UpdateProcess from './UpdateProcess';

const ImportCsvModal = (props) => {
    const { showImportModal, state } = props;
    const [fileData, setFileData] = useState();
    const [error, setError] = useState();
    const [startUpdate, setStartUpdate] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(false);

    const handleCsvUpload = async (event) => {
        console.log("File:", event.target.files[0])
        let fileData;
        let fr = new FileReader();
        fr.addEventListener('load', async (e) => {
            fileData = e.target.result.split("\n");
            setFileData(fileData.slice(1));
        });
        fr.readAsText(event.target.files[0]);
    }

    const handleUserUpdates = () => {
        setStartUpdate(true);
    }

    const getSecondOption = () => {
        if (!fileData) {
            return <>
                <p style={{ color: 'red', fontWeight: 600, textAlign: 'center' }}>An error occured while uploading file</p>
                <div>
                    <div onClick={() => setFileData(null)}>Try Again</div>
                </div>
            </>
        }
        if (fileData[0]?.split(',')?.length != 12) {
            return <>
                <p style={{ color: 'red', fontWeight: 600, textAlign: 'center' }}>Please use the exported CSV only</p>
                <div>
                    <div onClick={() => setFileData(null)}>Try Again</div>
                </div>
            </>
        }
        if (fileData?.length > 2000) {
            return <>
                <p style={{ color: 'red', fontWeight: 600, textAlign: 'center' }}>Your file contains more than 2000 records which exceeds the limit. <br></br> Try again after lowering the number of records</p>
                <div>
                    <div onClick={() => setFileData(null)}>Try Again</div>
                </div>
            </>
        }
        return <>  <p>A total of <strong>{fileData?.length}</strong> users will be updated</p>
            <div>
                <div onClick={() => showImportModal(false)}>Cancel</div>
                <div onClick={() => setConfirmUpdate(true)}>Continue</div>
            </div></>
    }

    return (ReactDOM.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.modalContent + " animate__animated animate__backInUp"}>
                {/* <UpdateProcess /> */}
                {!startUpdate && !confirmUpdate && <div className={styles.fileUpload}>
                    {!fileData?.length && <p>Please upload a file to continue 📄</p>}
                    {!fileData?.length && <div>
                        <div onClick={() => showImportModal(false)}>Cancel</div>
                        <label class={styles.customFileUpload}>
                            <input type="file" accept=".csv" onChange={handleCsvUpload} />
                            Upload <PublishIcon style={{ height: 30, width: 30 }} />
                        </label>
                    </div>}
                    {fileData?.length &&
                        <div className={styles.confirmUpdate + " animate__animated animate__fadeInDown"}>
                            {getSecondOption()}
                        </div>
                    }
                </div>}
                {!startUpdate && confirmUpdate && <div className={styles.confirmUpdate + " animate__animated animate__fadeInDown"}>
                    <p style={{ color: 'red' }}>Update cannot be stopped once it's started. Continue?</p>
                    <div>
                        <div onClick={() => showImportModal(false)}>Cancel</div>
                        <div onClick={() => handleUserUpdates()}>Continue</div>
                    </div>
                </div>}
                {startUpdate && <UpdateProcess {...{ fileData, showImportModal }} />}
            </div>
        </div >, document.body)
    );
}

export default ImportCsvModal;