export const districtOptions = [
  'Ambala',
  'Bhiwani',
  'Charkhi Dadri',
  'Faridabad',
  'Fatehabad',
  'Gurugram',
  'Hisar',
  'Jhajjar',
  'Jind',
  'Kaithal',
  'Karnal',
  'Kurukshetra',
  'Mahendragarh',
  'Nuh Mewat',
  'Palwal',
  'Panchkula',
  'Panipat',
  'Rewari',
  'Rohtak',
  'Sirsa',
  'Sonipat',
  'Yamunanagar',
];
export const blockOptions = {
  Ambala: [
    'Ambala-I (City)',
    'Ambala-II (Cantt)',
    'Shehzadpur',
    'Saha',
    'Naraingarh',
    'Barara',
  ],
  Bhiwani: [
    'Siwani',
    'Loharu',
    'Bhiwani',
    'Behal',
    'Kairu',
    'Bawani Khera',
    'Tosham',
  ],
  'Charkhi Dadri': ['Baund Kalan', 'Dadri', 'Badhra'],
  Faridabad: ['Faridabad', 'Ballabgarh'],
  Fatehabad: [
    'Tohana',
    'Jakhal',
    'Bhattu Kalan',
    'Bhuna',
    'Fatehabad',
    'Ratia',
  ],
  Gurugram: ['Pataudi', 'Gurugram', 'Farukh Nagar', 'Sohna'],
  Hisar: [
    'Hisar - II',
    'Hisar - I',
    'Narnaund',
    'Agroha',
    'Adampur',
    'Bass(Hansi - II)',
    'Barwala(Hsr)',
    'Hansi',
    'Uklana',
  ],
  Jhajjar: ['Bahadurgarh', 'Matan Hail', 'Beri', 'Salhawas', 'Jhajjar'],
  Jind: [
    'Julana',
    'Uchana',
    'Pillu Khera',
    'Alewa',
    'Safidon',
    'Jind',
    'Narwana',
  ],
  Kaithal: [
    'Guhla at Cheeka',
    'Pundri',
    'Kaithal',
    'Siwan',
    'Rajaund',
    'Kalayat',
  ],
  Karnal: [
    'Nissing at Chirao',
    'Indri',
    'Gharaunda',
    'Karnal',
    'Nilokheri',
    'Assandh',
  ],
  Kurukshetra: ['Babain', 'Pehowa', 'Thanesar', 'Ladwa', 'Shahabad'],
  Mahendragarh: [
    'Ateli',
    'Narnaul',
    'Mahendergarh',
    'Kanina',
    'Nangal Choudhary',
  ],
  'Nuh Mewat': ['Ferozepur Jhirka', 'Nagina', 'Taoru', 'Nuh', 'Punhana'],
  Palwal: ['Hodal', 'Palwal', 'Hassanpur', 'Hathin'],
  Panchkula: ['Morni Hills', 'Barwala(Pkl)', 'Pinjore', 'Raipur Rani'],
  Panipat: ['Israna', 'Panipat', 'Samalkha', 'Madlauda', 'Bapauli'],
  Rewari: ['Khol at Rewari', 'Rewari', 'Nahar', 'Jatusana', 'Bawal'],
  Rohtak: ['Sampla', 'Lakhan Majra', 'Rohtak', 'Kalanaur', 'Meham'],
  Sirsa: [
    'Nathusari Chopta',
    'Baragudha',
    'Sirsa',
    'Dabwali',
    'Odhan',
    'Ellenabad',
    'Rania',
  ],
  Sonipat: [
    'Sonipat',
    'Kharkhoda',
    'Gohana',
    'Kathura',
    'Rai',
    'Mundlana',
    'Ganaur',
  ],
  Yamunanagar: [
    'Bilaspur',
    'Sadhaura',
    'Saraswati Nagar',
    'Radaur',
    'Jagadhri',
    'Chhachhrauli',
  ],
};
