const filterData = {};

filterData.categories = [
  { id: 'GE', name: 'General' },
  { id: 'OB', name: 'OBC' },
  { id: 'SC', name: 'SC' },
  { id: 'ST', name: 'ST' },
  { id: 'OT', name: 'Other' },
];

filterData.gender = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' },
  { id: 'N', name: 'None' },
];

filterData.schoolTypes = [
  { id: 'GHS', name: 'GHS' },
  { id: 'GMS', name: 'GMS' },
  { id: 'GPS', name: 'GPS' },
  { id: 'GSSS', name: 'GSSS' },
  { id: 'None', name: 'None' },
];

filterData.schoolSessions = [
  { id: 'S', name: 'Summer' },
  { id: 'W', name: 'Winter' },
];

filterData.cwsn = [
  { id: true, name: 'True' },
  { id: false, name: 'False' },
];

filterData.grades = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
  { id: '12', name: '12' },
];

export default filterData;
