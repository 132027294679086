import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: '#192D3E',
  },
}));

const AdminBreadcrumbs = (props) => {
  const { path } = props;
  const paths = path.split('/');
  const slicedPaths = paths.slice(paths.indexOf('#'), paths.length);
  const classes = useStyles();
  const routes = [];
  const constructRoutes = () => {
    return routes.reduce((acc, route) => acc.concat(route));
  };

  return (
    <Breadcrumbs
      className={classes.nav}
      separator=' > '
      aria-label='breadcrumb'
    >
      {slicedPaths.map((fragment, index) => {
        let route = '';
        let label = '';
        if (fragment === '#') {
          route = '/#';
          label = 'Home';
          routes.push(route);
          return (
            <Link key={index} href={route} className='cursor-pointer'>
              <HomeIcon className={classes.icon} />
            </Link>
          );
        }
        // eslint-disable-next-line consistent-return
        if (fragment === 'e') {
          routes.push('/e');
          // eslint-disable-next-line consistent-return
          return;
        }
        route = fragment;
        routes.push(`/${route}`);
        label =
          fragment.charAt(0).toUpperCase() +
          fragment.slice(1).replace('-', ' ');

        if (index === slicedPaths.length - 1) {
          label =
            label.indexOf('?') > 0 ? label.slice(0, label.indexOf('?')) : label;
          if (label === 'Covid positive-teacher-form-v2s')
            label = 'COVID Positive Employees List';
          else if (label === 'Covid positive-student-form')
            label = 'COVID Positive Students List';
          else if (label === 'Ementfeedb v2s')
            label = 'Mentoring Feedback Survey List';
          else if (label.length > 20) label = 'Edit';
          return (
            <Typography key={index} variant='overline'>
              {label}
            </Typography>
          );
        }
        if (fragment.length > 20) {
          label = 'Edit';
          return <Typography variant='overline'>{label}</Typography>;
        }
        return (
          <Link key={index} href={constructRoutes(index)} color='inherit'>
            <Typography variant='overline'>{label}</Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AdminBreadcrumbs;
