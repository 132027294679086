/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  List,
  Create,
  Edit,
  Datagrid,
  EditButton,
  TextField,
  BooleanField,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  Filter,
  required,
  usePermissions,
} from 'react-admin';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';

// school renderer
const schoolCodeRenderer = (choice) => {
  return choice != null ? `${choice.id} - ${choice.school_name}` : '';
};

/**
 * Filter
 * @param {*} props
 */
const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder='Search'
      source='q'
      className='searchBar'
      alwaysOn
    />
    <SelectInput
      label='By Grade'
      source='grade'
      choices={[
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
        { id: '10', name: '10' },
        { id: '11', name: '11' },
        { id: '12', name: '12' },
      ]}
      className='autocompleteInput'
    />
    <SelectInput
      label='By Section'
      source='section'
      choices={[
        { id: 'A', name: 'A' },
        { id: 'B', name: 'B' },
        { id: 'C', name: 'C' },
        { id: 'D', name: 'D' },
        { id: 'E', name: 'E' },
        { id: 'F', name: 'F' },
      ]}
      className='autocompleteInput'
    />
    <SelectInput
      label='By Stream'
      source='stream'
      choices={[
        { id: 'Arts', name: 'Arts' },
        { id: 'Commerce', name: 'Commerce' },
        { id: 'Science', name: 'Science' },
        { id: '-', name: 'None' },
      ]}
      className='autocompleteInput'
    />
  </Filter>
);

/**
 * List all students
 * @param {*} props
 */
export const StudentList = (props) => (
  <List
    {...props}
    filters={<SearchFilter />}
    title='Student List'
    className='customBox'
  >
    <Datagrid rowClick='edit'>
      {/* <TextField source='id' /> */}
      <TextField label='Student Name' source='name' />
      <TextField source='grade' />
      <TextField source='section' />
      {/* <ReferenceField label='School' source='school_code' reference='school'>
        <TextField source='school_name' />
      </ReferenceField> */}
      <TextField source='stream' />
      <TextField label='SRN' source='srn' />
      <TextField label='Father Name' source='fatherName' />
      <TextField label='Mother Name' source='motherName' />
      <TextField label='Father Contact Number' source='fatherContactNumber' />
      <BooleanField label='Is Active' source='isActive' />
      <EditButton />
    </Datagrid>
  </List>
);

/**
 * Create new student
 * @param {*} props
 */
export const StudentCreate = (props) => (
  <Create {...props} className='customBox'>
    <SimpleForm>
      <TextInput
        label='Student Name'
        source='name'
        validate={[required()]}
        variant='outlined'
      />
      <SelectInput
        label='Grade'
        source='grade'
        choices={[
          { id: '1', name: '1' },
          { id: '2', name: '2' },
          { id: '3', name: '3' },
          { id: '4', name: '4' },
          { id: '5', name: '5' },
          { id: '6', name: '6' },
          { id: '7', name: '7' },
          { id: '8', name: '8' },
          { id: '9', name: '9' },
          { id: '10', name: '10' },
          { id: '11', name: '11' },
          { id: '12', name: '12' },
        ]}
        validate={[required()]}
        variant='outlined'
      />
      <SelectInput
        label='Section'
        source='section'
        choices={[
          { id: 'A', name: 'A' },
          { id: 'B', name: 'B' },
          { id: 'C', name: 'C' },
          { id: 'D', name: 'D' },
          { id: 'E', name: 'E' },
          { id: 'F', name: 'F' },
        ]}
        variant='outlined'
      />
      <SelectInput
        label='Stream'
        source='stream'
        choices={[
          { id: 'Arts', name: 'Arts' },
          { id: 'Commerce', name: 'Commerce' },
          { id: 'Science', name: 'Science' },
          { id: '-', name: 'None' },
        ]}
        validate={[required()]}
        variant='outlined'
      />
      <TextInput label='SRN' source='srn' variant='outlined' />
      <ReferenceInput
        label='School Code'
        source='school_code'
        reference='school'
        format={(v) => (!v ? null : v)}
        sort={{ field: 'school_code', order: 'ASC' }}
        filterToQuery={(searchText) => ({ school_code: searchText })}
        validate={[required()]}
        variant='outlined'
        allowEmpty
        allowNull
      >
        <AutocompleteInput optionText={schoolCodeRenderer} />
      </ReferenceInput>
      <TextInput label='Father Name' source='fatherName' variant='outlined' />
      <TextInput label='Mother Name' source='motherName' variant='outlined' />
      <TextInput
        label='Father Contact Number'
        source='fatherContactNumber'
        variant='outlined'
      />
      <TextInput
        label='Mother Contact Number'
        source='motherContactNumber'
        variant='outlined'
      />
      <BooleanInput label='Is Active?' source='isActive' />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData) {
            formData.srn = '-';
            formData.fatherName = '-';
            formData.motherName = '-';
            formData.fatherContactNumber = '-';
            formData.motherContactNumber = '-';
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

/**
 * Edit student details
 * @param {*} props
 */
export const StudentEdit = (props) => {
  const { loaded, permissions } = usePermissions();
  return (
    <Edit {...props} className='customBox'>
      <SimpleForm
        toolbar={
          loaded ? (
            permissions.Students &&
            permissions.Students.indexOf('delete') > -1 ? undefined : (
              <EditNoDeleteToolbar />
            )
          ) : (
            <EditNoDeleteToolbar />
          )
        }
      >
        <TextInput
          label='Student Name'
          source='name'
          validate={[required()]}
          variant='outlined'
        />
        <SelectInput
          label='Grade'
          source='grade'
          choices={[
            { id: '1', name: '1' },
            { id: '2', name: '2' },
            { id: '3', name: '3' },
            { id: '4', name: '4' },
            { id: '5', name: '5' },
            { id: '6', name: '6' },
            { id: '7', name: '7' },
            { id: '8', name: '8' },
            { id: '9', name: '9' },
            { id: '10', name: '10' },
            { id: '11', name: '11' },
            { id: '12', name: '12' },
          ]}
          validate={[required()]}
          variant='outlined'
        />
        <SelectInput
          label='Section'
          source='section'
          choices={[
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' },
            { id: 'C', name: 'C' },
            { id: 'D', name: 'D' },
            { id: 'E', name: 'E' },
            { id: 'F', name: 'F' },
          ]}
          variant='outlined'
        />
        <SelectInput
          label='Stream'
          source='stream'
          choices={[
            { id: 'Arts', name: 'Arts' },
            { id: 'Commerce', name: 'Commerce' },
            { id: 'Science', name: 'Science' },
            { id: '-', name: 'None' },
          ]}
          validate={[required()]}
          variant='outlined'
        />
        <TextInput label='SRN' source='srn' variant='outlined' />
        <ReferenceInput
          label='School Code'
          source='school_code'
          reference='school'
          format={(v) => (!v ? null : v)}
          sort={{ field: 'school_code', order: 'ASC' }}
          filterToQuery={(searchText) => ({ school_code: searchText })}
          validate={[required()]}
          variant='outlined'
          allowEmpty
          allowNull
        >
          <AutocompleteInput optionText={schoolCodeRenderer} />
        </ReferenceInput>
        <TextInput label='Father Name' source='fatherName' variant='outlined' />
        <TextInput label='Mother Name' source='motherName' variant='outlined' />
        <TextInput
          label='Father Contact Number'
          source='fatherContactNumber'
          variant='outlined'
        />
        <TextInput
          label='Mother Contact Number'
          source='motherContactNumber'
          variant='outlined'
        />
        <BooleanInput label='Is Active?' source='isActive' />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData) {
              formData.srn = formData.srn ? formData.srn : '-';
              formData.fatherName = formData.fatherName
                ? formData.fatherName
                : '-';
              formData.motherName = formData.motherName
                ? formData.motherName
                : '-';
              formData.fatherContactNumber = formData.fatherContactNumber
                ? formData.fatherContactNumber
                : '-';
              formData.motherContactNumber = formData.motherContactNumber
                ? formData.motherContactNumber
                : '-';
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
