import React, { useState } from 'react';
import {
  Create,
  Datagrid,
  Edit,
  Button,
  EditButton,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  useDataProvider,
  Error,
  required,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

// choices
const districtChoices = require('../../meta/district.json');
const blockChoices = require('../../meta/block.json');
const designationChoices = require('../../meta/designation.json');

// school renderer
const schoolCodeRenderer = (choice) => {
  return choice != null ? `${choice.id} - ${choice.school_name}` : '';
};

const useStyles = makeStyles({
  button: {
    fontWeight: 'bold',
    backgroundColor: '#122230',
    color: '#ffffff',
    cursor: 'pointer',
    '& svg': { color: '#ffffff' },
    '&:hover': {
      fontWeight: 'bold',
      backgroundColor: '#122230',
      color: '#ffffff',
      '& svg': { color: '#ffffff' },
    },
  },
});

/**
 * Filter
 * @param {*} props
 */
const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder='Search'
      source='q'
      className='searchBar'
      alwaysOn
    />
    <AutocompleteInput
      label='By Designation'
      source='designation'
      choices={designationChoices}
      className='autocompleteInput'
    />
    <AutocompleteInput
      label='By District'
      source='district'
      choices={districtChoices}
      className='autocompleteInput'
    />
    <AutocompleteInput
      label='By Block'
      source='block'
      choices={blockChoices}
      className='autocompleteInput'
    />
    <ReferenceInput
      label='By School Code'
      source='school_code'
      reference='school'
      format={(v) => (!v ? null : v)}
      sort={{ field: 'school_code', order: 'ASC' }}
      filterToQuery={(searchText) => ({ school_code: searchText })}
      className='autocompleteInput'
      allowEmpty
      allowNull
    >
      <AutocompleteInput optionText={schoolCodeRenderer} />
    </ReferenceInput>
  </Filter>
);

/**
 * List all school teachers
 * @param {*} props
 */
export const EmployeeList = (props) => (
  <List
    {...props}
    filters={<SearchFilter />}
    sort={{ field: 'employee_id', order: 'ASC' }}
    title='Employee List'
    className='customBox'
  >
    <Datagrid rowClick='edit'>
      <TextField label='Employee ID' source='id' />
      <TextField label='Employee Name' source='employee_name' />
      <TextField label='School Code' source='school_code' />
      <TextField label='School Name' source='school_name' />
      <TextField label='District' source='district' />
      <TextField label='Block' source='block' />
      <TextField label='Designation' source='designation' />
      <EditButton />
    </Datagrid>
  </List>
);

/**
 * Create new teacher
 * @param {*} props
 */
export const EmployeeCreate = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [schoolData, setSchoolData] = useState();
  const [error, setError] = useState();
  const autoFillSchoolData = (schoolCode) => {
    dataProvider
      .getOne('school', { id: schoolCode })
      .then(({ data }) => {
        setSchoolData(data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  if (error) return <Error />;

  return (
    <Create {...props} className='customBox'>
      <SimpleForm>
        <TextInput
          label='Employee Name'
          source='employee_name'
          validate={[required()]}
          variant='outlined'
        />
        <ReferenceInput
          label='School Code'
          source='school_code'
          reference='school'
          allowEmpty
          allowNull
          format={(v) => (!v ? null : v)} // final-form's default format makes null to "" :-(
          sort={{ field: 'school_code', order: 'ASC' }}
          filterToQuery={(searchText) => ({ school_code: searchText })}
          validate={required()}
          variant='outlined'
          perPage={100}
          onChange={autoFillSchoolData}
        >
          <AutocompleteInput optionText={schoolCodeRenderer} />
        </ReferenceInput>
        <Button label='Add School Data' className={classes.button}>
          <IconContentAdd />
        </Button>
        <TextInput
          label='School Name'
          source='school_name'
          validate={[required()]}
          variant='outlined'
        />
        <TextInput
          label='Block'
          source='block'
          validate={[required()]}
          variant='outlined'
        />
        <TextInput
          label='District'
          source='district'
          validate={[required()]}
          variant='outlined'
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData && schoolData) {
              formData.school_name = schoolData.school_name;
              formData.block = schoolData.block;
              formData.district = schoolData.district;
            }
          }}
        </FormDataConsumer>
        <SelectInput
          label='Designation'
          source='designation'
          choices={designationChoices}
          perPage={100}
          validate={[required()]}
          variant='outlined'
        />
      </SimpleForm>
    </Create>
  );
};

/**
 * Edit school details
 * @param {*} props
 */
export const EmployeeEdit = ({ variant, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [schoolData, setSchoolData] = useState();
  const [error, setError] = useState();
  const autoFillSchoolData = (schoolCode) => {
    // dat.school_name = null;
    dataProvider
      .getOne('school', { id: schoolCode })
      .then(({ data }) => {
        setSchoolData(data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  if (error) return <Error />;

  return (
    <Edit {...props} className='customBox'>
      <SimpleForm>
        <TextInput
          label='Employee Name'
          source='employee_name'
          validate={[required()]}
          variant='outlined'
        />
        <ReferenceInput
          label='School Code'
          source='school_code'
          reference='school'
          allowEmpty
          allowNull
          format={(v) => (!v ? null : v)} // final-form's default format makes null to "" :-(
          sort={{ field: 'school_code', order: 'ASC' }}
          filterToQuery={(searchText) => ({ school_code: searchText })}
          validate={required()}
          variant='outlined'
          perPage={100}
          onChange={autoFillSchoolData}
        >
          <AutocompleteInput optionText={schoolCodeRenderer} />
        </ReferenceInput>
        <Button label='Add School Data' className={classes.button}>
          <IconContentAdd />
        </Button>
        <TextInput
          label='School Name'
          source='school_name'
          validate={[required()]}
          variant='outlined'
        />
        <TextInput
          label='Block'
          source='block'
          validate={[required()]}
          variant='outlined'
        />
        <TextInput
          label='District'
          source='district'
          validate={[required()]}
          variant='outlined'
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData && schoolData) {
              formData.school_name = schoolData.school_name;
              formData.block = schoolData.block;
              formData.district = schoolData.district;
            }
          }}
        </FormDataConsumer>
        <SelectInput
          label='Designation'
          source='designation'
          choices={designationChoices}
          perPage={100}
          validate={[required()]}
          variant='outlined'
        />
      </SimpleForm>
    </Edit>
  );
};
