import { store } from '../../e/utils/localsotage.service';
import { client } from '../api-clients/users-client';
import { HttpError } from 'react-admin';

const GET_LIST = 'GET_LIST';
const GET_ONE = 'GET_ONE';
const GET_MANY = 'GET_MANY';
const CREATE = 'CREATE';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';
const DELETE_MANY = 'DELETE_MANY';

const { FusionAuthClient } = require('../../@fusionauth/node-client');
const fusionAuthDataProvider = (
  config = {
    fusionAuthAPIKey: '',
    fusionAuthURL: '',
    avoidApplicationId: false,
    avoidToken: false,
  }
) => {
  if (!config.fusionAuthAPIKey || !config.fusionAuthURL) {
    console.log(
      '!! IMPORTANT || Fusion auth details required in config !!! (fusionAuthAPIKey and fusionAuthURL)'
    );
  }

  return async (type, resource, params) => {
    if (params.fusionAuthConfig) {
      config.fusionAuthURL = params.fusionAuthConfig.fusionAuthURL;
      config.fusionAuthAPIKey = params.fusionAuthConfig.fusionAuthAPIKey;
      config.fusionAuthApplicationId =
        params.fusionAuthConfig.fusionAuthApplicationId;
    }
    const u = await store.getItem('user');
    const fusionAuthclient = new FusionAuthClient(
      config.fusionAuthAPIKey,
      config.fusionAuthURL,
      u && !config.avoidToken ? u.token : ''
    );
    switch (type) {
      case GET_LIST: {
        const { page = 1, perPage = 25 } = params.pagination || {};
        const sortFields = [];
        if (params.sort && params.sort.field && params.sort.field !== 'id') {
          const sort = {};
          sort.name = params.sort.field;
          if (params.sort.order) {
            sort.order = params.sort.order.toLowerCase();
          }
          sortFields.push(sort);
        }
        const role = resource == 'userSamiksha-school' ? 'School' : resource == 'userSamiksha-diet' ? 'DIET' : 'Mentor';
        // let queryString = config.avoidApplicationId
        //   ? ''
        //   : `(registrations.applicationId: ${config.fusionAuthApplicationId}) AND (registrations.roles: ${role})`;
        let queryString = `(registrations.roles: ${role})`;
        const {
          filter: { globalSearch },
        } = params;
        if (globalSearch) {
          if (queryString) {
            queryString += 'AND';
          }
          queryString += `(${globalSearch}*)`;
          delete params.filter.globalSearch;
        }
        // if (queryString && Object.keys(params.filter).length) {
        //   queryString += 'AND';
        // }
        Object.keys(params.filter).forEach((i) => {
          switch (i) {
            case 'active': queryString += `AND(active:${params.filter[i]})`; break;
            case 'username': queryString += `AND(username:${params.filter[i]} OR username:${params.filter[i]}*)`; break;
            case 'mentorUsername': queryString += `AND(username:${params.filter[i]})`; break;
            case 'district': queryString += `AND(data.roleData.district:${params.filter[i]})`; break;
            case 'block': queryString += `AND(data.roleData.block:"${params.filter[i]}")`; break;
            case 'cluster': queryString += `AND(data.roleData.cluster:"${params.filter[i]}")`; break;
            case 'designation': queryString += `AND(data.roleData.designation:${params.filter[i]})`; break;
            case 'reasonForInactive': queryString += `AND(data.reasonForInactive:${params.filter[i]})`; break;
            case 'startDate': queryString += `AND(lastLoginInstant: >${params.filter[i]})`; break;
            case 'endDate': queryString += `AND(lastLoginInstant: <${params.filter[i]})`; break;
          }
        });

        const finalParams = {
          startRow: (page - 1) * perPage,
          numberOfResults: perPage,
          queryString: queryString,
          sortFields
        };
        const response = await client.get("/api/searchUserByQuery", { params: finalParams, headers: { 'x-application-id': config.fusionAuthApplicationId } });
        if (response?.data?.result) {
          return {
            total: response?.data?.result?.total,
            data: response?.data?.result?.users || [],
          };
        } else {
          return {
            total: 0,
            data: [],
          };
        }
        // const body = {
        //   search: {
        //     queryString,
        //     sortFields,
        //     numberOfResults: perPage,
        //     startRow,
        //   },
        // };
        // try {
        //   return client
        //     .searchUsersByQueryString(body)
        //     .then((response) => {
        //       const users = response.successResponse.users || [];
        //       return {
        //         data: users,
        //         total: response.successResponse.total,
        //       };
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //       return {
        //         data: [],
        //         total: 0,
        //       };
        //     });
        // } catch (error) {
        //   console.log(error);
        //   return {
        //     data: [],
        //     total: 0,
        //   };
        // }
      }
      case GET_MANY: {
        try {
          return client
            .searchUsers(params.ids || [])
            .then((response) => {
              return {
                data: response.successResponse.users,
                total: response.successResponse.total,
              };
            })
            .catch((err) => {
              console.error(err);
              return {
                data: [],
                total: 0,
              };
            });
        } catch (error) {
          console.log('Get Many Catch', error);
          return {
            data: [],
            total: 0,
          };
        }
      }
      case GET_ONE: {
        try {
          // return client
          //   .retrieveUser(params.id)
          //   .then((response) => {
          //     return {
          //       data: response.successResponse.user,
          //     };
          //   })
          //   .catch(() => {
          //     return {
          //       data: null,
          //       total: 0,
          //     };
          //   });
          // const a = {
          //   queryString: params.id
          // };
          // const response = await client.get("/admin/searchUser", { params: a });
          const response = await client.get(`/api/user/${params.id}`, { headers: { 'x-application-id': config.fusionAuthApplicationId } });

          if (response?.data?.result) {
            return {
              data: response?.data?.result?.users[0],
            };
          }
          return response;
        } catch (error) {
          console.log('Get One Catch', error);
          return {
            data: null,
            total: 0,
          };
        }
      }
      case CREATE: {
        try {
          const body = {
            registration: {
              applicationId: config.fusionAuthApplicationId,
              usernameStatus: "ACTIVE",
              roles: [params?.data?.data?.roleData?.userType]
            },
            user: {
              fullName: params?.data?.fullName,
              password: params?.data?.password,
              username: params?.data?.username,
              mobilePhone: params?.data?.data?.phone,
              email: params?.data?.email,
              data: {
                accountName: params?.data?.fullName,
                roleData: {
                  block: params?.data?.data?.roleData?.block || "",
                  cluster: params?.data?.data?.roleData?.cluster,
                  designation: params?.data?.data?.roleData?.designation || "",
                  district: params?.data?.data?.roleData?.district || "",
                  schoolCode: params?.data?.data?.roleData?.schoolCode,
                  schoolName: params?.data?.data?.roleData?.schoolName,
                  userType: params?.data?.data?.roleData?.userType || ""
                },
              }
            },
          };

          let data = await client.post('/api/signup', body, { headers: { 'x-application-id': config.fusionAuthApplicationId } });

          if (data?.data?.responseCode === "OK") {
            return data;
          } else if (data?.data?.status != 200) {
            const errorStrings = [];
            const errors = data?.data?.exception?.fieldErrors;
            Object.keys(errors).forEach(key => {
              errorStrings.push(errors[key]?.[0]?.message);
            })
            return Promise.reject(
              new HttpError(
                errorStrings.join(" ") || "An internal server error occured",
                data.data.status,
                data.data
              )
            );
          }

          // return client
          //   .register(params.data.id, {
          //     user: params.data,
          //     registration: {
          //       applicationId: config.fusionAuthApplicationId,
          //       roles: ['School'],
          //     },
          //   })
          //   .then((response) => {
          //     return {
          //       data: response.successResponse.user,
          //     };
          //   })
          //   .catch((error) => {
          //     console.log('Create Catch', error);
          //     return {
          //       data: null,
          //     };
          //   });
        } catch (error) {
          console.log('Create Catch', error);
          return {
            data: error,
          };
        }
        return null;
      }
      case UPDATE: {
        try {
          if (params.data.newDistrict)
            params.data.data.roleData.district = params.data.newDistrict;
          if (params.data.newBlock)
            params.data.data.roleData.block = params.data.newBlock;
          // if (!params.data.active && params.data.data.reasonForInactive == "VACANT") {
          //   params.data.fullName = null;
          //   params.data.email = null
          //   params.data.mobilePhone = null;
          // }
          delete params.data.newDistrict
          delete params.data.newBlock;
          if (params.data.active && params.data.data.reasonForInactive) {
            params.data.data.reasonForInactive = null;
            params.data.data.otherComment = null;
          }

          if (params.data.active) {
            const activationResponse = await client.patch(`/api/user/${params.id || params.uuid}/${params.data.active ? 'activate' : 'deactivate'}`, {}, { headers: { 'x-application-id': config.fusionAuthApplicationId } });
            if (activationResponse.status == 200) {
              const response = await client.patch("/api/updateUser/" + params.id || params.uuid, params.data, { headers: { 'x-application-id': config.fusionAuthApplicationId } });
              if (response?.data?.result) {
                return {
                  data: response?.data?.result,
                };
              } else {
                const errorStrings = [];
                const errors = response?.data?.exception?.fieldErrors;
                Object.keys(errors).forEach(key => {
                  errorStrings.push(errors[key]?.[0]?.message);
                })
                return Promise.reject(
                  new HttpError(
                    errorStrings.join(" ") || "An internal server error occured",
                    response.data.status,
                    response.data
                  )
                );
              }
            }
          } else {
            const response = await client.patch("/api/updateUser/" + params.id || params.uuid, params.data, { headers: { 'x-application-id': config.fusionAuthApplicationId } });
            if (response?.data?.result) {
              await client.patch(`/api/user/${params.id || params.uuid}/${params.data.active ? 'activate' : 'deactivate'}`, {}, { headers: { 'x-application-id': config.fusionAuthApplicationId } });
              return {
                data: response?.data?.result,
              };
            } else {
              const errorStrings = [];
              const errors = response?.data?.exception?.fieldErrors;
              Object.keys(errors).forEach(key => {
                errorStrings.push(errors[key]?.[0]?.message);
              })
              return Promise.reject(
                new HttpError(
                  errorStrings.join(" ") || "An internal server error occured",
                  response.data.status,
                  response.data
                )
              );
            }
          }

          // return client
          //   .updateUser(params.id || params.uuid, { user: params.data })
          //   .then((response) => {
          //     return {
          //       data: response.successResponse.user,
          //     };
          //   })
          //   .catch(() => {
          //     return {
          //       data: null,
          //     };
          //   });
        } catch (error) {
          console.log('Update Catch', error);
          return {
            data: null,
          };
        }
      }
      case DELETE: {
        try {
          if (params.id.length > 1) {
            return fusionAuthclient
              .deleteUsers({
                dryRun: false,
                hardDelete: true,
                userIds: [...params.id],
              })
              .then(() => {
                return {
                  data: [...params.ids],
                };
              })
              .catch(() => ({
                data: {},
              }));
          }
          return fusionAuthclient
            .deleteUser(params.id || params.uuid)
            .then((response) => {
              return {
                data: response.statusCode,
              };
            })
            .catch(() => ({
              data: null,
            }));
        } catch (error) {
          console.log('Delete Catch', error);
          return {
            data: null,
          };
        }
      }
      // delete_many(amit_kumar);
      case DELETE_MANY: {
        try {
          return fusionAuthclient
            .deleteUsers(params.ids || params.uuids || [])
            .then((response) => {
              console.log(response, 'response');
              return {
                data: [...params.ids],
              };
            })
            .catch(() => ({
              data: {},
            }));
        } catch (error) {
          console.log('Delete Catch', error);
          return {
            data: {},
          };
        }
      }
      default:
    }
    return null;
  };
}

export default fusionAuthDataProvider;
