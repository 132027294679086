/* eslint-disable no-nested-ternary */
import ReactDom from 'react-dom';
import React, { useState, cloneElement, useEffect, useRef } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import {
  List,
  Create,
  Edit,
  EditButton,
  Toolbar,
  TopToolbar,
  Button,
  SaveButton,
  CreateButton,
  ExportButton,
  Datagrid,
  Pagination,
  TextField,
  EmailField,
  BooleanField,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  SelectInput,
  SelectArrayInput,
  PasswordInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SearchInput,
  Filter,
  Error,
  required,
  email,
  minLength,
  maxLength,
  useDataProvider,
  usePermissions,
  DateInput,
  useNotify,
  useRedirect,
  useResourceContext,
  FunctionField,
  downloadCSV
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { sanitizeListRestProps } from 'ra-core';
import { Divider, makeStyles } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';
import PasswordChangeButton from './PasswordChangeButton';
import DeleteButtonWithConfirmation from './DeleteButtonWithConfirmation';
import { clientGQL } from '../dataProviders/clientGQL';
import { getFinalBlocks } from '../../e/utils/helpers';
import { useInput } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { districts, blocks, clusters, districtMap, blockMap } from '../resources/LocationData';
import ImportCsvModal from './ImportCsvModal';
const config = require('../../config.json');
const userType = ['School', 'DIET', 'Mentor'];
const isMobile = window.innerWidth < 480;
const roleChoices = [
  'School Head',
  'PRT/JBT',
  'TGT/Master ',
  'PGT/Lecturer',
  'Head Master Elementary School',
  'Head Master High School',
  'Clerk',
  'Principal',
  'Head Teacher',
  'Subject Specialist',
  'Laboratory Attendant',
  'Junior Librarian',
  'Principal, DIET',
  'Senior Lecturer DIET',
  'Tabla Player',
  'Vocational Instructor',
  'Vocational PGT',
  'Classical & Vernacular Teacher',
  'Librarian',
  'Maali',
  'Junior Scale Stenographer',
  'Superintendent',
  'Sweeper-cum-Chowkidar',
  'Sweeper',
  'Statistical Assistant',
  'Assistant',
  'Senior Scale Stenographer',
  'Laboratory Assistant',
  'Waterman',
  'Peon-cum-Chowkidar',
  'Subject Specialist (Economics)',
  'Chowkidar',
  'DPE',
  'Computer Teacher',
  'PTI',
  'CRC',
  'BEEO',
  'BRP',
  'BEO',
  'ABRC',
  'DEO',
  'DIET Lecturer',
  'Primary Incharge',
  'DC',
  'CMGGA',
  'APC',
  'DDEO',
  'DIET Sr. Lecturer',
  'DMS',
  'DSS',
  'DEEO',
  'DIET Principal',
  'DPC',
  'KEF PL',
  'ADC',
  'Test',
  'Subject Expert',
  'SDM',
  'SHC',
  'Sampark',
]

// choice mapping
const toChoices = (items) =>
  items && items.map((item) => ({ id: item, name: item }));

// school renderer
const schoolCodeRenderer = (choice) => {
  return choice != null ? `${choice.id} - ${choice.school_name}` : '';
};

const useStyles = makeStyles({
  button: {
    fontWeight: 'bold',
    backgroundColor: '#122230',
    color: '#ffffff',
    cursor: 'pointer',
    '& svg': { color: '#ffffff' },
    '&:hover': {
      fontWeight: 'bold',
      backgroundColor: '#122230',
      color: '#ffffff',
      '& svg': { color: '#ffffff' },
    },
  },
});


const VacantConfirmationModal = (props) => {
  const { setState, state, showModal, option, username, setClearReasonForInactive, setRenderRFI } = props;

  return ReactDom.createPortal(
    <div className='modal-container'>
      <div className='modal-content'>
        <p>Attention</p>
        <p>Please confirm that the position against the username <strong>{username}</strong> is VACANT. Upon confirmation, the following details will be removed: </p>
        <p>• FULL NAME</p>
        <p>• EMAIL ID</p>
        <p>• MOBILE NUMBER</p>
        <div className='modal-buttons'>
          <div onClick={() => { setState({ ...state, reasonForInactive: option }); showModal(false); }}>✅ Confirm</div>
          <div onClick={() => { setRenderRFI(false); setClearReasonForInactive(true); showModal(false); setTimeout(() => setRenderRFI(true), 50) }}>❌ Cancel</div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export const SSUserList = (props) => {
  const [clusterChoices, setClusterChoices] = useState([]);
  const [blockChoices, setBlockChoices] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [importModal, showImportModal] = useState(false);
  const resource = useResourceContext();
  const ListBulkActionButtons = (props) => {
    return <DeleteButtonWithConfirmation {...props} redirect={resource} />;
  };

  /**
   * User list
   * @param {*} param
   */
  const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ImportButton />
      <ExportButton maxResults={10000} />
    </TopToolbar>
  );

  const ImportButton = () => {
    return <span className='import-button' onClick={() => showImportModal(!importModal)}><PublishIcon style={{ height: isMobile ? 22 : 20, width: isMobile ? 22 : 20 }} /> &nbsp;{window.innerWidth < 769 ? '' : 'IMPORT'}</span>
  }


  useEffect(() => {
    if (selectedDistrict) {
      if (selectedDistrict.length == 1)
        setBlockChoices(toChoices(districtMap[selectedDistrict]))
      else {
        const blockList = [];
        selectedDistrict.forEach(d => blockList.push(...districtMap[d]))
        setBlockChoices(toChoices(blockList.sort()))
      }
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedDistrict])

  useEffect(() => {
    if (selectedBlock) {
      if (selectedBlock.length == 1)
        setClusterChoices(toChoices(blockMap[selectedBlock]))
      else {
        const clusterList = [];
        selectedBlock.forEach(d => clusterList.push(...blockMap[d]))
        setClusterChoices(toChoices(clusterList.sort()))
      }
    } else
      setClusterChoices(toChoices(clusters));
  }, [selectedBlock])

  useEffect(() => {
    const docFilters = document.getElementsByClassName("filter-field");
    let de = false;
    let be = false;
    for (let i = 0; i < docFilters.length; i++) {
      if (docFilters[i].getAttribute('data-source') == 'district')
        de = true;
      if (docFilters[i].getAttribute('data-source') == 'block')
        be = true;
    }
    if (!de && selectedDistrict)
      setSelectedDistrict("")
    if (!be && selectedBlock)
      setSelectedBlock("")
  })

  const UserFilter = (props) => (
    <Filter {...props}>
      <SearchInput
        placeholder='Search by any attribute*'
        source='globalSearch'
        className='searchBar'
        ressetable="true"
        autoFocus={true}
        alwaysOn
      />
      <BooleanInput label="Active" source='active' />
      <SelectArrayInput label='District' source='district' onChange={(e) => setSelectedDistrict(e.target.value)} choices={toChoices(districts)} variant='outlined' />
      <SelectArrayInput label='Block' source='block' onChange={(e) => setSelectedBlock(e.target.value)} choices={blockChoices} variant='outlined' />
      <SelectArrayInput label='Cluster' source='cluster' choices={clusterChoices} variant='outlined' />
      <SelectArrayInput label='Designation' source='designation' choices={toChoices(roleChoices.sort())} variant='outlined' />
      <SelectArrayInput label='Reason For Inactive' source='reasonForInactive' choices={["MATERNITY LEAVE", "LONG LEAVE", "DEPUTED ELSEWHERE", "VACANT"].map(el => { return { id: el, name: el } })} />
      <DateInput source="startDate" label="Last Login Start Date" />
      <DateInput source="endDate" label="Last Login End Date" />
    </Filter>
  );

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    if (!isNaN(date))
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('/');
    return "------------"
  }

  const isDefaulted = (lastDate) => {
    let currDate = new Date();
    let oldDate = new Date(lastDate);
    let timeDiff = currDate.getTime() - oldDate.getTime();
    let daysDiff = timeDiff / (1000 * 3600 * 24);
    if (daysDiff > 30) return true;
    return false;
  }

  const exporter = records => {
    const recordsForExports = records.map(rec => {
      const recForExport = {
        'ID': rec?.id,
        'Full Name': rec?.active ? rec?.fullName : rec?.data?.reasonForInactive == "VACANT" ? "" : rec?.fullName,
        'Username': rec?.username,
        'Designation': rec?.data?.roleData?.designation,
        'District': rec?.data?.roleData?.district,
        'Block': rec?.data?.roleData?.block,
        'Cluster': rec?.data?.roleData?.cluster,
        'Mobile': rec?.active ? rec?.mobilePhone : rec?.data?.reasonForInactive == "VACANT" ? "" : rec?.mobilePhone,
        'Email': rec?.active ? rec?.email : rec?.data?.reasonForInactive == "VACANT" ? "" : rec?.email,
        'Status': rec?.active ? "Active" : "Disabled",
        'Reason For Inactive': rec?.data?.reasonForInactive || "",
        'Last Date of Login': formatDate(new Date(rec.lastLoginInstant))
      }
      return recForExport;
    });
    jsonExport(recordsForExports, {
      headers: ['ID', 'Full Name', 'Username', 'Designation', 'District', 'Block', 'Cluster', 'Mobile', 'Email', 'Status', 'Reason For Inactive', 'Last Date of Login']
    }, (err, csv) => {
      downloadCSV(csv, 'Samiksha Users');
    });
  };

  return (
    <>
      {importModal && <ImportCsvModal {...{ showImportModal }} />}
      <style>
        {`
          .MuiSelect-root {
            background: #fff !important;
            min-width: 8rem !important;
            border-radius: 0.5rem !important;
            max-height: 1rem !important;
            border: none !important;
          }

          .MuiChip-root {
            padding: 0.2rem 0.2rem !important;
            background: #f8fafc;
            height: auto;
            margin-top: -3px !important;
          }

          .MuiInputBase-root {
            background: #fff !important;
            min-width: 8rem !important;
            border-radius: 0.5rem !important;
            border: none !important;
          }
        `}
      </style>
      <List
        {...props}
        filters={< UserFilter />}
        actions={< ListActions />}
        exporter={exporter}
        bulkActionButtons={< ListBulkActionButtons />}
        pagination={< Pagination perPage={1} />}
        sort={{ field: 'username', order: 'ASC' }}
        title='Samiksha User List'
        className='customBox'
      >
        <Datagrid  {...props}>
          <FunctionField
            label={"FULL NAME"}
            render={record => {
              if (!record.active && record?.data?.reasonForInactive == "VACANT")
                return <span></span>
              return <span>{record.fullName}</span>
            }}
          />
          {/* <TextField source='fullName' /> */}
          <TextField source='username' />
          {/* Unable to sort these fields atm*/}
          <TextField source='data.roleData.designation' label="Designation" sortable={false} />
          <TextField source='data.roleData.district' label="District" sortable={false} />
          <TextField source='data.roleData.block' label="Block" sortable={false} />
          <TextField source='data.roleData.cluster' label="Cluster" sortable={false} />
          {/* Unable to sort these fields atm*/}
          {/* <EmailField source='email' /> */}
          {/* <TextField source='mobilePhone' /> */}
          <FunctionField
            label={"MOBILE PHONE"}
            render={record => {
              if (!record.active && record?.data?.reasonForInactive == "VACANT")
                return <span></span>
              return <span>{record.mobilePhone}</span>
            }}
          />
          <FunctionField
            label="Status"
            render={(record) => {
              return <span>{record.active ? "Active" : "Disabled"}</span>
            }}
          />
          {/* <BooleanField source='active' /> */}
          {/* <TextField source='data.reasonForInactive' label='Reason for Inactive' /> */}
          <FunctionField
            label='Last Date of Login'
            render={record => <span style={isDefaulted(record.lastLoginInstant) ? { color: 'red' } : {}}>{formatDate(new Date(record.lastLoginInstant))}</span>}
          />
          <EditButton />
        </Datagrid>
      </List >
    </>
  )
};

/**
 * Create new user
 * @param {*} props
 */
const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter />
    <SaveButton
      label='post.action.save_and_notify'
      transform={(data) => ({ ...data, notify: true })}
      submitOnEnter={false}
    />
  </Toolbar>
);

// Validation function to check for special characters
const validateSpecialCharacters = (value) => {
  const hasSpecialCharacters = /[^a-zA-Z0-9 \-_]/.test(value);
  return hasSpecialCharacters ? 'Invalid characters detected. Please remove any special characters.' : undefined;
};
const validatePhoneNumber = (value) => {
  // Check for a sequence that doesn't consist entirely of zeros and allows '+', '-', and spaces
  const isValidPhoneNumber = /^(\+?[\s\-]?)(?!0+$)[0-9\s\-]+$/.test(value);
  return isValidPhoneNumber ? undefined : 'Invalid phone number. Please enter a valid phone number without special characters, except +, -, or spaces.';
};

export const SSUserCreate = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [schoolData, setSchoolData] = useState();
  const [error, setError] = useState();
  const [blockChoices, setBlockChoices] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const autoFillSchoolData = (schoolCode) => {
    dataProvider
      .getOne('school', { id: schoolCode })
      .then(({ data }) => {
        setSchoolData(data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (selectedDistrict) {
      setBlockChoices(toChoices(districtMap[selectedDistrict]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedDistrict])

  if (error) return <Error />;

  return (
    <Create {...props} className='customBox' hasEdit={false}>
      <SimpleForm >
        <SelectInput
          label='User Type'
          source='data.roleData.userType'
          choices={toChoices(userType)}
          validate={[required()]}
          variant='outlined'
        />
        <FormDataConsumer variant='outlined'>
          {({ formData, ...rest }) => {
            return (
              <SelectInput
                label='User Role'
                source='data.roleData.designation'
                variant='outlined'
                choices={
                  formData && formData.data
                    ? toChoices(roleChoices)
                    : []
                }
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer subscription={{ values: true }} variant='outlined'>
          {({ formData, ...rest }) =>
            ((formData.data && formData.data.roleData.userType === 'School') ||
              (formData.data &&
                formData.data.roleData.userType === 'Teacher')) && (
              <>
                <div>
                  <ReferenceInput
                    label='School Code'
                    source='data.roleData.schoolCode'
                    reference='school'
                    allowEmpty
                    allowNull
                    format={(v) => (!v ? null : v)} // final-form's default format makes null to "" :-(
                    sort={{ field: 'school_code', order: 'ASC' }}
                    filterToQuery={(searchText) => ({
                      school_code: searchText,
                    })}
                    validate={required()}
                    variant='outlined'
                    perPage={100}
                    onChange={autoFillSchoolData}
                    {...rest}
                  >
                    <AutocompleteInput optionText={schoolCodeRenderer} />
                  </ReferenceInput>
                </div>
                <div>
                  <Button label='Add School Data' className={classes.button}>
                    <IconContentAdd />
                  </Button>
                </div>
                <div>
                  <TextInput
                    label='School Name'
                    source='data.roleData.schoolName'
                    validate={[required(), validateSpecialCharacters]}
                    variant='outlined'
                  />
                </div>
                <div>
                  <SelectInput
                    onChange={(e) => {
                      setSelectedDistrict(e.target.value);
                    }}
                    label='District'
                    source='data.roleData.district'
                    validate={[required()]}
                    variant='outlined'
                    translateChoice={false}
                    choices={toChoices(districts)}
                  />
                </div>
                <div>
                  <SelectInput
                    onChange={(e) => {
                      setSelectedBlock(e.target.value)
                    }}
                    label='Block'
                    source='data.roleData.block'
                    validate={[required()]}
                    variant='outlined'
                    choices={blockChoices}
                  />
                </div>
              </>
            )
          }
        </FormDataConsumer>

        <TextInput
          label='Username'
          source='username'
          validate={[required(), minLength(4), validateSpecialCharacters]}
          variant='outlined'
        />
        <PasswordInput
          label='Password'
          source='password'
          validate={[required(), minLength(8)]}
          variant='outlined'
        />
        <TextInput
          label='Full Name'
          source='fullName'
          validate={[required(), validateSpecialCharacters]}
          variant='outlined'
        />
        <TextInput
          label='Mobile Phone'
          source='mobilePhone'
          validate={[validatePhoneNumber, maxLength(10)]}
          variant='outlined'
        />
        <TextInput
          label='Email'
          source='email'
          variant='outlined'
          validate={[email()]}
        />

        <TextInput source='data.accountName' style={{ display: 'none' }} />
        <TextInput source='data.phone' style={{ display: 'none' }} />

        <FormDataConsumer>
          {({ formData }) => {
            const { fullName, mobilePhone } = formData;
            if (formData && formData.data) {
              formData.data.phone = mobilePhone;
              formData.data.accountName = fullName;
            }
            if (formData && formData.data && schoolData) {
              formData.data.roleData.schoolName = schoolData.school_name;
              formData.data.roleData.block = schoolData.block;
              formData.data.roleData.district = schoolData.district;
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

/**
 * Edit User Details
 */
export const SSUserEdit = (props) => {
  const { loaded, permissions } = usePermissions();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [schoolData, setSchoolData] = useState();
  const [error, setError] = useState();
  const [state, setState] = useState({});
  const [modal, showModal] = useState(false);
  const [clearReasonForInactive, setClearReasonForInactive] = useState(false);
  const [renderRFI, setRenderRFI] = useState(true);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [clusterChoices, setClusterChoices] = useState([]);
  const [blockChoices, setBlockChoices] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const resource = useResourceContext();

  useEffect(() => {
    if (selectedDistrict) {
      setBlockChoices(toChoices(districtMap[selectedDistrict]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedDistrict])

  useEffect(() => {
    if (selectedBlock) {
      setClusterChoices(toChoices(blockMap[selectedBlock]))
    } else
      setClusterChoices(toChoices(clusters));
  }, [selectedBlock])

  // const autoFillSchoolData = (schoolCode) => {
  //   dataProvider
  //     .getOne('school', { id: schoolCode })
  //     .then(({ data }) => {
  //       setSchoolData(data);
  //     })
  //     .catch((err) => {
  //       setError(err);
  //     });
  // };

  if (error) return <Error />;

  return (
    <Edit {...props} title='Edit Samiksha User' className='customBox' mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          loaded ? (
            permissions['Samiksha User'] &&
              permissions['Samiksha User'].indexOf('delete') > -1 ? undefined : (
              <EditNoDeleteToolbar />
            )
          ) : (
            <EditNoDeleteToolbar />
          )
        }
      >
        {/* <SelectInput
          label='User Type'
          source='data.roleData.userType'
          choices={toChoices(userType)}
          validate={[required()]}
          variant='outlined'
        /> */}
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.active)
              return <TextInput
                label='Full Name'
                source='fullName'
                validate={[required(), validateSpecialCharacters]}
                variant='outlined'
              />
          }}

        </FormDataConsumer>
        <TextInput
          label='Username'
          source='username'
          validate={[required(), validateSpecialCharacters]}
          variant='outlined'
        />
        {/* <TextInput
          label='Designation'
          source='data.roleData.designation'
          validate={[required()]}
          variant='outlined'
        /> */}
        <FormDataConsumer variant='outlined'>
          {({ formData, ...rest }) => {
            return (
              <SelectInput
                label='Designation'
                source='data.roleData.designation'
                validate={[required()]}
                variant='outlined'
                choices={toChoices(roleChoices)}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <SelectInput
          value={state.district}
          onChange={(e) => {
            setState({ ...state, district: e.target.value })
            setSelectedDistrict(e.target.value);
          }}
          label='District'
          source='data.roleData.district'
          validate={[required()]}
          variant='outlined'
          translateChoice={false}
          choices={toChoices(districts)}
        />
        {state.district == 'Other' &&
          <TextInput
            label='New District'
            source='newDistrict'
            validate={[required(), validateSpecialCharacters]}
            variant='outlined'
          />
        }
        {resource != "userSamiksha-diet" && <SelectInput
          value={state.block}
          onChange={(e) => {
            setState({ ...state, block: e.target.value })
            setSelectedBlock(e.target.value)
          }}
          label='Block'
          source='data.roleData.block'
          validate={[required()]}
          variant='outlined'
          choices={blockChoices}
        />}
        {state.block == 'Other' &&
          <TextInput
            label='New Block'
            source='newBlock'
            validate={[required(), validateSpecialCharacters]}
            variant='outlined'
          />
        }
        <FormDataConsumer variant='outlined'>
          {({ formData, ...rest }) => {
            if ((state.designation == 'ABRC' || formData?.data?.roleData?.designation == 'ABRC') && resource != "userSamiksha-diet")
              return (
                <SelectInput
                  value={state.cluster}
                  onChange={(e) =>
                    setState({ ...state, cluster: e.target.value })
                  }
                  label='Cluster'
                  source='data.roleData.cluster'
                  variant='outlined'
                  choices={clusterChoices}
                />
              );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.active)
              return <TextInput
                label='Email'
                source='email'
                validate={[required(), email()]}
                variant='outlined'
              />
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => {
            if (formData.active)
              return <TextInput
                label='Mobile'
                source='mobilePhone'
                validate={[required(), validatePhoneNumber, maxLength(10)]}
                variant='outlined'
              />
          }}
        </FormDataConsumer>
        {/* <FormDataConsumer subscription={{ values: true }} variant='outlined'>
          {({ formData, ...rest }) =>
            ((formData.data && formData.data.roleData.userType === 'School') ||
              (formData.data &&
                formData.data.roleData.userType === 'Teacher')) && (
              <>
                <div>
                  <ReferenceInput
                    label='School Code'
                    source='data.roleData.schoolCode'
                    reference='school'
                    allowEmpty
                    allowNull
                    format={(v) => (!v ? null : v)} // final-form's default format makes null to "" :-(
                    sort={{ field: 'school_code', order: 'ASC' }}
                    filterToQuery={(searchText) => ({
                      school_code: searchText,
                    })}
                    validate={required()}
                    variant='outlined'
                    perPage={100}
                    onChange={autoFillSchoolData}
                    {...rest}
                  >
                    <AutocompleteInput optionText={schoolCodeRenderer} />
                  </ReferenceInput>
                </div>
                <div>
                  <Button label='Add School Data' className={classes.button}>
                    <IconContentAdd />
                  </Button>
                </div>
                <div>
                  <TextInput
                    label='School Name'
                    source='data.roleData.schoolName'
                    validate={[required()]}
                    variant='outlined'
                  />
                </div>
                <div>

                </div>
                <div>

                </div>
              </>
            )
          }
        </FormDataConsumer> */}

        {/* <TextInput source='data.accountName' style={{ display: 'none' }} />
        <TextInput source='data.phone' style={{ display: 'none' }} /> */}

        {/* <FormDataConsumer>
          {({ formData }) => {
            const { fullName, mobilePhone } = formData;
            if (formData && formData.data) {
              formData.data.phone = mobilePhone;
              formData.data.accountName = fullName;
            }
            if (formData && formData.data && schoolData) {
              formData.data.roleData.schoolName = schoolData.school_name;
              formData.data.roleData.block = schoolData.block;
              formData.data.roleData.district = schoolData.district;
            }
          }}
        </FormDataConsumer> */}
        <BooleanInput source='active' />

        {
          renderRFI && <FormDataConsumer>
            {({ formData, rest }) => {
              if (!formData?.active) {
                return <SelectInput
                  label="Reason for inactive"
                  source="data.reasonForInactive"
                  variant="outlined"
                  onChange={e => {
                    setClearReasonForInactive(false);
                    if (e.target.value == 'VACANT')
                      showModal(true);
                    else
                      setState({ ...state, reasonForInactive: e.target.value })
                  }}
                  validate={[required()]}
                  choices={["MATERNITY LEAVE", "LONG LEAVE", "DEPUTED ELSEWHERE", "VACANT"].map(el => { return { id: el, name: el } })}
                  {...rest}
                />
              }
            }}
          </FormDataConsumer>
        }
        {
          modal &&
          <FormDataConsumer>
            {({ formData }) => {
              return <VacantConfirmationModal state={state} showModal={showModal} setState={setState} option={'VACANT'} username={formData.username} setClearReasonForInactive={setClearReasonForInactive} setRenderRFI={setRenderRFI} />
            }}
          </FormDataConsumer>
        }
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData?.data?.reasonForInactive == 'VACANT' || state.reasonForInactive == 'VACANT') {
              return <TextInput
                label="Other Comment"
                source="data.otherComment"
                variant="outlined"
              />
            }
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            if (clearReasonForInactive) {
              formData.data.reasonForInactive = null;
            }
            if (formData?.data?.roleData?.district && !selectedDistrict) {
              setSelectedDistrict(formData.data.roleData.district)
              const districtExists = districts.filter(el => el == formData.data.roleData.district).length > 0;
              if (!districtExists) {
                setDistrictChoices([...toChoices(districts), { id: formData.data.roleData.district, name: formData.data.roleData.district }])
              }
            }

            if (blockChoices?.length > 0 && formData?.data?.roleData?.block) {
              const blockExists = blockChoices.filter(el => el.name == formData.data.roleData.block).length > 0;
              if (!blockExists) {
                setBlockChoices([...blockChoices, { id: formData.data.roleData.block, name: formData.data.roleData.block }])
              }
            }

            if (clusterChoices?.length > 0 && formData?.data?.roleData?.cluster) {
              const clusterExists = clusterChoices.filter(el => el.name == formData.data.roleData.cluster).length > 0;
              if (!clusterExists) {
                setClusterChoices([...clusterChoices, { id: formData.data.roleData.cluster, name: formData.data.roleData.cluster }])
              }
            }
          }}
        </FormDataConsumer>

        <Divider />
        <PasswordChangeButton />
      </SimpleForm>
    </Edit >
  );
};
