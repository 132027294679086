/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  List,
  Create,
  Button as LibButton,
  Edit,
  SaveButton,
  Toolbar,
  Datagrid,
  DeleteButton,
  TextField,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  EditButton,
  RadioButtonGroupInput,
  FormDataConsumer,
  FunctionField,
  required,
  usePermissions,
  useRedirect,
} from 'react-admin';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, Card as MuiCard } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import UsersList from '../../e/pages/campaign/campaign.segment.usersList';
import TeacherList from '../../e/pages/campaign/campaign.segment.teacherList';

const config = require('../../config');

const useStyles = makeStyles(() => ({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '50vw',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
}));

// custom edit button
const CustomEditButton = (props) => {
  console.log(props);
  return (
    <EditButton
      style={{ marginLeft: '-20px', color: '#757575' }}
      {...props}
      label=''
      component={Link}
      to={{
        pathname: `/segments/${props.recordId}`,
        // search: `filter=${JSON.stringify(
        //   props.filterValues ? props.filterValues : { isActive: true }
        // )}`,
        search: `filter=${JSON.stringify(
          props.filterValues
            ? props.filterValues
            : props.categoryValue === 'student'
            ? { isActive: true }
            : {}
        )}`,
      }}
    />
  );
};

// custom delete button
const CustomDeleteButton = (props) => (
  <DeleteButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);

// Segment list top toolbar
const SegmentCreateToolbar = (props) => {
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    // redirect(
    //   `/segments/${data.id}?filter=${JSON.stringify({ isActive: true })}`
    // );
    redirect(
      `/segments/${data.id}?filter=${JSON.stringify(
        data.data && data.data.userCategory === 'student'
          ? { isActive: true }
          : {}
      )}`
    );
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        label='Save and proceed'
        disabled={props.invalid}
        onSuccess={onSuccess}
      />
    </Toolbar>
  );
};

/**
 * Segment List
 * @param {*} props
 */
export const SegmentList = (props) => {
  const { loaded, permissions } = usePermissions();
  const classes = useStyles();
  const UserAttributes = (props) => {
    const { filterValues } = props;
    if (!filterValues) return null;

    const flattenFilterValues = (filterValues, rows = []) => {
      for (const filter in filterValues) {
        if (
          typeof filterValues[filter] === 'string' ||
          typeof filterValues[filter] === 'boolean' ||
          Array.isArray(filterValues[filter])
        ) {
          rows.push({ attributeName: filter, value: filterValues[filter] });
        } else flattenFilterValues(filterValues[filter], rows);
      }
      return rows;
    };

    const rows = flattenFilterValues(filterValues);
    const sanitise = (attr) => {
      attr = attr.replace('_', ' ');
      return attr;
    };
    const filterValueChipify = (value) => {
      if (typeof value === 'string') return <Chip label={value} />;
      if (typeof value === 'boolean') {
        if (value) return <Chip label='True' />;
        return <Chip label='False' />;
      }
      return value.map((val) => <Chip label={val} />);
    };
    return (
      <TableContainer>
        <Table aria-label='caption table'>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.attributeName}>
                <>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {sanitise(row.attributeName)}
                  </TableCell>
                  <TableCell align='center'>
                    {filterValueChipify(row.value)}
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const SegmentCategory = (props) => {
    const { category, chipColor } = props;
    return (
      <Chip label={category} color={chipColor ? 'primary' : 'secondary'} />
    );
  };

  return (
    <div className='pl-16'>
      <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        filterDefaultValues={{
          applicationId: config.fusionAuthConsole.fusionAuthApplicationId,
        }}
      >
        <Datagrid>
          <TextField source='name' />
          <FunctionField
            label='Category'
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            render={(record) => {
              if (
                record &&
                record.data &&
                record.data.userCategory === 'teacher'
              ) {
                return <SegmentCategory category='Teachers' chipColor />;
              }
              return <SegmentCategory category='Students' chipColor={false} />;
            }}
          />
          <FunctionField
            label='Total Members'
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            render={(record) =>
              record &&
              record.data &&
              record.data.memberCount &&
              record.data.memberCount.toLocaleString('en-IN')
            }
          />
          <FunctionField
            label='Updated'
            render={(record) =>
              record &&
              record.data &&
              (record.data.updatedAt
                ? record.data.updatedAt
                : record.data.createdOn)
            }
          />
          {loaded ? (
            permissions['User Segments'] &&
            permissions['User Segments'].indexOf('edit') > -1 ? (
              <FunctionField
                label='Edit'
                render={(record) => (
                  <CustomEditButton
                    label='Edit'
                    filterValues={
                      record && record.data && record.data.filterValues
                    }
                    recordId={record && record.id}
                    categoryValue={
                      record && record.data && record.data.userCategory
                    }
                    {...props}
                  />
                )}
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          {loaded ? (
            permissions['User Segments'] &&
            permissions['User Segments'].indexOf('delete') > -1 ? (
              <CustomDeleteButton label='Delete' {...props} />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Datagrid>
      </List>
    </div>
  );
};

/**
 * Segment Create
 * @param {*} props
 */
export const SegmentCreate = (props) => {
  const [showPanel, setShowPanel] = useState(false);
  const handleClick = () => {
    setShowPanel(true);
  };

  const handleCloseClick = () => {
    setShowPanel(false);
  };

  const SegmentCreateActions = ({ basePath, data }) => (
    <TopToolbar>
      <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
    </TopToolbar>
  );

  return (
    <div className='pl-16 pt-16'>
      <Typography variant='h6' color='textPrimary'>
        Segment Details
      </Typography>
      <Create
        {...props}
        actions={<SegmentCreateActions {...props} />}
        undoable={false}
      >
        <SimpleForm toolbar={<SegmentCreateToolbar />}>
          <div
            className='flex flex-row items-center pl-16 pb-16'
            style={{ width: '70vw' }}
          >
            <TextInput
              label='Segment Name'
              source='name'
              validate={[required()]}
              variant='outlined'
              helperText='Use a unique, descriptive name that will identify this segment while creating a conversation.'
              style={{ width: '65%', marginRight: '1rem' }}
            />
            <TextInput
              source='data.applicationId'
              variant='outlined'
              defaultValue={config.fusionAuthConsole.fusionAuthApplicationId}
              style={{ display: 'none' }}
            />
            <TextInput
              source='data.memberCount'
              variant='outlined'
              defaultValue={0}
              style={{ display: 'none' }}
            />
            <TextInput
              source='data.createdOn'
              variant='outlined'
              defaultValue={new Date().toLocaleString('en-IN')}
              style={{ display: 'none' }}
            />
            <TextInput
              source='data.userProvider'
              variant='outlined'
              defaultValue='hasura'
              style={{ display: 'none' }}
            />
          </div>
          <div className='pl-16'>
            <RadioButtonGroupInput
              label='Segment Category'
              source='data.userCategory'
              validate={[required()]}
              choices={[
                { id: 'teacher', name: 'Teacher' },
                { id: 'student', name: 'Students' },
              ]}
            />
          </div>
        </SimpleForm>
      </Create>
    </div>
  );
};

// Segment Edit Section
/**
 * Segment Title
 * @param {*} param0
 */
const SegmentTitle = ({ record }) => {
  return (
    <>
      <Typography
        variant='h5'
        color='textPrimary'
        style={{ display: 'inline' }}
      >
        Manage&nbsp;
      </Typography>
      <Typography
        variant='h5'
        color='textSecondary'
        style={{ display: 'inline' }}
      >
        {record ? record.name : ' '}
      </Typography>
      &nbsp;
    </>
  );
};

/**
 * Segment Toolbar
 * @param {*} props
 */
const SegmentEditDetailsToolbar = (props) => {
  const { loaded, permissions } = usePermissions();
  return (
    <Toolbar {...props} className='flex flex-row justify-between'>
      <SaveButton />
      {loaded ? (
        permissions['User Segments'] &&
        permissions['User Segments'].indexOf('delete') > -1 ? (
          <DeleteButton label='Delete Segment' />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

/**
 * Segment Edit form
 * @param {*} props
 */
export const SegmentEdit = (props) => {
  const [userProvider, setUserProvider] = useState(null);
  const [userCategory, setUserCategory] = useState(null);
  const [filterValues, setFilterValues] = useState(null);
  const [memberCount, setMemberCount] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const UserSelector = (props) => {
    if (userProvider === 'hasura' && userCategory === 'student')
      return (
        <SegmentStudents
          {...props}
          filterValues={filterValues}
          memberCount={memberCount}
          lastUpdated={lastUpdated}
        />
      );
    if (userProvider === 'hasura' && userCategory === 'teacher')
      return (
        <SegmentTeachers
          {...props}
          groupId={groupId}
          filterValues={filterValues}
          memberCount={memberCount}
          lastUpdated={lastUpdated}
        />
      );
    return <></>;
  };

  return (
    <div className='pl-16'>
      <Typography className='pt-16' variant='h6' color='textPrimary'>
        Segment Details
      </Typography>
      <Edit title={<SegmentTitle />} {...props}>
        <SimpleForm toolbar={<SegmentEditDetailsToolbar />}>
          <div
            className='flex flex-row items-center pl-16 pb-16'
            style={{ width: '70vw' }}
          >
            <TextInput
              label='Segment Name'
              source='name'
              validate={[required()]}
              variant='outlined'
              style={{ width: '45%', marginRight: '1rem' }}
            />
            <TextInput
              label='Segment Description'
              source='data.description'
              variant='outlined'
              style={{ width: '45%', marginRight: '1rem' }}
            />
          </div>
          <div className='pl-16'>
            <TextInput
              label='Segment Category'
              source='data.userCategory'
              format={(v) => (v === 'teacher' ? 'Teachers' : 'Students')}
              variant='outlined'
              disabled
            />
            <TextInput
              label='Group Id'
              source='id'
              style={{ display: 'none' }}
              variant='outlined'
              disabled
            />
          </div>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData && formData.data) {
                const { userProvider } = formData.data;
                setUserProvider(userProvider);

                const { userCategory } = formData.data;
                setUserCategory(userCategory);

                const { filterValues } = formData.data;
                setFilterValues(filterValues);

                const { memberCount } = formData.data;
                setMemberCount(memberCount);

                const lastUpdated = formData.data.updatedAt;
                setLastUpdated(lastUpdated);
              }
              if (formData) {
                const groupId = formData.id;
                setGroupId(groupId);
              }
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
      <UserSelector filterValues={filterValues} {...props} />
    </div>
  );
};

// MUI Drawer
function AddStudentDrawer(props) {
  const {
    showPanel,
    // handleClick,
    handleCloseClick,
    groupId,
    filterValues,
    memberCount,
  } = props;
  return (
    <>
      <Drawer anchor='right' open={showPanel} onClose={handleCloseClick}>
        <div className='px-16'>
          <LibButton label='Close' onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </LibButton>
          <Typography
            variant='h6'
            color='textPrimary'
            style={{ marginBottom: '1rem' }}
          >
            Modify segment
          </Typography>
          <UsersList
            groupId={groupId}
            filterValues={filterValues}
            memberCount={memberCount}
          />
        </div>
      </Drawer>
    </>
  );
}

function AddTeacherDrawer(props) {
  const {
    showPanel,
    // handleClick,
    handleCloseClick,
    groupId,
    filterValues,
    memberCount,
  } = props;
  return (
    <>
      <Drawer anchor='right' open={showPanel} onClose={handleCloseClick}>
        <div className='px-16'>
          <LibButton label='Close' onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </LibButton>
          <Typography variant='h6' color='textPrimary'>
            Modify segment
          </Typography>
          <TeacherList
            groupId={groupId}
            filterValues={filterValues}
            memberCount={memberCount}
          />
        </div>
      </Drawer>
    </>
  );
}

// Selected Student Segment Users
function SegmentStudents(props) {
  const { id } = props;
  const { filterValues, memberCount, lastUpdated } = props;
  const [showPanel, setShowPanel] = useState(true);

  const handleClick = () => {
    setShowPanel(true);
  };
  const handleCloseClick = () => {
    setShowPanel(false);
  };

  const SegmentStudentDetails = () => {
    const UserAttributes = () => {
      if (!filterValues) return null;

      const flattenFilterValues = (filterValues, rows = []) => {
        for (const filter in filterValues) {
          if (
            typeof filterValues[filter] === 'string' ||
            typeof filterValues[filter] === 'boolean' ||
            Array.isArray(filterValues[filter])
          ) {
            rows.push({ attributeName: filter, value: filterValues[filter] });
          } else flattenFilterValues(filterValues[filter], rows);
        }
        return rows;
      };

      const rows = flattenFilterValues(filterValues);
      const sanitise = (attr) => {
        attr = attr.replace('_', ' ');
        return attr;
      };
      const filterValueChipify = (value) => {
        if (typeof value === 'string') return <Chip label={value} />;
        if (typeof value === 'boolean') {
          if (value) return <Chip label='True' />;
          return <Chip label='False' />;
        }
        return value.map((val) => <Chip label={val} />);
      };
      return (
        <TableContainer>
          <Table aria-label='caption table'>
            <caption>
              Student count:&nbsp;
              <Typography variant='h6' style={{ display: 'inline' }}>
                {memberCount.toLocaleString('en-IN')}
              </Typography>
              &nbsp;
              <Typography
                style={{ display: 'inline' }}
                variant='subtitle2'
              >{`(Last Updated: ${lastUpdated})`}</Typography>
            </caption>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.attributeName}>
                  <>
                    <TableCell
                      component='th'
                      scope='row'
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      {sanitise(row.attributeName)}
                    </TableCell>
                    <TableCell align='center'>
                      {filterValueChipify(row.value)}
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };
    return (
      <div className='py-16 px-16 MuiPaper-elevation1 MuiPaper-root MuiPaper-rounded`'>
        {memberCount === 0 ? 'Segment is empty.' : <UserAttributes />}
      </div>
    );
  };

  // const Card = withStyles((theme) => ({
  //   root: {
  //     [theme.breakpoints.up('sm')]: {
  //       order: -1, // display on the left rather than on the right of the list
  //       width: '15em',
  //       marginRight: '1em',
  //     },
  //     [theme.breakpoints.down('sm')]: {
  //       display: 'none',
  //     },
  //   },
  // }))(MuiCard);

  return (
    <div className='mt-16'>
      <Typography
        variant='h6'
        color='textPrimary'
        style={{ marginBottom: '1rem' }}
      >
        Segment Attributes{' '}
      </Typography>
      <SegmentStudentDetails {...props} />
      <Toolbar style={{ marginTop: '0' }} className='MuiPaper-elevation1'>
        <Button
          color='primary'
          variant='contained'
          aria-label='add'
          onClick={handleClick}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </Toolbar>
      <AddStudentDrawer
        groupId={id}
        showPanel={showPanel}
        filterValues={filterValues}
        memberCount={memberCount}
        handleClick={handleClick}
        handleCloseClick={handleCloseClick}
      />
    </div>
  );
}

// Selected Teacher Users
function SegmentTeachers(props) {
  const { groupId } = props;
  const { filterValues, memberCount, lastUpdated } = props;
  const [showPanel, setShowPanel] = useState(true);

  const handleClick = () => {
    setShowPanel(true);
  };
  const handleCloseClick = () => {
    setShowPanel(false);
  };

  const SegmentTeacherDetails = () => {
    const UserAttributes = () => {
      if (!filterValues) return null;

      const flattenFilterValues = (filterValues, rows = []) => {
        for (const filter in filterValues) {
          if (
            typeof filterValues[filter] === 'string' ||
            typeof filterValues[filter] === 'boolean' ||
            Array.isArray(filterValues[filter])
          ) {
            rows.push({ attributeName: filter, value: filterValues[filter] });
          } else flattenFilterValues(filterValues[filter], rows);
        }
        return rows;
      };

      const rows = flattenFilterValues(filterValues);
      const sanitise = (attr) => {
        attr = attr.replace('_', ' ');
        return attr;
      };
      const filterValueChipify = (value) => {
        if (typeof value === 'string') return <Chip label={value} />;
        if (typeof value === 'boolean') {
          if (value) return <Chip label='True' />;
          return <Chip label='False' />;
        }
        return value.map((val) => <Chip label={val} />);
      };
      return (
        <TableContainer>
          <Table aria-label='caption table'>
            <caption>
              Teacher Count:&nbsp;
              <Typography variant='h6' style={{ display: 'inline' }}>
                {memberCount && memberCount.toLocaleString('en-IN')}
              </Typography>
              &nbsp;
              <Typography
                style={{ display: 'inline' }}
                variant='subtitle2'
              >{`(Last Updated: ${lastUpdated})`}</Typography>
            </caption>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.attributeName}>
                  <>
                    <TableCell
                      component='th'
                      scope='row'
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      {sanitise(row.attributeName)}
                    </TableCell>
                    <TableCell align='center'>
                      {filterValueChipify(row.value)}
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };
    return (
      <div className='py-16 px-16 MuiPaper-elevation1 MuiPaper-root MuiPaper-rounded`'>
        {memberCount === 0 ? (
          'Segment is empty. Add users to begin.'
        ) : (
          <UserAttributes />
        )}
      </div>
    );
  };

  return (
    <div className='mt-16'>
      <Typography
        variant='h6'
        color='textPrimary'
        style={{ marginBottom: '1rem' }}
      >
        Segment Attributes{' '}
      </Typography>
      <SegmentTeacherDetails {...props} />
      <Toolbar style={{ marginTop: '0' }} className='MuiPaper-elevation1'>
        <Button
          color='primary'
          variant='contained'
          aria-label='add'
          onClick={handleClick}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </Toolbar>
      <AddTeacherDrawer
        groupId={groupId}
        showPanel={showPanel}
        filterValues={filterValues}
        memberCount={memberCount}
        handleClick={handleClick}
        handleCloseClick={handleCloseClick}
      />
    </div>
  );
}
