/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { Typography, Grid, Divider } from '@material-ui/core';
import CountPage from './countPage';
import { NormalBox } from './Box';

const headers = {
  'content-type': 'application/json',
  'x-hasura-admin-secret': process.env.REACT_APP_BOT_DATA_HASURA_ADMIN_SECRET,
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_BOT_DATA_HASURA_SERVER_URL,
  headers,
});

const useStyles = makeStyles(() => ({
  textField: { margin: '2px 5px' },
}));

const SMSMonitoring = () => {
  const classes = useStyles();
  const [clickCount, setClickCount] = useState([]);

  useEffect(() => {
    const fetchHeathStats = async () => {
      const response = await fetch(
        'https://api-ssl.bitly.com/v4/bitlinks/bit.ly/samikshaBot/clicks/summary?unit=day',
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BITLY_AUTH_TOKEN}`,
          },
        }
      );

      const result = await response.json();
      setClickCount(result);
    };

    fetchHeathStats();
  }, []);
  const { total_clicks } = clickCount;
  const progressDataArray = [
    { id: '1', title: '# Users Opted-In', progress: null },
    { id: '2', title: '# Users Completed full chatbot', progress: 100 },
    { id: '3', title: '# Users completed 30%', progress: 30 },
    { id: '4', title: '# Users completed 50%', progress: 50 },
    { id: '5', title: '# Users completed 70%', progress: 70 },
  ];

  return (
    <ApolloProvider client={client}>
      <div className={classes.root}>
        <Typography variant='h5' component='h5' style={{ textAlign: 'center' }}>
          Mentoring Feedback Survey Statistics
        </Typography>
        <br />
        <Divider />
        <br />
        <Grid container justify='center' spacing={3}>
          <NormalBox
            title='# SMS/WA Broadcast Sent'
            count={(32550).toLocaleString('en-IN')}
          />
          <NormalBox
            title='# SMS/WA Broadcasts Delivered (with % of overall)'
            count={(28806).toLocaleString('en-IN')}
            percent='88.5'
          />
          <NormalBox
            title='# Users interacted with the message/# Bot link clicks'
            count={total_clicks ? total_clicks.toLocaleString('en-IN') : ''}
          />
        </Grid>
        <br />
        <Divider />
        <br />
        <div className='widget w-full p-12'>
          <Grid container justify='center' spacing={2}>
            {progressDataArray.map((el) => {
              return <CountPage key={el.id} componentData={el} />;
            })}
          </Grid>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default SMSMonitoring;
