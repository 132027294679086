import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { client } from '../../../api-clients/users-client';
import { clientGQL } from '../../../dataProviders/clientGQL';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useDataProvider } from 'react-admin';
const config = require('../../../../config.json');


const statusMap = {
    'Active': true,
    'Disabled': false
};

const UpdateProcess = (props) => {
    const { fileData, showImportModal } = props;
    const scrollInterval = useRef();
    const progressBar = useRef();
    const logsRef = useRef();
    const warningRef = useRef();
    const [currentSchool, setCurrentSchool] = useState(1);
    const [currentMentor, setCurrentMentor] = useState(1);
    const [logData, setLogData] = useState([])
    const [updatingMapping, setUpdatingMapping] = useState(true);
    const isMobile = window.innerWidth < 769;
    const dataProvider = useDataProvider();

    // Temp var to store update logss
    let tempLogs = [];

    // Temp var to allow auto scrolling in logs div
    let scroll = 0;


    const updateMentors = async () => {
        setUpdatingMapping(true);
        for (let i = 0; i < fileData.length; i++) {
            const record = fileData[i];

            setCurrentSchool(i + 1)
            setCurrentMentor(i);
            const schoolCode = record['School Code'];

            // Fetching school data to get currently mapped mentors 
            let schoolData = await dataProvider.getOne('school', { id: schoolCode });
            if (!schoolData.data) {
                tempLogs.push({ schoolCode: schoolCode, mentor: '', status: 'Failed', desc: "No school exits for this school code" });
                setLogData(tempLogs);
                continue;
            }
            const mentors = record['Mentor(s) Username']?.length ? record['Mentor(s) Username'].split(',') : [];
            const currentMentors = schoolData?.data?.school_mentor_mapping?.map(el => el.mentor_username);

            // Finding new mentors to add
            const mentorsToAdd = mentors.filter(el => currentMentors.find((x) => x == el) == undefined);
            // console.log("Mentors to add -->", mentorsToAdd);

            // Finding mentors to remove
            const mentorsToRemove = currentMentors.filter(el => mentors.find((x) => x == el) == undefined);
            // console.log("Mentors to remove -->", mentorsToRemove);

            // Adding new mentors if any
            for (let j = 0; j < mentorsToAdd.length; j++) {
                // Fetching mentor details from Fusionauth
                let res = await dataProvider.getList('userSamiksha-mentor', {
                    pagination: {
                        page: 1,
                        perPage: 1
                    },
                    sort: {
                        field: 'username',
                        order: 'ASC'
                    },
                    filter: {
                        mentorUsername: [mentorsToAdd[j]]
                    }
                });

                const mentorData = res?.data?.[0];
                if (!mentorData) {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToAdd[j], status: 'Failed', desc: "No mentor exists for this username" });
                    setLogData(tempLogs);
                    continue;
                }
                if (!mentorData.active) {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToAdd[j], status: 'Failed', desc: "Non Active mentors cannot be mapped to school" });
                    setLogData(tempLogs);
                    continue;
                }

                // Adding to hasura
                const mentorAddResponse = await mapUserToSchool(schoolCode, mentorData);
                if (mentorAddResponse?.insert_school_mentor_mapping?.returning?.length) {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToAdd[j], status: 'Success', desc: `${mentorsToAdd[j]} mapped successfully` });
                    setLogData(tempLogs);
                    continue;
                } else {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToAdd[j], status: 'Failed', desc: `Unable to map ${mentorsToAdd[j]} to school. Try again ` });
                    setLogData(tempLogs);
                    continue;
                }
            }

            // Removing mapped mentors if any
            for (let j = 0; j < mentorsToRemove.length; j++) {
                const mentorRemoveResponse = await removeUserMapping(schoolCode, mentorsToRemove[j]);
                if (mentorRemoveResponse?.delete_school_mentor_mapping?.affected_rows) {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToRemove[j], status: 'Success', desc: `${mentorsToRemove[j]} removed successfully` });
                    setLogData(tempLogs);
                    continue;
                } else {
                    tempLogs.push({ schoolCode: schoolCode, mentor: mentorsToRemove[j], status: 'Failed', desc: `Unable to remove ${mentorsToRemove[j]} mapping from school. Try again` });
                    setLogData(tempLogs);
                    continue;
                }
            }
            progressBar.current.style.width = `${Math.ceil((i / (fileData.length - 1)) * 100)}%`;
        }
        setUpdatingMapping(false);
    }

    const mapUserToSchool = async (schoolCode, user) => {
        try {
            const userObj = {
                school_code: schoolCode,
                mentor_username: user.username,
                mentor_block: user.data.roleData.block,
                mentor_mobile: user.mobilePhone
            }
            let res = await clientGQL(
                `
            mutation ($object: [school_mentor_mapping_insert_input!]! = {}) {
                insert_school_mentor_mapping(objects: $object) {
                  returning {
                    id
                    mentor_block
                    mentor_mobile
                    mentor_username
                  }
                }
              }

            `,
                { object: userObj }
            )
            return res.data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    const removeUserMapping = async (schoolCode, username) => {
        try {
            let res = await clientGQL(
                `
            mutation MyMutation {
                delete_school_mentor_mapping(where: {school_code: {_eq: "${schoolCode}"}, _and: {mentor_username: {_eq: "${username}"}}}) {
                    affected_rows
                  }
                }
            `
            )
            // console.log("DEL RESPONSE ->", res);
            return res.data;
        }
        catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    useEffect(() => {
        setTimeout(() => updateMentors(), 1000)

        // An interval to allow for automatic scroll of update user logs
        scrollInterval.current = setInterval(() => {
            if (logsRef.current) {
                logsRef.current.scrollTop = scroll;
                scroll += 50;
            }
        }, 100)

        // Clearing state on exit
        return () => {
            clearInterval(scrollInterval.current);
            setLogData([]);
            setUpdatingMapping(false);
            tempLogs = [];
            scrollInterval.current = null;
            progressBar.current = null;
            logsRef.current = null
            setCurrentMentor(1);
        };
    }, [])

    useEffect(() => {
        if (!updatingMapping && logData.length > 0) clearInterval(scrollInterval.current)
    }, [updatingMapping])

    const exportLogsToCsv = () => {
        if (!updatingMapping && logData?.length) {
            const recordsForExports = logData.map(rec => {
                const recForExport = {
                    'School Code': rec?.schoolCode,
                    'Mentor': rec?.mentor,
                    'Status': rec?.status,
                    'Description': rec?.desc
                }
                return recForExport;
            });
            jsonExport(recordsForExports, {
                headers: ['Username', 'Status', 'Description']
            }, (err, csv) => {
                downloadCSV(csv, `Update User Logs ${new Date()}`);
            });
        }
    }


    return (
        <div className={styles.container}>
            {!updatingMapping && !logData.length && <div> Starting update ...</div>}
            {updatingMapping && <div>Updating mapping for school {currentSchool} of {fileData?.length} ...</div>}
            {!updatingMapping && logData?.length > 0 && <div>Update Complete</div>}
            <div className={styles.progressContainer}><div className={styles.progressBar} style={updatingMapping ? {} : { animation: 'none' }} ref={progressBar}></div></div>
            <div className={styles.logsContainer}>
                <div className={styles.logsHeader}>
                    <div>#</div>
                    <div>School Code</div>
                    <div>Mentor</div>
                    <div>Status</div>
                    {!isMobile && <div>Description</div>}
                </div>
                <div className={styles.logsData} ref={logsRef}>
                    {logData?.length > 0 && logData.map((el, i) => <div className={styles.dataCell}>
                        <div>{i + 1}</div>
                        <div>{el.schoolCode}</div>
                        <div>{el.mentor}</div>
                        <div style={el.status == 'Success' ? { color: '#007500' } : el.status == 'Failed' ? { color: '#D10000' } : {}}>{el.status}</div>
                        {!isMobile && <div>{el.desc}</div>}
                    </div>)}
                </div>
            </div>
            {updatingMapping && <p style={{ color: 'red', padding: '1rem 0rem', fontWeight: 600 }} className='animate__animated animate__slow animate__flash' ref={warningRef}>Do not refresh this page!</p>}
            <div className={styles.btnContainer}>
                <div className={updatingMapping ? styles.disabled : ''} onClick={exportLogsToCsv}>Export</div>
                <div className={updatingMapping ? styles.disabled : ''} onClick={() => { if (!updatingMapping) { showImportModal(false); window.location.reload() } }}>Done</div>
            </div>
        </div >

    );
}

export default UpdateProcess;