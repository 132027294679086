import React from 'react';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import {
  AccountCircle,
  AddCircle,
  Assessment,
  AssignmentTurnedIn,
  BarChart,
  Build,
  Class,
  Dashboard,
  Event,
  Face,
  Grade,
  Group,
  GroupAdd,
  Help,
  ImportContacts,
  LibraryBooks,
  LocationOn,
  Message,
  NoteAdd,
  People,
  PeopleAlt,
  PieChart,
  QuestionAnswer,
  School,
  Search,
  Settings,
  Sms,
  Subject,
  TableChart,
  Timeline,
  TimerOff,
  ViewStream,
  Visibility,
  Web,
  WhereToVote,
} from '@material-ui/icons';

export const UsersIcon = () => <AccountCircle />;
export const AddCircleIcon = () => <AddCircle />;
export const AssessmentIcon = () => <Assessment />;
export const AssignmentIcon = () => <AssignmentTurnedIn />;
export const ArrowDownIcon = () => <ArrowDown />;
export const ArrowUpIcon = () => <ArrowUp />;
export const BarChartIcon = () => <BarChart />;
export const BuildIcon = () => <Build />;
export const ClassIcon = () => <Class />;
export const DashboardIcon = () => <Dashboard />;
export const EventIcon = () => <Event />;
export const StudentIcon = () => <Face />;
export const GradeIcon = () => <Grade />;
export const GroupsIcon = () => <Group />;
export const GroupAddIcon = () => <GroupAdd />;
export const HelpIcon = () => <Help />;
export const ContactsIcon = () => <ImportContacts />;
export const LibraryBooksIcon = () => <LibraryBooks />;
export const LocationOnIcon = () => <LocationOn />;
export const MessageIcon = () => <Message />;
export const NoteAddIcon = () => <NoteAdd />;
export const PeopleIcon = () => <People />;
export const PeopleAltIcon = () => <PeopleAlt />;
export const PieChartIcon = () => <PieChart />;
export const QuestionAnswerIcon = () => <QuestionAnswer />;
export const SchoolIcon = () => <School />;
export const SearchIcon = () => <Search />;
export const ComponentsIcon = () => <Settings />;
export const SettingsIcon = () => <Settings />;
export const SmsIcon = () => <Sms />;
export const SubmissionIcon = () => <Subject />;
export const TableChartIcon = () => <TableChart />;
export const TimelineIcon = () => <Timeline />;
export const DeadlineIcon = () => <TimerOff />;
export const ViewStreamIcon = () => <ViewStream />;
export const VisibilityIcon = () => <Visibility />;
export const WebIcon = () => <Web />;
export const Location2Icon = () => <WhereToVote />;
export const CustomIcons = {
  UsersIcon,
  AddCircleIcon,
  AssessmentIcon,
  AssignmentIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  BarChartIcon,
  BuildIcon,
  ClassIcon,
  DashboardIcon,
  EventIcon,
  StudentIcon,
  GradeIcon,
  GroupsIcon,
  GroupAddIcon,
  HelpIcon,
  ContactsIcon,
  LibraryBooksIcon,
  LocationOnIcon,
  MessageIcon,
  NoteAddIcon,
  PeopleIcon,
  PeopleAltIcon,
  PieChartIcon,
  QuestionAnswerIcon,
  SchoolIcon,
  SearchIcon,
  ComponentsIcon,
  SettingsIcon,
  SmsIcon,
  SubmissionIcon,
  TableChartIcon,
  TimelineIcon,
  DeadlineIcon,
  ViewStreamIcon,
  VisibilityIcon,
  WebIcon,
  Location2Icon,
};
