import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SmallBox } from './Box';

const CountPage = (props) => {
  const {
    componentData: { title, progress },
  } = props;

  let COUNT_DATA = '';
  let TOTAL_COUNT = '';

  TOTAL_COUNT = gql`
    query CountData {
      samiksha_aggregate {
        aggregate {
          count
        }
      }
    }
  `;
  COUNT_DATA = gql`
      query CountData {
        samiksha_aggregate(
          where: {  progress: { _eq: ${progress} } }
        ) {
          aggregate {
            count
          }
        }
      }`;

  const totalRow = useQuery(TOTAL_COUNT);
  const rowCount = useQuery(COUNT_DATA);

  if (totalRow.data && rowCount.data) {
    return (
      <SmallBox
        title={title}
        count={rowCount.data.samiksha_aggregate.aggregate.count.toLocaleString(
          'en-IN'
        )}
        percent={(
          (rowCount.data.samiksha_aggregate.aggregate.count /
            parseInt(32550, 10)) *
          100
        ).toFixed(1)}
        // fetchDataList={fetchData}
      />
    );
  }
  return null;
};

export default CountPage;
