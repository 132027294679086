import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { client } from '../../../api-clients/users-client';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
const config = require('../../../../config.json');

const statusMap = {
    'Active': true,
    'Disabled': false
};

const UpdateProcess = (props) => {
    const { fileData, showImportModal } = props;
    const scrollInterval = useRef();
    const progressBar = useRef();
    const logsRef = useRef();
    const warningRef = useRef();
    const [currentUser, setCurrentUser] = useState(1);
    const [logData, setLogData] = useState([])
    const [updatingUsers, setUpdatingUsers] = useState(true);
    const isMobile = window.innerWidth < 769;

    // Temp var to store update logss
    let tempLogs = [];

    // Temp var to allow auto scrolling in logs div
    let scroll = 0;


    const updateUsers = async () => {
        setUpdatingUsers(true);
        // for (let i = 0; i < 2; i++) {
        for (let i = 0; i < fileData.length; i++) {
            const record = fileData[i].split(',');

            setCurrentUser(i);
            const userId = record[0];
            const username = record[2];
            // const finalParams = {
            //     startRow: 0,
            //     numberOfResults: 1,
            //     queryString: `(username: ${username})`,
            // };
            // const response = await client.get("/api/searchUserByQuery", { params: finalParams, headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
            const response = await client.get(`/api/user/${userId}`, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });

            // console.log(response)
            if (response?.data?.responseCode != 'OK') {
                tempLogs.push({ id: i + 1, username: username, status: 'Failed', desc: response?.data?.params?.errMsg || "Failed to get user" });
                setLogData(tempLogs);
            } else {
                const user = response?.data?.result?.users?.[0];
                const changeStatus = statusMap[record[9]] != user.active;
                // Making changes to user data
                if (record[1]) user.fullName = record[1];
                if (record[2]) user.username = record[2];
                if (record[3]) user.data.roleData.designation = record[3];
                if (record[4]) user.data.roleData.district = record[4];
                if (record[5]) user.data.roleData.block = record[5];
                if (record[6]) user.data.roleData.cluster = record[6];
                if (record[7]) user.mobilePhone = record[7];
                if (record[8]) user.email = record[8];
                if (record[10]) user.data.reasonForInactive = record[10];

                if (changeStatus)
                    if (statusMap[record[9]]) {
                        const activationResponse = await client.patch(`/api/user/${user.id}/${statusMap[record[9]] ? 'activate' : 'deactivate'}`, {}, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
                        if (activationResponse.status == 200) {
                            const response = await client.patch("/api/updateUser/" + user.id, user, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
                            if (response?.data?.result) {
                                tempLogs.push({ id: i + 1, username: username, status: 'Updated', desc: `${username} updated successfully` });
                                setLogData(tempLogs);
                            } else {
                                const errorStrings = [];
                                const errors = response?.data?.exception?.fieldErrors;
                                if (Object.keys(errors))
                                    Object.keys(errors).forEach(key => {
                                        errorStrings.push(errors[key]?.[0]?.message);
                                    })
                                tempLogs.push({ id: i + 1, username: username, status: 'Failed', desc: `${errorStrings.join(". \n")}` });
                                setLogData(tempLogs);
                            }
                        }
                    } else {
                        const response = await client.patch("/api/updateUser/" + user.id, user, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
                        if (response?.data?.result) {
                            const userRes = await client.patch(`/api/user/${user.id}/${statusMap[record[9]] ? 'activate' : 'deactivate'}`, {}, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
                            if (userRes?.data?.result) {
                                tempLogs.push({ id: i + 1, username: username, status: 'Updated', desc: `${username} updated successfully` });
                                setLogData(tempLogs);
                            } else {
                                const errorStrings = [];
                                const errors = userRes?.data?.exception?.fieldErrors;
                                if (Object.keys(errors))
                                    Object.keys(errors).forEach(key => {
                                        errorStrings.push(errors[key]?.[0]?.message);
                                    })
                                tempLogs.push({ id: i + 1, username: username, status: 'Failed', desc: `${errorStrings.join(". \n")}` });
                                setLogData(tempLogs);
                            }
                        }
                    }
                else {
                    const response = await client.patch("/api/updateUser/" + user.id, user, { headers: { 'x-application-id': config.fusionAuth.fusionAuthApplicationId } });
                    if (response?.data?.result) {
                        tempLogs.push({ id: i + 1, username: username, status: 'Updated', desc: `${username} updated successfully` });
                        setLogData(tempLogs);
                    } else {
                        const errorStrings = [];
                        const errors = response?.data?.exception?.fieldErrors;
                        if (Object.keys(errors))
                            Object.keys(errors).forEach(key => {
                                errorStrings.push(errors[key]?.[0]?.message);
                            })
                        tempLogs.push({ id: i + 1, username: username, status: 'Failed', desc: `${errorStrings.join(". \n")}` });
                        setLogData(tempLogs);
                    }
                }

            }
            progressBar.current.style.width = `${Math.ceil((i / (fileData.length - 1)) * 100)}%`;
        }
        setUpdatingUsers(false);
    }

    useEffect(() => {
        setTimeout(() => updateUsers(), 1000)

        // An interval to allow for automatic scroll of update user logs
        scrollInterval.current = setInterval(() => {
            if (logsRef.current) {
                logsRef.current.scrollTop = scroll;
                scroll += 50;
            }
        }, 100)

        // Clearing state on exit
        return () => {
            clearInterval(scrollInterval.current);
            setLogData([]);
            setUpdatingUsers(false);
            tempLogs = [];
            scrollInterval.current = null;
            progressBar.current = null;
            logsRef.current = null
            setCurrentUser(1);
        };
    }, [])

    useEffect(() => {
        if (!updatingUsers && logData.length > 0) clearInterval(scrollInterval.current)
    }, [updatingUsers])

    const exportLogsToCsv = () => {
        if (!updatingUsers && logData?.length) {
            const recordsForExports = logData.map(rec => {
                const recForExport = {
                    'Username': rec?.username,
                    'Status': rec?.status,
                    'Description': rec?.desc
                }
                return recForExport;
            });
            jsonExport(recordsForExports, {
                headers: ['Username', 'Status', 'Description']
            }, (err, csv) => {
                downloadCSV(csv, `Update User Logs ${new Date()}`);
            });
        }
    }


    return (
        <div className={styles.container}>
            {!updatingUsers && !logData.length && <div> Starting update ...</div>}
            {updatingUsers && <div>Updating user {currentUser} of {fileData?.length - 1} ...</div>}
            {!updatingUsers && logData?.length > 0 && <div>Update Complete</div>}
            <div className={styles.progressContainer}><div className={styles.progressBar} style={updatingUsers ? {} : { animation: 'none' }} ref={progressBar}></div></div>
            <div className={styles.logsContainer}>
                <div className={styles.logsHeader}>
                    <div>#</div>
                    <div>Username</div>
                    <div>Status</div>
                    {!isMobile && <div>Description</div>}
                </div>
                <div className={styles.logsData} ref={logsRef}>
                    {logData?.length > 0 && logData.map(el => <div className={styles.dataCell}>
                        <div>{el.id}</div>
                        <div>{el.username}</div>
                        <div style={el.status == 'Updated' ? { color: '#007500' } : el.status == 'Failed' ? { color: '#D10000' } : {}}>{el.status}</div>
                        {!isMobile && <div>{el.desc}</div>}
                    </div>)}
                </div>
            </div>
            {updatingUsers && <p style={{ color: 'red', padding: '1rem 0rem', fontWeight: 600 }} className='animate__animated animate__slow animate__flash' ref={warningRef}>Do not refresh this page!</p>}
            <div className={styles.btnContainer}>
                <div className={updatingUsers ? styles.disabled : ''} onClick={exportLogsToCsv}>Export</div>
                <div className={updatingUsers ? styles.disabled : ''} onClick={() => { if (!updatingUsers) { showImportModal(false); window.location.reload() } }}>Done</div>
            </div>
        </div >

    );
}

export default UpdateProcess;