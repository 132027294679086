import { useState, useEffect } from 'react';
const util = require('util');
const axios = require('axios');

export default function useCDACTrack(conversationId) {
  const [status, setStatus] = useState(null);
  const client = axios.create({
    baseURL: process.env.REACT_APP_COMMS_SAMAGRA_BASE_URL
  })
  useEffect(() => {
    async function fetchData() {
    try {
        const status = await client
        .get(
            `/status/cdac/bulk?campaignId=${conversationId}`
        )     
        setStatus(status);
    }
    catch {
        console.log('Error fetching status.');
    }
  }
  fetchData();
}, []);    
  return status;
}
