/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  List,
  Create,
  Edit,
  Datagrid,
  EditButton,
  TextField,
  Toolbar,
  SimpleForm,
  CloneButton,
  FunctionField,
  FormDataConsumer,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TopToolbar,
  ListButton,
  Confirm,
  DeleteButton,
  BooleanInput,
  TextInput,
  SaveButton,  
  SelectInput,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceInput,
  useNotify,
  useRedirect,
  useQuery,
  useMutation,
  Link,
  required,
  regex,
} from 'react-admin';

import { useFormState } from 'react-final-form';

import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import SmsIcon from '@material-ui/icons/Sms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { isNullableType } from 'graphql';
import ConditionalFormBroadcastInput from '../components/ra-custom-compontents/conditional-form-broadcast-fiel.ra.component';
import useODKForms from '../../e/pages/campaign/useODKForms';
import countChars from '../../e/utils/countChars';
import useCDACTrack from './useCDACTrack';
import useMembersCount from './useMemberCount';


const axios = require('axios');
const config = require('../../config');
const { FusionAuthClient } = require('../../@fusionauth/node-client');

const client = new FusionAuthClient(
  config.fusionAuthCampaignManager.fusionAuthAPIKey,
  config.fusionAuthCampaignManager.fusionAuthURL
);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginRight: '4rem',
  },
  media: {
    height: 140,
  },
  selectedCard: {
    background: '#358fd647',
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px'
  },
  textCenter: {
    textAlign: 'center'
  },
  textBold: {
    '& > span': {
      fontWeight: '700!important'
    }
  },
  conversationBackToolbar: {
    paddingTop: '0px !important'
  },
  selectInput: {
    width: '100%',
    paddingRight: '2rem',
    '& div': {
      background: '#f8f9fc !important'
    }
  }
});

const validateConfirm = regex(/CONFIRM/g, 'Incorrect string.');


const CustomCloneButton = (props) => (
  <CloneButton style={{ marginLeft: '-20px' }} {...props} label='' />
);
const CustomEditButton = (props) => (
  <EditButton style={{ marginLeft: '-20px', color: '#757575' }} {...props} label='' />
);
const CustomDeleteButton = (props) => (
  <DeleteButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);

const campaignManagerInstance = axios.create({
  baseURL: config.fusionAuthCampaignManager.fusionAuthURL,
  headers: {
    Authorization: config.fusionAuthCampaignManager.fusionAuthAPIKey,
  },
});

const sendConversation = async (id) => {  
  try {
    const sendResponse = await axios.get(
      `${process.env.REACT_APP_COMMS_SAMAGRA_BASE_URL}/start?campaignId=${id}`
    );
    const now = new Date().toLocaleString('en-IN');
    client.patchApplication(id, {
      application: {
        data: {
          lastSentAt: now,
        },
      },
    });
    return { ...sendResponse, status: true }
  } catch(e) {    
    const now = new Date().toLocaleString('en-IN');
    client.patchApplication(id, {
      application: {
        data: {
          lastSendAttemptFailedAt: now,
        },
      },
    });    
    return { status: false, error: e && e.message }
  }  
};

export const CampaignList = (props) => {
  const ConversationStatsPanel = ({ id, record, resource }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dateUpdated, setDateUpdated] = useState(false);
    const handleDialogClose = () => setOpen(false);
    const now = null;

    const status = useCDACTrack(record && record.id);
    const { data, loading: dateLoading, error } = useQuery({
      type: 'getOne',
      resource: 'conversations',
      payload: { id: record && record.id },
    });
    const membersCount = useMembersCount(
      record && record.data && record.data.group
    );
    if (record && record.data && !record.data.lastSentAt) 
    return (
      <div className='flex flex-col items-center py-16'>
      <div className='flex flex-col items-center'>
        <Typography variant='h6' className='text-grey-600 pb-16'>
          Send this conversation to track status!
        </Typography>            
      </div>
    </div>
    )

    if (!record || !status || !data)
      return (
        <div className='flex flex-col items-center py-16'>
          <div className='flex flex-col items-center'>
            <Typography variant='h6' className='text-grey-600 pb-16'>
              Fetching status
            </Typography>
            <CircularProgress />
          </div>
        </div>
      );   
    if (record && status && data) {
      return (
        <Fade in={status}>
          <div className='flex flex-col pl-48 py-16'>
            {/* <Typography variant='subtitle2' className='text-gray-800 uppercase pt-16'>Send</Typography>
        <div className='flex flex-row justify-between pl-16 pr-64 pt-16'>
          <div className='flex flex-col'>
          <Typography variant='subtitle2'>Send this campaign</Typography>
          <Typography>{`Trigger bulk sending of text messages to ${membersCount} users. This will cost ₹ ${ record.data ? (record.data.parts[0].creditPerUser * membersCount) : ''}  `}</Typography>
          </div>
          <CustomSendButton />
          <ConfirmDialog />
        </div>       */}
            <Typography
              variant='subtitle2'
              className='text-gray-800 uppercase pt-16 border-b-1 border-gray-400'
            >{`${record && record.name} Status`}</Typography>
            <div className='flex flex-row justify-evenly pl-16 pt-16 border-b-1 border-gray-400 pb-16'>
              <span className='flex flex-col'>
                <Typography
                  variant='subtitle2'
                  className='text-gray-600 text-center uppercase'
                >
                  Send Date
                </Typography>
                <Typography
                  variant='h6'
                  className='text-grey-700 text-center uppercase'
                >
                  {data.data && data.data.lastSentAt}
                </Typography>
              </span>
              <Typography variant='h5' className='self-center text-grey-600'>
                &gt;
              </Typography>
              <span className='flex flex-col'>
                <Typography
                  variant='subtitle2'
                  className='text-gray-600 text-center uppercase'
                >
                  Pending
                </Typography>
                <Typography
                  variant='h5'
                  className='text-grey-700 text-center uppercase'
                >
                  {status.data.submittedSMSCount}
                </Typography>
              </span>
              <Typography variant='h5' className='self-center text-grey-600'>
                &gt;
              </Typography>
              <span className='flex flex-col'>
                <Typography
                  variant='subtitle2'
                  className='text-gray-600 text-center uppercase'
                >
                  Delivered
                </Typography>
                <Typography
                  variant='h5'
                  className='text-green-700 text-center uppercase0'
                >
                  {status.data.deliveredSMSCount}
                </Typography>
              </span>
              <Typography variant='h5' className='self-center text-grey-600'>
                &gt;
              </Typography>
              <span className='flex flex-col'>
                <Typography
                  variant='subtitle2'
                  className='text-gray-600 text-center uppercase'
                >
                  Failed
                </Typography>
                <Typography
                  variant='h5'
                  className='text-red-700'
                  text-center
                  uppercase
                >
                  {status.data.failedSMSCount}
                </Typography>
              </span>
              <Typography variant='h5' className='self-center text-grey-600'>
                &gt;
              </Typography>
              <span className='flex flex-col'>
                <Typography
                  variant='subtitle2'
                  className='text-gray-600 text-center uppercase'
                >
                  Cost
                </Typography>
                <Typography
                  variant='h5'
                  className='text-amber-700 text-center uppercase'
                >
                  ₹{' '}
                  {(parseInt(status.data.submittedSMSCount) +
                    parseInt(status.data.deliveredSMSCount) +
                    parseInt(status.data.failedSMSCount) || 0) *
                    ((record.data &&
                      record.data.parts &&
                      record.data.parts[0] &&
                      record.data.parts[0].creditPerUser) ||
                      0)}
                </Typography>
              </span>
            </div>
          </div>
        </Fade>
      );
    }
    return <></>;
  };

  const ConversationStatus = (props) => {
    return (
      <Chip label={props.status} color={props.sent ? 'primary' : props.failed ? 'error' : 'secondary'} />
    );
  };

  const ConversationCategory = (props) => {
    return (
      <Chip label={props.category} color={props.students ? 'primary' : 'secondary'} />
    );
  }

  const classes = useStyles();

  return (
    <List
      className='pl-16'
      title='Conversations'
      {...props}
      perPage={50}
      bulkActionButtons={false}
      exporter={false}
      filterDefaultValues={{'applicationId': config.fusionAuthConsole.fusionAuthApplicationId}}
    >
      <Datagrid                
        expand={<ConversationStatsPanel />}
      >
        <TextField source='name' cellClassName={classes.textBold} />        
        {/* <TextField label='Sent At' source='data.lastSentAt' />      */}
        <FunctionField
          label='Status'
          render={(record) => {
            if (record && record.data && record.data.lastSentAt) {
              return <ConversationStatus status='Sent' sent={true} />;
            }
            else if(record && record.data && record.data.lastSendAttemptFailedAt) {
              return <ConversationStatus status='Failed' sent={false} failed={true} />;
            }
            return <ConversationStatus status='Draft' sent={false} />;
          }}
        />
        <TextField label='Message' source='data.parts[0].msg' cellClassName={classes.textEllipsis} />
        <ReferenceArrayField label='Segments' source='data.group' reference='segments' headerClassName={classes.textCenter} cellClassName={classes.textCenter} style={{margin: '1rem 0'}}>
          <SingleFieldList>
            <FunctionField render={ (record) => {
              if(record && record.data  && (record.data.userProvider === 'hasura')) {
              return <ConversationCategory category={record.name} students={true} />;
              }
              return <ConversationCategory category={record.name} students={false} />
            }} />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField label='Total Users' headerClassName={classes.textCenter} cellClassName={classes.textCenter} render={ 
          (record) =>  record && record.data && record.data.parts && record.data.parts[0] && record.data.parts[0].selectedUsersCount.toLocaleString('en-IN') } 
        />  
        {/* <CustomEditButton {...props} label='Edit' />  */}
        {/* <CustomCloneButton {...props} label='Clone' />     */}
        <FunctionField label='Edit' render={(record) => {
          const updatedProps = {...props, record};
           if (record && record.data && record.data.lastSentAt) {
             return <></>
           }
            else return <CustomEditButton {...updatedProps} label='Edit' />;
        }} />        
        <CustomCloneButton {...props} label='Duplicate' />
        {/* <CustomDeleteButton {...props} label='Delete' /> */}
      </Datagrid>
    </List>
  );
};

export const CampaignCreate = (props) => {
  const [selectedConversationType, setSelectedConversationType] = useState(
    'SMS'
  );
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [selectedUsersCount, setSelectedUsersCount] = useState(0);  
  const forms = useODKForms();
  const classes = useStyles();

  const handleChange = (type) => {
    setSelectedConversationType(type);
  };

  const ConfirmDialog = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const botLink = `https://api.whatsapp.com/send?text=${encodeURI(props.startingMessage)}&phone=${config.comms?.whatsappBotStagingNumber}`;
    return (
      <Confirm
        isOpen={open}
        loading={loading}
        title={selectedConversationType === 'SMS' ? `Send ${props && props.conversationName}` : `Generate ${props && props.conversationName} Bot`}
        content={selectedConversationType === 'SMS' ? `Are you sure you want to send this this conversation to ${selectedUsersCount} ${
          selectedUsersCount > 1 ? 'users' : 'user'
        }? This will cost ₹ ${totalCost}.` : `Bot Link: ${botLink}`}
        confirm={selectedConversationType === 'SMS' ? 'Confirm' : 'Copy'}                
        cancel={selectedConversationType === 'SMS' ? 'Cancel': 'Close'}        
        onConfirm={async () => {
          if(selectedConversationType === 'SMS') {
            const sendResponse = await sendConversation(conversationId);          
            if(!sendResponse.status) notify(sendResponse.error, 'warning');
            redirect(props && props.basePath);
          } else if(selectedConversationType === 'whatsapp') {
           
              navigator.clipboard.writeText(botLink)
              .then(() => { setOpen(false); notify('Link Copied!', 'info')})
              .catch((error) => { notify(error, 'warning'); })
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    );
  };

  const CampaignCreateToolbar = (props) => {
    const notify = useNotify();
    const { values: conversation } = useFormState();
    const [
      conversationCreate,
      { data: createdConversation, error, loading, loaded },
    ] = useMutation(
      {
        type: 'create',
        resource: 'conversations',
        payload: { data: conversation },
      },
      {        
        onSuccess: ({ data }) => {
          notify('Conversation created!', 'info');          
          setConversationId(data && data.id);
          setOpen(true);
        },
        onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
      }
    );

    return (
      <Toolbar {...props}>
        <SaveButton
          variant='outlined'
          color='primary'
          style={{ marginRight: '2rem' }}
          disabled={props.invalid}
          label={selectedConversationType === 'SMS' ? 'Create and send' : 'Create and generate link'}
          submitOnEnter={false}
          handleSubmitWithRedirect={() => { conversationCreate(); }}
          redirect={false}
          icon={selectedConversationType === 'SMS' ? <SmsIcon /> : <WhatsAppIcon />}
        />
        <SaveButton
          label='Save as draft'
          disabled={props.invalid}
          redirect={props.basePath}
        />
      </Toolbar>
    );
  };

  const ConversationCreateActions = ({ basePath, data }) => (
    <TopToolbar className={classes.conversationBackToolbar}>
      <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
    </TopToolbar>
  );
  
  const MediaCard = (props) => {
    const classes = useStyles();

    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={props.imageSrc} />
          <CardContent
            style={{ transition: 'all ease-in 0.3s' }}
            className={
              selectedConversationType === props.type
                ? classes.selectedCard
                : ''
            }
            onClick={() => {
              props.handleChange(props.type);
            }}
          >
            <Typography gutterBottom variant='subtitle2' component='h2'>
              {props.cardTitle}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.cardContent}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const MessageText = () => {
    const classes = useStyles();
    return (
      selectedConversationType === 'SMS' ? 
        <Fade in={selectedConversationType === 'SMS'}>
          <div style={{ width: '100%' }}>
          <Typography variant='subtitle1'>Message Text</Typography>
          <div className='flex flex-row pl-16'>
            <TextInput
              multiline
              label='Message'
              variant='outlined'
              validate={[required()]}
              type='text'
              style={{ width: '20vw', marginRight: '2rem' }}
              // parse={stringParser}
              // onChange={ () => }
              source='data.parts[0].msg'
            />
            <TextInput
              label='Credit per user'
              variant='outlined'
              disabled
              source='data.parts[0].creditPerUser'
              // value={creditPerUser && creditPerUser.toFixed(2)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>₹</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        </Fade>
       :         
        <Fade in={selectedConversationType === 'whatsapp'}>
          <div style={{ width: '75vw' }}>
            <Typography variant='subtitle1'>Chatbot</Typography>
            <div className='flex flex-row pl-16'>              
              <AutocompleteInput
                className={classes.selectInput}
                variant='outlined'
                choices={forms}
                translateChoice={false}
                label='Existing ODK Forms'                
                source={`data.parts[0].meta.formID`}
                onChange={(e) => {                                                      
                // props.input.onChange(t);
                console.log(e);
              }}
            />
            <TextInput
              variant='outlined'
              source='data.startingMessage'
              label='Starting Message'
              validate={[required()]}
              style={{width: '30%', marginRight: '1rem'}}
              translateChoice={false}
            />          
          </div>
        </div>
      </Fade>      
    );
  };

  const maxLength = (max, message = 'Name may not exceed 20 characters.') => (
    value
  ) => (value && value.length > max ? message : undefined);

  const validateName = [required(), maxLength(20)];

  async function asyncForEach(array, callback) {
    if(!array) return;
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  useEffect(() => {  
  async function calculateMemberCount () {
      let count = 0;
      await asyncForEach( selectedGroup, async (groupId) => {
          const retrievedGroup = await client.retrieveGroup(groupId);                   
          if(retrievedGroup && retrievedGroup.successResponse) {
            const { successResponse: { group } } = retrievedGroup;         
            count = count + (group && group.data && group.data.memberCount);
          }
        });  
        setSelectedUsersCount(count);
    };        
  calculateMemberCount();  
  }, [selectedGroup])


  return (
    <div class='pl-16 pt-16'>
      {/* <Typography variant='h6' color='textPrimary'>
        Conversation Details
      </Typography> */}
      <Create {...props} actions={<ConversationCreateActions {...props} />}>
        <SimpleForm toolbar={<CampaignCreateToolbar />}>
          <Typography variant='subtitle1'>Conversation Details</Typography>
          <div
            className='flex flex-row items-center pl-16 pb-16'
            style={{ width: '70vw' }}
          >
            <TextInput
              label='Name of conversation'
              style={{ width: '45%', marginRight: '1rem' }}
              source='name'
              variant='outlined'
              validate={validateName}
              helperText='Set a unique, descriptive name that this conversation will be identified with.'
            />      
            <TextInput
              label='Application'
              style={{display: 'none'}}
              source='data.applicationId'
              defaultValue={config.fusionAuthConsole.fusionAuthApplicationId}
            />    
          </div>          
          <>
            <Typography variant='subtitle1'>Conversation Type</Typography>
            <div className='flex flex-row pl-16 pt-16 pb-32'>
              <MediaCard
                imageSrc='assets/sms-example.jpg'
                type='SMS'
                handleChange={handleChange}
                cardTitle='SMS'
                cardContent='Send broadcast text messages to thousands of users'
              />
              <MediaCard imageSrc="assets/bot-example.jpg" type='whatsapp' handleChange={handleChange} cardTitle='WhatsApp' cardContent='Create WhatsApp chat bot based on an already existing or new ODK form.' />
            </div>

            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].provider'
              initialValue='cdac'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].selectedUsersCount'
            />
            <TextInput style={{ display: 'none' }} source='data.appName' />            
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].senderID'
              initialValue='HPGOVT'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].type'
              initialValue='broadcast'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].channel'
              initialValue='SMS'
            />

            <MessageText />
            {selectedConversationType === 'SMS' ? 
            <>
              <div className='py-16'>
                <Typography variant='subtitle1'>Audience</Typography>
                <div
                  className='flex flex-row items-center justify-between pl-16'
                  style={{ width: '30vw' }}
                >
                  <div className='border-r-2 border-gray pr-20 mb-20'>
                    <Typography variant='subtitle1' style={{ color: '#757575' }}>
                      Search from existing segments:
                    </Typography>
                    <ReferenceArrayInput
                      label='Segments'
                      variant='outlined'
                      source='data.group'
                      filter={{applicationId: config.fusionAuthConsole.fusionAuthApplicationId}}                      
                      reference='segments'
                      onChange={(groupId) => {                    
                        setSelectedGroup(groupId);                                        
                      }}
                    >
                    <AutocompleteArrayInput optionValue='id' optionText='name' />
                    </ReferenceArrayInput>
                  </div>
                  <div>
                    <Button color='primary'>
                      <Link to='/segments/create'>Or create new</Link>
                    </Button>
                  </div>
                </div>
                {selectedUsersCount ? (
                  <Fade in={selectedUsersCount != null}>
                    <div style={{ paddingLeft: '1.6rem' }}>
                      <Typography
                        variant='subtitle1'
                        color='textPrimary'
                        style={{ display: 'inline' }}
                      >
                        {selectedUsersCount.toLocaleString('en-IN')}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ display: 'inline' }}
                      >
                        {' '}
                        {selectedUsersCount > 1 ? 'users' : 'user'} selected.
                      </Typography>
                    </div>
                  </Fade>
                ) : (
                  ''
                )}
              </div>
              <Typography variant='subtitle1'>Total Cost</Typography>
              <div className='flex flex-row pl-16'>
              <TextInput
                label='Total Cost'
                variant='outlined'
                disabled
                source='data.parts[0].totalCost'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>₹</InputAdornment>
                  ),
                }}
              />
            </div>
            </> : 
            <>
               <div className='py-16'>
                <Typography variant='subtitle1'>Audience</Typography>
                <div
                  className='flex flex-row-reverse items-center justify-between pl-16'
                  style={{ width: '30vw' }}
                >
                  <div className='border-l-2 border-gray pl-20 mb-20'>
                    <Typography variant='subtitle1' style={{ color: '#757575' }}>
                      Or limit to existing segments:
                    </Typography>
                    <ReferenceArrayInput
                      label='Segments'
                      variant='outlined'
                      source='data.group'
                      filter={{applicationId: config.fusionAuthConsole.fusionAuthApplicationId}}                      
                      reference='segments'
                      onChange={(groupId) => {                    
                        setSelectedGroup(groupId);                                        
                      }}
                    >
                    <AutocompleteArrayInput optionValue='id' optionText='name' />
                    </ReferenceArrayInput>
                  </div>
                  <div>                    
                    <BooleanInput
                      source="data.addNewUserOnOptIn"
                      label='Make Bot Public'
                      defaultValue={true}
                    />
                  </div>
                </div>
                {selectedUsersCount ? (
                  <Fade in={selectedUsersCount != null}>
                    <div style={{ paddingLeft: '1.6rem' }}>
                      <Typography
                        variant='subtitle1'
                        color='textPrimary'
                        style={{ display: 'inline' }}
                      >
                        {selectedUsersCount.toLocaleString('en-IN')}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ display: 'inline' }}
                      >
                        {' '}
                        {selectedUsersCount > 1 ? 'users' : 'user'} selected.
                      </Typography>
                    </div>
                  </Fade>
                ) : (
                  ''
                )}
              </div>
            </>}    
                                      
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (
                  formData &&
                  formData.data &&
                  formData.data.parts &&
                  formData.data.parts[0]
                ) {
                  const creditPerUser =
                    formData.data.parts[0].msg &&
                    countChars.count(formData.data.parts[0].msg).messages *
                      0.05;

                  formData.data.parts[0].creditPerUser = creditPerUser;
                  formData.data.appName =
                    formData.name && formData.name.substr(0, 20);
                  formData.data.parts[0].selectedUsersCount = selectedUsersCount;                  
                  formData.data.parts[0].channel = selectedConversationType;
                  if (!selectedGroup) { 
                    setSelectedGroup(formData.data.group);                    
                  }                 
                  const totalCost =
                    ((selectedUsersCount || 0) * (creditPerUser || 0)).toFixed(2);
                  formData.data.parts[0].totalCost = totalCost;
                  setTotalCost(totalCost);
                }
                return (
                  <ConfirmDialog {...props} conversationName={formData.name} startingMessage={formData.data?.startingMessage} />
                );
              }}
            </FormDataConsumer>
          </>          
        </SimpleForm>
      </Create>
    </div>
  );
};

export const CampaignEdit = (props) => {
  const [selectedConversationType, setSelectedConversationType] = useState(
    null
  );
  const [selectedGroup, setSelectedGroup] = useState(null);  
  const [selectedUsersCount, setSelectedUsersCount] = useState(0);  
  const [conversationId, setConversationId] = useState(props && props.id);
  const [totalCost, setTotalCost] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const forms = useODKForms();
  const classes = useStyles();
  
  const handleChange = (type) => {
    setSelectedConversationType(type);
  };

   const ConfirmDialog = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const botLink = `https://api.whatsapp.com/send?text=${encodeURI(props.startingMessage)}&phone=${config.comms?.whatsappBotStagingNumber}`;
    return (
      <Confirm
        isOpen={open}
        loading={loading}
        title={selectedConversationType === 'SMS' ? `Send ${props && props.conversationName}` : `Generate ${props && props.conversationName} Bot`}
        content={selectedConversationType === 'SMS' ? `Are you sure you want to send this this conversation to ${selectedUsersCount} ${
          selectedUsersCount > 1 ? 'users' : 'user'
        }? This will cost ₹ ${totalCost}.` : `Bot Link: ${botLink}`}
        confirm={selectedConversationType === 'SMS' ? 'Confirm' : 'Copy'}                
        cancel={selectedConversationType === 'SMS' ? 'Cancel': 'Close'}        
        onConfirm={async () => {
          if(selectedConversationType === 'SMS') {
            const sendResponse = await sendConversation(conversationId);          
            if(!sendResponse.status) notify(sendResponse.error, 'warning');
            redirect(props && props.basePath);
          } else if(selectedConversationType === 'whatsapp') {
           
              navigator.clipboard.writeText(botLink)
              .then(() => { setOpen(false); notify('Link Copied!', 'info')})
              .catch((error) => { notify(error, 'warning'); })
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    );
  };
  
  const CampaignEditToolbar = (props) => {
    const { values: conversation } = useFormState();
    const [conversationUpdate, { error, loading, loaded }] = useMutation({
      type: 'update',
      resource: 'conversations',
      payload: { id: conversationId, data: conversation },
    });

    return (
      <Toolbar {...props}>
        <SaveButton
          variant='outlined'
          color='primary'
          style={{ marginRight: '2rem' }}
          disabled={props.invalid}
          label={selectedConversationType === 'SMS' ? 'Save and Send' : 'Generate Link'}
          submitOnEnter={false}
          handleSubmitWithRedirect={async () => {
            conversationUpdate();
            if (!error) {
              notify('Conversation updated!', 'info');
              setOpen(true);
            } else {
              notify(error);
            }
          }}
          redirect={false}
          icon={selectedConversationType === 'SMS' ? <SmsIcon /> : <WhatsAppIcon />}
          // endIcon={<Icon>send</Icon>}
         />
        <SaveButton
          label='Save draft'
          disabled={props.invalid}
          redirect={props.basePath}
        />
      </Toolbar>
    );
  };

  const ConversationEditActions = ({ basePath, data }) => (
    <TopToolbar className={classes.conversationBackToolbar}>
      <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
    </TopToolbar>
  );

  const MediaCard = (props) => {
    const classes = useStyles();

    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={props.imageSrc} />
          <CardContent
            style={{ transition: 'all ease-in 0.3s' }}
            className={
              selectedConversationType === props.type
                ? classes.selectedCard
                : ''
            }
            onClick={() => {
              props.handleChange(props.type);
            }}
          >
            <Typography gutterBottom variant='subtitle2' component='h2'>
              {props.cardTitle}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.cardContent}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const MessageText = () => {
    const classes = useStyles();
    return (
      selectedConversationType === 'SMS' ? 
        <Fade in={selectedConversationType === 'SMS'}>
          <div style={{ width: '100%' }}>
          <Typography variant='subtitle1'>Message Text</Typography>
          <div className='flex flex-row pl-16'>
            <TextInput
              multiline
              label='Message'
              variant='outlined'
              validate={[required()]}
              type='text'
              style={{ width: '20vw', marginRight: '2rem' }}
              // parse={stringParser}
              // onChange={ () => }
              source='data.parts[0].msg'
            />
            <TextInput
              label='Credit per user'
              variant='outlined'
              disabled
              source='data.parts[0].creditPerUser'
              // value={creditPerUser && creditPerUser.toFixed(2)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>₹</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        </Fade>
       :         
        <Fade in={selectedConversationType === 'whatsapp'}>
          <div style={{ width: '75vw' }}>
            <Typography variant='subtitle1'>Chatbot</Typography>
            <div className='flex flex-row pl-16'>              
              <AutocompleteInput
                className={classes.selectInput}
                variant='outlined'
                choices={forms}
                translateChoice={false}
                label='Existing ODK Forms'                
                source={`data.parts[0].meta.formID`}
                onChange={(e) => {                                                      
                // props.input.onChange(t);
                console.log(e);
              }}
            />
            <TextInput
              variant='outlined'
              source='data.startingMessage'
              label='Starting Message'
              validate={[required()]}
              style={{width: '30%', marginRight: '1rem'}}
              translateChoice={false}
            />         
            
          </div>
        </div>
      </Fade>      
    );
  };

  const ConversationTitle = ({ record }) => {
    return (
      <>
        <Typography
          variant='h5'
          color='textPrimary'
          style={{ display: 'inline' }}
        >
          Edit{' '}
        </Typography>
        <Typography
          variant='h5'
          color='textSecondary'
          style={{ display: 'inline' }}
        >
          {record ? record.name : ''}
        </Typography>
      </>
    );
  };

  const EditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton redirect={false} submitOnEnter={false} label='Save' />
        <SaveButton
          submitOnEnter={false}
          label='Trigger Conversation'
          variant='contained'
          color='secondary'
          startIcon={<Icon>send</Icon>}
          // onSave={triggerConversation}
        />
      </Toolbar>
    );
  };

  const maxLength = (
    max,
    message = 'Conversation name may not exceed 20 characters.'
  ) => (value) => (value && value.length > max ? message : undefined);

  const validateName = [required(), maxLength(20)];

  async function asyncForEach(array, callback) {
    if(!array) return;
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  useEffect(() => {  
  async function calculateMemberCount () {
      let count = 0;
      await asyncForEach( selectedGroup, async (groupId) => {
          const retrievedGroup = await client.retrieveGroup(groupId);                   
          if(retrievedGroup && retrievedGroup.successResponse) {
            const { successResponse: { group } } = retrievedGroup;         
            count = count + (group && group.data && group.data.memberCount);
          }
        });  
        setSelectedUsersCount(count);
    };        
  calculateMemberCount();  
  }, [selectedGroup])

  return (
    <div className='pl-16 pt-16'>
      <Edit
        {...props}
        title={<ConversationTitle />}
        actions={<ConversationEditActions {...props} />}
      >
        <SimpleForm toolbar={<CampaignEditToolbar />}>
          <Typography variant='subtitle1'>Conversation Details</Typography>
          <div
            className='flex flex-row items-center pl-16 pb-16'
            style={{ width: '70vw' }}
          >
            <TextInput
              label='Name of conversation'
              style={{ width: '45%', marginRight: '1rem' }}
              source='name'
              variant='outlined'
              validate={validateName}
            />
            <TextInput
              label='Conversation Description'
              source='data.description'
              variant='outlined'
              style={{ width: '45%' }}
            />
          </div>        
          <>
            <Typography variant='subtitle1'>Conversation Type</Typography>
            <div className='flex flex-row pl-16 pt-16 pb-32'>
              <MediaCard
                imageSrc='assets/sms-example.jpg'
                type='SMS'
                handleChange={handleChange}
                cardTitle='SMS'
                cardContent='Send broadcast text messages to thousands of users'
              />
              <MediaCard imageSrc="assets/bot-example.jpg" type='whatsapp' handleChange={handleChange} cardTitle='WhatsApp' cardContent='Create WhatsApp chat bot based on an already existing or new ODK form.' />
            </div>

            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].provider'
              initialValue='cdac'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].selectedUsersCount'
            />
            <TextInput style={{ display: 'none' }} source='data.appName' />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].senderID'
              initialValue='HPGOVT'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].type'
              initialValue='broadcast'
            />
            <TextInput
              style={{ display: 'none' }}
              source='data.parts[0].channel'
              initialValue='SMS'
            />

            <MessageText />
            {selectedConversationType === 'SMS' ? 
              <>
                <div className='py-16'>
                <Typography variant='subtitle1'>Audience</Typography>
                <div
                  className='flex flex-row items-center justify-between pl-16'
                  style={{ width: '30vw' }}
                >
                  <div className='border-r-2 border-gray pr-20 mb-20'>
                    <Typography variant='subtitle1' style={{ color: '#757575' }}>
                      Search from existing segments:
                    </Typography>
                    <ReferenceArrayInput
                      label='Segments'
                      variant='outlined'
                      source='data.group'
                      filter={{applicationId: config.fusionAuthConsole.fusionAuthApplicationId}}                      
                      reference='segments'
                      onChange={(groupId) => {                    
                        setSelectedGroup(groupId);                                        
                      }}
                    >
                    <AutocompleteArrayInput optionValue='id' optionText='name' />
                    </ReferenceArrayInput>
                  </div>
                  <div>
                    <Button color='primary'>
                      <Link to='/segments/create'>Or create new</Link>
                    </Button>
                  </div>
                </div>
                {selectedUsersCount ? (
                  <Fade in={selectedUsersCount != null}>
                    <div style={{ paddingLeft: '1.6rem' }}>
                      <Typography
                        variant='subtitle1'
                        color='textPrimary'
                        style={{ display: 'inline' }}
                      >
                        {selectedUsersCount.toLocaleString('en-IN')}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ display: 'inline' }}
                      >
                        {' '}
                        {selectedUsersCount > 1 ? 'users' : 'user'} selected.
                      </Typography>
                    </div>
                  </Fade>
                ) : (
                  ''
                )}
              </div>
              <Typography variant='subtitle1'>Total Cost</Typography>
              <div className='flex flex-row pl-16'>
              <TextInput
                label='Total Cost'
                variant='outlined'
                disabled
                source='data.parts[0].totalCost'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>₹</InputAdornment>
                  ),
                }}
              />
            </div>
            </> :       <>
               <div className='py-16'>
                <Typography variant='subtitle1'>Audience</Typography>
                <div
                  className='flex flex-row-reverse items-center justify-between pl-16'
                  style={{ width: '30vw' }}
                >
                  <div className='border-l-2 border-gray pl-20 mb-20'>
                    <Typography variant='subtitle1' style={{ color: '#757575' }}>
                      Or limit to existing segments:
                    </Typography>
                    <ReferenceArrayInput
                      label='Segments'
                      variant='outlined'
                      source='data.group'
                      filter={{applicationId: config.fusionAuthConsole.fusionAuthApplicationId}}                      
                      reference='segments'
                      onChange={(groupId) => {                    
                        setSelectedGroup(groupId);                                        
                      }}
                    >
                    <AutocompleteArrayInput optionValue='id' optionText='name' />
                    </ReferenceArrayInput>
                  </div>
                  <div>                    
                    <BooleanInput
                      source="data.addNewUserOnOptIn"
                      label='Make Bot Public'
                      defaultValue={true}
                    />
                  </div>
                </div>
                {selectedUsersCount ? (
                  <Fade in={selectedUsersCount != null}>
                    <div style={{ paddingLeft: '1.6rem' }}>
                      <Typography
                        variant='subtitle1'
                        color='textPrimary'
                        style={{ display: 'inline' }}
                      >
                        {selectedUsersCount.toLocaleString('en-IN')}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ display: 'inline' }}
                      >
                        {' '}
                        {selectedUsersCount > 1 ? 'users' : 'user'} selected.
                      </Typography>
                    </div>
                  </Fade>
                ) : (
                  ''
                )}
              </div>
            </>}             
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (
                  formData &&
                  formData.data &&
                  formData.data.parts &&
                  formData.data.parts[0]
                ) {
                  const creditPerUser =
                    formData.data.parts[0].msg &&
                    countChars.count(formData.data.parts[0].msg).messages *
                      0.05;

                  formData.data.parts[0].creditPerUser = creditPerUser;
                  formData.data.appName =
                    formData.name && formData.name.substr(0, 20);
                  if (!selectedGroup) { 
                    setSelectedGroup(formData.data.group);                    
                  }
                  if(!selectedUsersCount) {
                    setSelectedUsersCount(formData.data.parts[0].selectedUsersCount);
                  }
                  // Don't update on no group selected
                  if (
                    selectedUsersCount != null &&
                    selectedUsersCount !==
                      formData.data.parts[0].selectedUsersCount
                  )
                    formData.data.parts[0].selectedUsersCount = selectedUsersCount;                  
                  if(!selectedConversationType && formData.data.parts[0].channel) setSelectedConversationType(formData.data.parts[0].channel);
                  if(formData.data.parts[0].channel !== selectedConversationType) formData.data.parts[0].channel = selectedConversationType;                  
                  const totalCost =
                    ((selectedUsersCount || 0) * (creditPerUser || 0)).toFixed(2);
                  formData.data.parts[0].totalCost = totalCost;
                  setTotalCost(totalCost);
                }
                return (
                  <ConfirmDialog {...props} conversationName={formData.name} startingMessage={formData.data?.startingMessage} />
                );
              }}
            </FormDataConsumer>
          </>
          
        </SimpleForm>
      </Edit>
    </div>
  );
};
