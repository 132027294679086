import React from 'react';
import { Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export const NormalBox = (props) => {
  const classes = useStyles();
  const { title, count, percent, link } = props;

  return (
    <Grid item lg={3} md={4} sm={6} className={classes.boxMargin}>
      <Paper className='w-full rounded-8 shadow-none border-1'>
        <div className='text-center pt-12 pb-28'>
          <Typography
            variant='h4'
            component='h4'
            className='leading-none text-blue'
          >
            {count}&nbsp;{percent ? <strong>({percent}%)</strong> : ''}
          </Typography>
          <Typography className='text-18' color='textSecondary'>
            {title}
          </Typography>
          {link ? <Button label='CLICK TO SEE DETAILS' /> : ' '}
        </div>
      </Paper>
    </Grid>
  );
};

export const SmallBox = (props) => {
  const classes = useStyles();
  const { title, count, percent, link } = props;

  return (
    <Grid item lg={2} md={4} sm={6} className={classes.boxMargin}>
      <Paper className='w-full rounded-8 shadow-none border-1'>
        <div className='text-center pt-12 pb-28'>
          <Typography
            variant='h4'
            component='h4'
            className='leading-none text-blue'
          >
            {count}
            <br />
            {percent ? <strong>({percent}%)</strong> : ''}
          </Typography>
          <Typography className='text-18' color='textSecondary'>
            {title}
          </Typography>
          {link ? <Button label='CLICK TO SEE DETAILS' /> : ' '}
        </div>
      </Paper>
    </Grid>
  );
};
