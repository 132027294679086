import React from 'react';
import Iframe from 'react-iframe';
import { Link } from 'react-admin'
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

const CovidDashboardPage = () => (
  <>
  <Button style={{width: '5vw', marginBottom: '1rem', alignSelf: 'flex-end'}} 
    color='primary' 
    startIcon={<PrintIcon />}
    onClick={ () => { window.open('https://html-printer.samagra.io/export/pdf/?url=https://visualization.audit.samiksha.samagra.io/public/dashboard/e6c05195-36c5-4da7-b9af-2c2a7fd4dc1c')}}>    
        
  </Button>
  <Iframe
    url='https://visualization.audit.samiksha.samagra.io/public/dashboard/e6c05195-36c5-4da7-b9af-2c2a7fd4dc1c'
    width='100%'
    height='100%'
    display='initial'
    position='relative'
  />
  </>
);

export default CovidDashboardPage;
