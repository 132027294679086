import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  FunctionField,
  SingleFieldList,
  // SearchInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
  useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { districtOptions, blockOptions } from '../../../meta/filterOption';

// choice mapping
const toChoices = (items) =>
  items && items.map((item) => ({ id: item, name: item }));
// school renderer
const schoolCodeRenderer = (choice) => {
  return choice != null ? `${choice.id} - ${choice.school_name}` : '';
};

// block filter input
const BlockInput = (props) => {
  const { values } = useFormState();
  const { displayedFilters } = useListContext();
  return displayedFilters['data.district'] ? (
    <SelectInput
      choices={
        values.data && values.data.district
          ? toChoices(blockOptions[values.data.district])
          : []
      }
      {...props}
    />
  ) : (
    ''
  );
};

// school filter input
const SchoolInput = (props) => {
  const { values } = useFormState();
  const { displayedFilters } = useListContext();
  return displayedFilters['data.district'] && displayedFilters['data.block'] ? (
    <ReferenceInput
      format={(v) => (!v ? null : v)}
      sort={{ field: 'school_code', order: 'ASC' }}
      filter={{
        district: values.data && values.data.district,
        block: values.data && values.data.block,
      }}
      filterToQuery={(searchText) => ({ school_code: searchText })}
      {...props}
    >
      <AutocompleteInput optionText={schoolCodeRenderer} />
    </ReferenceInput>
  ) : (
    ''
  );
};

// search filter
const SearchFilter = (props) => {
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;

  const { displayedFilters, filterValues, setFilters } = useListContext();

  if (
    displayedFilters['data.block'] &&
    displayedFilters['data.district'] === undefined
  ) {
    displayedFilters['data.block'] = false;
    displayedFilters['data.scode'] = false;
    setFilters({});
  }

  if (
    displayedFilters['data.scode'] &&
    (displayedFilters['data.block'] === false ||
      displayedFilters['data.block'] === undefined)
  ) {
    displayedFilters['data.scode'] = false;
    if (filterValues.length > 0) {
      setFilters({
        data: { district: filterValues && filterValues.data.district },
      });
    } else {
      setFilters({});
    }
  }

  const districtChange = (e) => {
    if (filterValues.data.district !== e.target.value) {
      setFilters({
        data: {
          district: e.target.value,
        },
      });
    }
  };

  const blockChange = (e) => {
    if (filterValues.data.block !== e.target.value) {
      setFilters({
        data: {
          district: filterValues.data.district,
          block: e.target.value,
        },
      });
    }
  };

  const userDistrict = [{ id: district, name: district }];
  return (
    <Filter {...props}>
      <SelectInput
        label='By District'
        source='data.district'
        choices={
          district === 'ALL' || district === ''
            ? toChoices(districtOptions)
            : userDistrict
        }
        className='autocompleteInput'
        allowEmpty={false}
        onChange={(e) => districtChange(e)}
      />
      <BlockInput
        label='By Block'
        source='data.block'
        className='autocompleteInput'
        allowEmpty={false}
        onChange={(e) => blockChange(e)}
      />
      <SchoolInput
        label='By School Code'
        source='data.scode'
        reference='school'
        className='autocompleteInput'
        allowEmpty={false}
        allowNull
      />
      {/* <ReferenceInput
        label='By School Name'
        source='data.scode'
        reference='school'
        format={(v) => (!v ? null : v)}
        sort={{ field: 'school_code', order: 'ASC' }}
        filterToQuery={(searchText) => ({ school_code: searchText })}
        className='autocompleteInput'
        allowEmpty
        allowNull
      >
        <AutocompleteInput optionText={schoolCodeRenderer} />
      </ReferenceInput> */}
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 0px)',
    height: '86vh',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'scroll',
    marginLeft: '1rem',
  },
}));

const ratingSwitch = (type) =>
  ({
    1: 'Very Poor',
    2: 'Poor',
    3: 'Average',
    4: 'Good',
    5: 'Very Good',
    6: 'Never received call/help',
  }[type]);

const booleanSwitch = (type) =>
  ({
    1: 'Yes',
    2: 'No',
  }[type]);

export const MentoringFeedbackList = (props) => {
  const classes = useStyles();
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;
  return (
    <div className={classes.root}>
      <List
        {...props}
        filters={<SearchFilter />}
        filterDefaultValues={
          district === 'ALL' || district === ''
            ? {}
            : {
                data: {
                  district,
                },
              }
        }
        pagination={<Pagination perPage={1} style={{ float: 'left' }} />}
        title='eMentoring Feedback List'
        className='customBox'
      >
        <Datagrid>
          <ArrayField label='District' source='data'>
            <SingleFieldList>
              <TextField source='district' />
            </SingleFieldList>
          </ArrayField>
          <ArrayField label='Block' source='data'>
            <SingleFieldList>
              <TextField source='block' />
            </SingleFieldList>
          </ArrayField>
          <TextField label='School Code' source='data[0].scode' />
          <FunctionField
            label='Did Mentor Call?'
            render={(record) => booleanSwitch(record.data[0].call_yn)}
          />
          <FunctionField
            label='E-Learing Content Discussed?'
            render={(record) => booleanSwitch(record.data[0].ycont)}
          />
          <FunctionField
            label='Student Participation Discussed?'
            render={(record) => booleanSwitch(record.data[0].engg)}
          />
          <FunctionField
            label='Solution Provided?'
            render={(record) => booleanSwitch(record.data[0].soln)}
          />
          <FunctionField
            label='Mentor Rating'
            render={(record) => ratingSwitch(record.data[0].yrate)}
          />
          <FunctionField
            label='Is Support Provided?'
            render={(record) => booleanSwitch(record.data[0].ncont)}
          />
          <FunctionField
            label='Best Practise Shared?'
            render={(record) => booleanSwitch(record.data[0].bprac)}
          />
          <FunctionField
            label='Help Deptt. Programs?'
            render={(record) => booleanSwitch(record.data[0].dept)}
          />
          <FunctionField
            label='Mentor Support Rating'
            render={(record) => ratingSwitch(record.data[0].nrate)}
          />
        </Datagrid>
      </List>
    </div>
  );
};
