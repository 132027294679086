import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { clientGQL } from '../../dataProviders/clientGQL';
import AddMentorComponent from './AddMentorComponent';
import styles from './index.module.scss';
import { useDataProvider } from 'react-admin';
import UserDetails from './UserDetails';

const ManageTeacherMapping = (props) => {
    const { showModal, schoolDetails } = props;
    console.log(schoolDetails)
    const [mappedMentors, setMappedMentors] = useState([]);
    const [filteredMentors, setFilteredMentors] = useState([]);
    const [addMentors, setAddMentors] = useState(false);
    const [removeMentors, setRemoveMentors] = useState(false);
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [searchText, setSearchText] = useState('');
    const isMobile = window.innerWidth < 498;
    const dataProvider = useDataProvider();

    // Getting all the mapped users to the selected school
    const getMappedUsers = async (user) => {
        let res = await clientGQL(
            `
            query MyQuery {
                school_mentor_mapping(where: {school_code: {_eq: "${schoolDetails.id}"}}) {
                  id
                  mentor_block
                  mentor_mobile
                  mentor_username
                }
              }
              
            `
        )
        if (res?.data?.school_mentor_mapping) setMappedMentors(res?.data?.school_mentor_mapping);
    }

    const handleUserClick = async (mapping) => {
        if (removeMentors) {
            if (searchText) setFilteredMentors(filteredMentors.filter(el => el.mentor_username != mapping.mentor_username))
            setMappedMentors(mappedMentors.filter(el => el.mentor_username != mapping.mentor_username))
            clientGQL(
                `
                mutation MyMutation {
                    delete_school_mentor_mapping(where: {id: {_eq: ${mapping.id}}}) {
                      affected_rows
                    }
                  }
                `
            )
        } else {
            // let res = await dataProvider.getOne('userSamiksha-mentor', { id: mapping.mentor_id });
            let res = await dataProvider.getList('userSamiksha-mentor', {
                pagination: {
                    page: 1,
                    perPage: 1
                },
                sort: {
                    field: 'username',
                    order: 'ASC'
                },
                filter: {
                    mentorUsername: [mapping.mentor_username]
                }
            });
            if (res?.data?.[0]) { setUserDetails(res?.data?.[0]); setShowUserDetails(true) }
        }
    }

    useEffect(() => {
        getMappedUsers();
    }, [])

    useEffect(() => {
        if (searchText) {
            setFilteredMentors(mappedMentors.filter(el => el.mentor_username.startsWith(searchText)))
        } else setFilteredMentors([]);
    }, [searchText])


    return (ReactDOM.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.modalContent + " animate__animated animate__backInUp"} style={showUserDetails ? { width: '30%' } : {}}>
                {!addMentors && !showUserDetails && <>
                    <div className={styles.mainContent}>
                        <p>{schoolDetails?.school_name}</p>
                        <div className={styles.divider}></div>
                        <p className={styles.closeIcon} onClick={() => showModal(false)}>x</p>
                        <input type="text" className={styles.searchbar} placeholder="Filter users here..." value={searchText} onChange={e => setSearchText(e.target.value)} />
                        {removeMentors ? <p className='animate__animated animate__fadeInUp' style={{ fontWeight: 600 }}>{isMobile ? 'Tap' : 'Click'} on any username to remove their mapping</p>
                            : <p className='animate__animated animate__fadeInUp' style={{ fontWeight: 600 }}>{isMobile ? 'Tap' : 'Click'} on any username to view their details</p>
                        }

                        <div className={styles.mentorContainer}>

                            {!filteredMentors?.length && !searchText && mappedMentors?.map(el =>
                                <div className={styles.mentorCell + ` ${removeMentors && 'animate__animated animate__pulse animate__slow animate__infinite'}`}
                                    onClick={() => handleUserClick(el)}
                                >
                                    {removeMentors && <div className={styles.crossIcon}>x</div>}
                                    <p>{el.mentor_username}</p>
                                </div>)}

                            {filteredMentors?.length ? filteredMentors?.map(el =>
                                <div className={styles.mentorCell + ` ${removeMentors && 'animate__animated animate__pulse animate__slow animate__infinite'}`}
                                    onClick={() => handleUserClick(el)}
                                >
                                    {removeMentors && <div className={styles.crossIcon}>x</div>}
                                    <p>{el.mentor_username}</p>
                                </div>) : null}

                            {!mappedMentors?.length && <p style={{ margin: 'auto' }}>No mentors mapped to this school</p>}

                        </div>
                    </div>
                    <div className={styles.btnContainer}>
                        {removeMentors ? <div className={`${styles.btn} ${styles.btnRemove}`} onClick={() => setRemoveMentors(false)}>Done</div> :
                            <>
                                <div className={`${styles.btn} ${styles.btnAdd}`} onClick={() => setAddMentors(true)}>Add Mentors</div>
                                <div className={`${styles.btn} ${styles.btnRemove}`} onClick={() => setRemoveMentors(true)}>Remove Mentors</div>
                            </>}
                    </div>
                </>}
                {addMentors && <AddMentorComponent {...{ setAddMentors, mappedMentors, setMappedMentors, schoolDetails }} />}
                {showUserDetails && <UserDetails {...{ userDetails, setUserDetails, setShowUserDetails }} />}
            </div>
        </div >, document.body)
    );
}

export default ManageTeacherMapping;