/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import React, { useState, cloneElement } from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';
import {
  List,
  Toolbar,
  TopToolbar,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Filter,
  useQuery,
  useRedirect,
  useNotify,
  useListContext,
  sanitizeListRestProps,
  // Loading,
} from 'react-admin';
import { setListSelectedIds } from 'ra-core';
import SaveIcon from '@material-ui/icons/Save';
import {
  withStyles,
  // makeStyles,
  Card as MuiCard,
  CardContent,
  Typography,
  Button,
  Chip,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';

// import { GradeNumberFilter } from './studentFilters';
// import filterData from '../../meta/filterData';

const districtChoices = require('../../../meta/district.json');
const blockChoices = require('../../../meta/block.json');
const { FusionAuthClient } = require('../../../@fusionauth/node-client');

const config = require('../../../config');

const client = new FusionAuthClient(
  config.fusionAuthCampaignManager.fusionAuthAPIKey,
  config.fusionAuthCampaignManager.fusionAuthURL
);
const TeacherList = (props) => {
  return <ConversationUsersList {...props} />;
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const ConversationUsersList = (props) => {
  // const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <div>
      <Teachers value={value} index={0} {...props}>
        Item One
      </Teachers>
    </div>
  );
};

const schoolRenderer = (choice) => {
  return choice ? `${choice.school_name} (Code: ${choice.id})` : '';
};

// Teacher custom filters
function Teachers(props) {
  const notify = useNotify();
  const redirect = useRedirect();

  const Card = withStyles((theme) => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        order: -1, // display on the left rather than on the right of the list
        // width: '15em',
        marginBottom: '1em',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }))(MuiCard);

  const FilterSidebar = () => {
    const { setFilters } = useListContext();
    const resetFilters = () => {
      setFilters({});
    };
    const ResetFilterButton = () => {
      return (
        <Chip
          label='Reset Filters'
          variant='outlined'
          className='w-full'
          onClick={() => {
            resetFilters();
          }}
        />
      );
    };
    return (
      <Card>
        <CardContent style={{ paddingBottom: '1em' }}>
          <ResetFilterButton />
          <div
            className='grid grid-cols-3'
            style={{ gridTemplateRows: '1fr max-content' }}
          >
            {/* <GradeNumberFilter /> */}
          </div>
        </CardContent>
      </Card>
    );
  };

  const { children, value, index, newApplicationId, ...other } = props;
  const fakeProps = {
    basePath: '/teachers',
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: '/', search: '', hash: '', state: undefined },
    match: { path: '/', url: '/', isExact: true, params: {} },
    options: {},
    filter: {},
    permissions: null,
    resource: 'employees_info',
  };

  const updateGroupWithFilter = async (groupId, filterValues, total) => {
    try {
      const updatedData = {
        filterValues,
        memberCount: total,
        updatedAt: new Date().toLocaleString('en-IN'),
      };
      const retrievedGroup = await client.retrieveGroup(groupId);
      if (retrievedGroup && retrievedGroup.successResponse) {
        const {
          successResponse: { group },
        } = retrievedGroup;
        const groupUpdateResponse = await client.updateGroup(groupId, {
          group: { data: { ...group.data, ...updatedData }, name: group.name },
        });
        if (groupUpdateResponse) {
          notify(`Segment updated successfully!`, 'info');
          redirect(
            `/conversations/create?source=${JSON.stringify({
              data: {
                group: [groupId],
                parts: [
                  {
                    senderID: 'HPGOVT',
                    type: 'broadcast',
                    provider: 'cdac',
                  },
                ],
                applicationId: config.fusionAuth.fusionAuthApplicationId,
              },
            })}`
          );
        }
      }
    } catch (e) {
      notify(`Error importing users to group.`, 'warning');
    }
  };

  const SearchFilter = (props) => (
    <Filter {...props}>
      <AutocompleteArrayInput
        style={{ marginTop: '1.2rem' }}
        label='By District'
        allowEmpty={false}
        source='district'
        choices={districtChoices}
        alwaysOn
      />
      <AutocompleteArrayInput
        style={{ marginTop: '1.2rem', width: '200px' }}
        label='By Block'
        allowEmpty={false}
        source='block'
        choices={blockChoices}
        alwaysOn
      />
      <ReferenceArrayInput
        label='By School Name'
        source='school_code'
        reference='school'
        sort={{ field: 'school_name', order: 'ASC' }}
        className=''
        // filter={{
        //   ...school,
        //   location: {
        //     ...(district && { district }),
        //     ...(block && { block }),
        //   },
        // }}
        filterToQuery={(searchText) => ({ school_name: searchText })}
        alwaysOn
      >
        <AutocompleteArrayInput
          style={{ marginTop: '1.2rem', width: '200px' }}
          className=''
          optionText={schoolRenderer}
        />
      </ReferenceArrayInput>
    </Filter>
  );

  const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      groupId,
      ...rest
    } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
      </TopToolbar>
    );
  };

  const UserAttributes = (props) => {
    const { filterValues, total } = useListContext();
    const { data, loading } = useQuery({
      type: 'getList',
      resource: 'employees_info',
      payload: {
        pagination: {},
        sort: { field: 'employee_name', order: 'ASC' },
        filter: filterValues,
      },
    });
    if (loading)
      return (
        <div className='flex flex-1 flex-col items-center justify-center'>
          <CircularProgress />
        </div>
      );

    if (!filterValues) return null;

    const flattenFilterValues = (filterValues, rows = []) => {
      for (const filter in filterValues) {
        if (
          typeof filterValues[filter] === 'string' ||
          typeof filterValues[filter] === 'boolean' ||
          Array.isArray(filterValues[filter])
        ) {
          rows.push({ attributeName: filter, value: filterValues[filter] });
        } else flattenFilterValues(filterValues[filter], rows);
      }
      return rows;
    };

    const rows = flattenFilterValues(filterValues);
    const filteredRows = rows.filter((row) => {
      return row.attributeName !== 'isActive' ? row : false;
    });
    const sanitise = (attr) => {
      attr = attr.replace('_', ' ');
      return attr;
    };
    const filterValueChipify = (value) => {
      if (typeof value === 'string') return <Chip label={value} />;
      if (typeof value === 'boolean') {
        if (value) return <Chip label='True' />;
        return <Chip label='False' />;
      }
      return value.map((val) => <Chip label={val} />);
    };

    return (
      <TableContainer>
        <Table aria-label='caption table' size='small'>
          <caption style={{ paddingBottom: '0' }}>
            Teacher selected:&nbsp;
            <Typography variant='h6' style={{ display: 'inline' }}>
              {/* {total && total.toLocaleString('en-IN')} */}
              {data && data.length.toLocaleString('en-IN')}
            </Typography>
          </caption>
          <TableBody>
            {filteredRows.map((row) => (
              <TableRow key={row.attributeName}>
                <>
                  <TableCell
                    component='th'
                    align='center'
                    scope='row'
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {sanitise(row.attributeName)}
                  </TableCell>
                  <TableCell align='center'>
                    {filterValueChipify(row.value)}
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const CustomButton = (props) => {
    const { groupId, label } = props;
    const { filterValues, total } = useListContext();
    const { data } = useQuery({
      type: 'getList',
      resource: 'employees_info',
      payload: {
        pagination: {},
        sort: { field: 'employee_name', order: 'ASC' },
        filter: filterValues,
      },
    });
    // const segment = {};

    const handleConfirm = () => {
      if (groupId) {
        // createOrUpdateStudentsInGroup(students, groupId,);
        updateGroupWithFilter(groupId, filterValues, data.length);
      }
    };

    return (
      <Button
        color='primary'
        variant='contained'
        aria-label='add'
        label={label}
        onClick={handleConfirm}
        startIcon={<SaveIcon />}
      >
        Save{' '}
      </Button>
    );
  };

  return (
    <>
      <List
        {...fakeProps}
        title=''
        perPage={25}
        filters={<SearchFilter />}
        actions={<ListActions {...props} />}
        aside={<FilterSidebar {...props} />}
        // filterDefaultValues={{ isActive: true }}
        bulkActionButtons={false}
        pagination={null}
      >
        <>
          <UserAttributes {...props} />
          {/* fetch data component */}
          <Toolbar
            style={{ marginTop: '1rem' }}
            className='MuiPaper-elevation1'
          >
            <CustomButton label='Save Segment' {...props} />
          </Toolbar>
        </>
      </List>
    </>
  );
}
const mapStateToProps = (state) => ({
  users: state.admin.resources.users,
});
export default connect(mapStateToProps, { setListSelectedIds })(TeacherList);
