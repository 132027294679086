import React from 'react';
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { useListContext } from 'react-admin';
import { shallowEqual } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: '2em',
  },
  listItemText: {
    margin: 0,
  },
}));

/**
 * Button to enable/disable a list filter.
 *
 * Expects 2 props:
 *
 * - label: The text to be displayed for this item. Will be translated.
 * - value: An object to be merged into the filter value when enabling the filter
 * (e.g. { is_published: true, published_at_gte: '2020-07-08' })
 *
 * @example
 *
 * import * as React from 'react';
 * import { Card, CardContent } from '@material-ui/core';
 * import MailIcon from '@material-ui/icons/MailOutline';
 * import { FilterList, FilterListItem } from 'react-admin';
 *
 * const FilterSidebar = () => (
 *     <Card>
 *         <CardContent>
 *             <FilterList
 *                 label="Subscribed to newsletter"
 *                 icon={<MailIcon />}
 *             >
 *                 <FilterListItem
 *                     label="Yes"
 *                     value={{ has_newsletter: true }}
 *                  />
 *                 <FilterListItem
 *                     label="No"
 *                     value={{ has_newsletter: false }}
 *                  />
 *             </FilterList>
 *         </CardContent>
 *     </Card>
 * );
 *
 * @example // The value prop can contain multiple keys
 *
 * import * as React from 'react';
 * import {
 *     endOfYesterday,
 *     startOfWeek,
 *     subWeeks,
 *     startOfMonth,
 *     subMonths,
 * } from 'date-fns';
 * import { Card, CardContent } from '@material-ui/core';
 * import AccessTimeIcon from '@material-ui/icons/AccessTime';
 * import { FilterList, FilterListItem } from 'react-admin';
 *
 * const FilterSidebar = () => (
 *     <Card>
 *         <CardContent>
 *             <FilterList
 *                 label="Last visited"
 *                 icon={<AccessTimeIcon />}
 *             >
 *                 <FilterListItem
 *                     label="Today"
 *                     value={{
 *                         last_seen_gte: endOfYesterday().toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="This week"
 *                     value={{
 *                         last_seen_gte: startOfWeek(
 *                             new Date()
 *                         ).toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Last week"
 *                     value={{
 *                         last_seen_gte: subWeeks(
 *                             startOfWeek(new Date()),
 *                             1
 *                         ).toISOString(),
 *                         last_seen_lte: startOfWeek(
 *                             new Date()
 *                         ).toISOString(),
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="This month"
 *                     value={{
 *                         last_seen_gte: startOfMonth(
 *                             new Date()
 *                         ).toISOString(),
 *                         last_seen_lte: undefined,
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Last month"
 *                     value={{
 *                         last_seen_gte: subMonths(
 *                             startOfMonth(new Date()),
 *                             1
 *                         ).toISOString(),
 *                         last_seen_lte: startOfMonth(
 *                             new Date()
 *                         ).toISOString(),
 *                     }}
 *                 />
 *                 <FilterListItem
 *                     label="Earlier"
 *                     value={{
 *                         last_seen_gte: undefined,
 *                         last_seen_lte: subMonths(
 *                             startOfMonth(new Date()),
 *                             1
 *                         ).toISOString(),
 *                     }}
 *                 />
 *             </FilterList>
 *         </CardContent>
 *     </Card>
 * );
 */
const FilterListItem = (props) => {
  const { label, value, multipleSelect, target, innerTarget } = props;
  const { filterValues, setFilters } = useListContext();
  const classes = useStyles(props);

  const isSelected = Object.keys(value).reduce((acc, key) => {
    if (typeof value[key] !== 'object') {
      if (Array.isArray(filterValues[key]))
        return acc && filterValues[key].indexOf(value[key]) > -1;
      else return acc && value[key] == filterValues[key];
    } else {
      if (filterValues[key]) {
        if (Array.isArray(filterValues[key][innerTarget]))
          return (
            filterValues[key][innerTarget].indexOf(value[key][innerTarget]) > -1
          );
        else
          return (
            value[key][innerTarget] ===
            (filterValues[key] && filterValues[key][innerTarget])
          );
      }
    }
  }, true);

  const addFilter = () => {
    if (!multipleSelect) setFilters({ ...filterValues, ...value }, null);
    else {
      const multipleValue = {};
      Object.keys(value).forEach((key) => {
        if (target && key !== target) return;
        if (typeof value[key] !== 'object') {
          if (
            (Array.isArray(filterValues[key]) &&
              filterValues[key].indexOf(value[key]) !== -1) ||
            filterValues[key] !== value[key]
          ) {
            multipleValue[key] = Array.isArray(filterValues[key])
              ? [...filterValues[key], value[key]]
              : filterValues[key]
              ? [filterValues[key], value[key]]
              : value[key];
          } else multipleValue[key] = filterValues[key];
        } else {
          multipleValue[key] = filterValues[key] ? filterValues[key] : {};
          if (filterValues[key]) {
            if (
              (Array.isArray(filterValues[key][innerTarget]) &&
                filterValues[key][innerTarget].indexOf(
                  value[key][innerTarget]
                ) !== -1) ||
              filterValues[key][innerTarget] !== value[key][innerTarget]
            ) {
              multipleValue[key][innerTarget] = Array.isArray(
                filterValues[key][innerTarget]
              )
                ? [
                    ...filterValues[key][innerTarget].filter(
                      (e) => e !== undefined
                    ),
                    value[key][innerTarget],
                  ]
                : filterValues[key][innerTarget]
                ? [filterValues[key][innerTarget], value[key][innerTarget]]
                : value[key][innerTarget];
            } else
              multipleValue[key][innerTarget] = filterValues[key][innerTarget];
          } else multipleValue[key][innerTarget] = value[key][innerTarget];
        }
      });
      setFilters({ ...filterValues, ...multipleValue }, null);
    }
  };
  const removeFilter = () => {
    const multipleInverseValue = {};
    Object.keys(value).forEach((key) => {
      if (target && key !== target) return;
      if (typeof value[key] !== 'object')
        multipleInverseValue[key] = Array.isArray(filterValues[key])
          ? filterValues[key].filter((e) => e !== value[key])
          : undefined;
      else {
        multipleInverseValue[key] = filterValues[key] ? filterValues[key] : {};
        if (filterValues[key]) {
          multipleInverseValue[key][innerTarget] = Array.isArray(
            filterValues[key][innerTarget]
          )
            ? filterValues[key][innerTarget].filter(
                (e) => e !== value[key][innerTarget]
              )
            : undefined;
        } else multipleInverseValue[key][innerTarget] = undefined;
      }
    });
    setFilters({ ...filterValues, ...multipleInverseValue }, null);
  };

  const toggleFilter = () => (isSelected ? removeFilter() : addFilter());

  return (
    <ListItem
      button
      onClick={toggleFilter}
      selected={isSelected}
      className={classes.listItem}
    >
      <ListItemText primary={label} className={classes.listItemText} />
      {isSelected && (
        <ListItemSecondaryAction>
          <IconButton size='small' onClick={toggleFilter}>
            <CancelIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.label === nextProps.label &&
  shallowEqual(prevProps.value, nextProps.value);

export default React.memo(FilterListItem, arePropsEqual);
