/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import _ from 'lodash';
import {
  GET_ONE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE_MANY,
} from './fetchActions';

import getFinalType from './getFinalType';

function getFilter(f, filterObj, key) {
  if (f.name === 'id') return { [f.name]: { _eq: `%${filterObj[key]}%` } };
  return { [f.name]: { _ilike: `%${filterObj[key]}%` } };
}

const checkForNesting = (filter, filterObj, key) => {
  const temp = {};
  temp[key] = {};
  for (const innerKey in filterObj[key]) {
    if (typeof filterObj[key][innerKey] === 'object') {
      temp[key][innerKey] = {};
      if (!Array.isArray(filterObj[key][innerKey])) {
        for (const innerInnerKey in filterObj[key][innerKey]) {
          if (typeof filterObj[key][innerKey][innerInnerKey] === 'object') {
            // todo: implement recursion
            if (Array.isArray(filterObj[key][innerKey][innerInnerKey])) {
              temp[key][innerKey][innerInnerKey] = {
                _in: filterObj[key][innerKey][innerInnerKey],
              };
            }
          } else {
            temp[key][innerKey][innerInnerKey] = {
              _eq: filterObj[key][innerKey][innerInnerKey],
            };
          }
        }
      } else {
        temp[key][innerKey] = { _in: filterObj[key][innerKey] };
      }
    } else {
      temp[key][innerKey] = { _eq: filterObj[key][innerKey] };
    }
  }
  filter = { ...filter, [key]: temp[key] };
  return filter;
};
const buildGetListVariables = () => (resource, aorFetchType, params) => {
  const result = {};
  const { filter: filterObj = {}, customFilters = [] } = params;

  let distinct = [];
  if (params && params.filter && params.filter.distinctOnFields) {
    distinct = params.filter.distinctOnFields || [];
    delete params.filter.distinctOnFields;
  }
  const tempFilterObject = { ...filterObj };
  for (const k in tempFilterObject) {
    if (
      typeof tempFilterObject[k] === 'object' &&
      !Object.keys(tempFilterObject[k]).length
    ) {
      delete filterObj[k];
    }
  }
  const filters = Object.keys(filterObj).reduce((acc, key) => {
    let filter;
    if (key === 'q') {
      const field = resource.type.fields.filter((f) => {
        return getFinalType(f.type).name === 'String';
      });
      const allQuery = [];
      field.map((f) => {
        return allQuery.push(getFilter(f, filterObj, key));
      });
      filter = { _or: [...allQuery] };
    } else if (key === 'ids') {
      filter = { id: { _in: filterObj.ids } };
    } else if (key === 'customEmployeeFilter') {
      const userData = JSON.parse(
        localStorage.getItem('Dashboard-App/local_sql/user')
      );
      const {
        user: {
          data: {
            roleData: { district },
          },
        },
      } = userData;
      if (district === 'ALL' || district === '') {
        filter = {};
      } else {
        filter = {
          school: {
            district: { _eq: district },
          },
        };
      }
    } else if (key === 'customStudentFilter') {
      const userData = JSON.parse(
        localStorage.getItem('Dashboard-App/local_sql/user')
      );
      const {
        user: {
          data: {
            roleData: { district },
          },
        },
      } = userData;
      if (district === 'ALL' || district === '') {
        filter = {};
      } else {
        filter = {
          studentByStudent: {
            school: {
              district: { _eq: district },
            },
          },
        };
      }
    } else if (key === 'temperature') {
      filter = { temperature: { _gt: filterObj[key] } };
    } else if (Array.isArray(filterObj[key])) {
      filter = { [key]: { _in: filterObj[key] } };
    } else {
      const field = resource.type.fields.find((f) => f.name === key);
      if (
        field.type.kind === 'OBJECT' ||
        (field.type.ofType && field.type.ofType.kind === 'OBJECT')
      ) {
        filter = checkForNesting(filter, filterObj, key);
      } else {
        switch (getFinalType(field.type).name) {
          case 'String':
            filter = { [key]: { _ilike: `%${filterObj[key]}%` } };
            break;
          default:
            filter = { [key]: { _eq: filterObj[key] } };
        }
      }
    }

    return [...acc, filter];
  }, customFilters);

  result.where = { _and: filters };

  if (params.pagination) {
    result.limit = parseInt(params.pagination.perPage, 10);
    result.offset = parseInt(
      (params.pagination.page - 1) * params.pagination.perPage,
      10
    );
  }
  if (params.sort) {
    result.order_by = _.set(
      {},
      params.sort.field,
      params.sort.order.toLowerCase()
    );
  }
  if (distinct && distinct.length) {
    result.distinct_on = distinct;
  }
  return result;
};

const buildUpdateVariables = (resource, aorFetchType, params) =>
  Object.keys(params.data).reduce((acc, key) => {
    // If hasura permissions do not allow a field to be updated like (id),
    // we are not allowed to put it inside the variables
    // RA passes the whole previous Object here
    // https://github.com/marmelab/react-admin/issues/2414#issuecomment-428945402

    // TODO: To overcome this permission issue,
    // it would be better to allow only permitted inputFields from *_set_input INPUT_OBJECT
    if (params.previousData && params.data[key] === params.previousData[key]) {
      return acc;
    }

    if (resource.type.fields.some((f) => f.name === key)) {
      return {
        ...acc,
        [key]: params.data[key],
      };
    }

    return acc;
  }, {});

const buildCreateVariables = (resource, aorFetchType, params) => {
  return params.data;
};

const buildGetOneVariables = () => (resource, aorFetchType, params) => {
  // if (resource.type.name === 'employees_info') {
  //   return {
  //     employee_id: { _eq: params.id },
  //   };
  // }
  if (resource.type.name === 'school') {
    return {
      school_code: { _eq: params.id },
    };
  }
  return {
    id: { _eq: params.id },
  };
};

const buildGetManyVariables = () => (resource, aorFetchType, params) => {
  // if (resource.type.name === 'employees_info') {
  //   return {
  //     employee_id: { _eq: params.id },
  //   };
  // }
  if (resource.type.name === 'school') {
    return {
      school_code: { _in: params.ids },
    };
  }
  return {
    id: { _eq: params.id },
  };
};

export default (introspectionResults) => (
  resource,
  aorFetchType,
  params,
  queryType
) => {
  switch (aorFetchType) {
    case GET_LIST:
      return buildGetListVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
    case GET_MANY_REFERENCE: {
      const built = buildGetListVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
      if (params.filter) {
        return {
          ...built,
          where: {
            _and: [
              ...built.where._and,
              { [params.target]: { _eq: params.id } },
            ],
          },
        };
      }
      return {
        ...built,
        where: {
          [params.target]: { _eq: params.id },
        },
      };
    }
    case GET_MANY: {
      const target = buildGetManyVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
      return {
        where: target,
      };
    }
    case DELETE_MANY: {
      const target = buildGetManyVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
      return {
        where: target,
      };
    }
    case GET_ONE: {
      const target = buildGetOneVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
      return {
        where: target,
        limit: 1,
      };
    }
    case DELETE:
      return {
        where: { id: { _eq: params.id } },
      };
    case CREATE:
      return {
        objects: buildCreateVariables(
          resource,
          aorFetchType,
          params,
          queryType
        ),
      };

    case UPDATE: {
      const target = buildGetOneVariables(introspectionResults)(
        resource,
        aorFetchType,
        params,
        queryType
      );
      return {
        _set: buildUpdateVariables(resource, aorFetchType, params, queryType),
        where: target,
      };
    }
    case UPDATE_MANY:
      return {
        _set: buildUpdateVariables(resource, aorFetchType, params, queryType),
        where: { id: { _in: params.ids } },
      };
    default:
  }
  return null;
};
