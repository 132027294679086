import React, { useEffect, useState } from 'react';
import { clientGQL } from '../../dataProviders/clientGQL';
import CommonModal from '../CommonModal';
import styles from './index.module.scss';

const SchoolMentorPopup = (props) => {
    const { showSchoolPopup } = props;
    const [isFetching, setIsFetching] = useState(true);
    const [count, setCount] = useState('?')

    const getUnmappedSchools = async () => {
        let totalSchools = await clientGQL(
            `
            query MyQuery {
                school_aggregate(distinct_on: school_code) {
                  aggregate {
                    count
                  }
                }
              }
            `
        )
        totalSchools = totalSchools?.data?.school_aggregate?.aggregate?.count
        let totalMentors = await clientGQL(
            `
            query MyQuery {
                school_mentor_mapping_aggregate(distinct_on: school_code) {
                  aggregate {
                    count
                  }
                }
              }              
            `
        )
        totalMentors = totalMentors?.data?.school_mentor_mapping_aggregate?.aggregate?.count
        if (totalSchools - totalMentors)
            setCount(totalSchools - totalMentors)
    }

    useEffect(() => {
        getUnmappedSchools()
    }, [])

    return (
        <CommonModal>
            <div className={styles.container}>
                <div className={styles.content}>
                    <p>Please Note</p>
                    <p>A total of <b>{count}</b> schools are not mapped to any mentor. Please map all schools to mentors. To see the detailed status of school{`<>`}mentor mapping, download the report through <b>EXPORT</b> function.</p>
                    <p><strong>Note:</strong> In the exported file, if column "Mentor" is <b>blank</b>, no mentor has been mapped to that particular school </p>
                </div>
                <div className={styles.footer}>
                    <div onClick={() => showSchoolPopup(false)}>Continue</div>
                </div>
            </div>
        </CommonModal>
    )
}

export default SchoolMentorPopup;