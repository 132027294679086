import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  SearchInput,
  AutocompleteInput,
  Filter,
  Pagination,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const disctritChoices = require('../../../meta/district.json');
const blockChoices = require('../../../meta/block.json');
const designationChoices = require('../../../meta/designation.json');

const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder='Search by name*'
      source='data.username'
      className='searchBar'
      alwaysOn
    />
    <AutocompleteInput
      label='By District'
      source='data.district'
      choices={disctritChoices}
    />
    <AutocompleteInput
      label='By Block'
      source='data.block'
      choices={blockChoices}
    />
    <AutocompleteInput
      label='By Designation'
      source='data.designation'
      choices={designationChoices}
    />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 0px)',
    height: '86vh',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'scroll',
    marginLeft: '1rem',
  },
}));

export const SamparkVisitList = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List
        {...props}
        filters={<SearchFilter />}
        pagination={<Pagination perPage={1} style={{ float: 'left' }} />}
        // classes={{ stickToTop: classes.headerCell }}
      >
        <Datagrid>
          <TextField label='Username' source='data[0].username' />
          <TextField label='Designation' source='data[0].designation' />
          <TextField label='Name' source='data[0].name' />
          <ArrayField label='District' source='data'>
            <SingleFieldList>
              <TextField source='district' />
            </SingleFieldList>
          </ArrayField>
          <ArrayField label='Block' source='data'>
            <SingleFieldList>
              <TextField source='block' />
            </SingleFieldList>
          </ArrayField>
          <TextField label='School Name' source='data[0].school' />

          <TextField label='Month' source='data[0].month' />
          <TextField label='Teacher Trained' source='data[0].teacherTrained' />
          <TextField label='Tim Usage' source='data[0].tlmUsage' />
          <TextField
            label='Progress Chart'
            source='data[0].progressChartUpdated'
          />
          <TextField
            label='Sequence Followed'
            source='data[0].sequenceFollowed'
          />
          <TextField
            label='Application Loaded'
            source='data[0].applicationLoaded'
          />
          <TextField
            label='Same Teacher Trained'
            source='data[0].sameTrainedTeacher'
          />
          <TextField label='Teacher Diary' source='data[0].teacherDairy' />
          <TextField
            label='Children Workbooks'
            source='data[0].childrenWorkbooks'
          />
          <TextField label='Audio Device' source='data[0].audioDevice' />

          <TextField label='Start' source='data[0].start' />
          <TextField label='End' source='data[0].end' />
          <TextField label='Today' source='data[0].today' />
          <TextField label='Device ID' source='data[0].deviceid' />
          <TextField label='Subscriber ID' source='data[0].subscriberid' />
          <TextField label='Sim Serial' source='data[0].simserial' />
          <TextField label='Phone Number' source='data[0].phonenumber' />
          <TextField label='Instance ID' source='data[0].instanceID' />

          <ArrayField source='data'>
            <SingleFieldList>
              <TextField source='*meta-instance-id*' />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </List>
    </div>
  );
};
