export const districtMap = {
    "Ambala": [
        "Ambala-I (City)",
        "Ambala-II (Cantt)",
        "Barara",
        "Naraingarh",
        "Saha",
        "Shehzadpur"
    ],
    "Bhiwani": [
        "Bawani Khera",
        "Behal",
        "Bhiwani",
        "Kairu",
        "Loharu",
        "Siwani",
        "Tosham"
    ],
    "Charkhi Dadri": [
        "Badhra",
        "Baund Kalan",
        "Dadri"
    ],
    "Faridabad": [
        "Ballabgarh",
        "Faridabad"
    ],
    "Fatehabad": [
        "Bhattu Kalan",
        "Bhuna",
        "Fatehabad",
        "Jakhal",
        "Ratia",
        "Tohana"
    ],
    "Gurugram": [
        "Farukh Nagar",
        "Gurugram",
        "Pataudi",
        "Sohna"
    ],
    "Hisar": [
        "Adampur",
        "Agroha",
        "Barwala (Hsr)",
        "Hansi-I",
        "Hansi-II",
        "Hisar-I",
        "Hisar-II",
        "Narnaund",
        "Uklana"
    ],
    "Jhajjar": [
        "Bahadurgarh",
        "Beri",
        "Jhajjar",
        "Matanhail",
        "Salhawas"
    ],
    "Jind": [
        "Alewa",
        "Jind",
        "Julana",
        "Narwana",
        "Pillukhera",
        "Safidon",
        "Uchana"
    ],
    "Kaithal": [
        "Guhla at Cheeka",
        "Kaithal",
        "Kalayat",
        "Pundri",
        "Rajaund",
        "Siwan"
    ],
    "Karnal": [
        "Assandh",
        "Gharaunda",
        "Indri",
        "Karnal",
        "Nilokheri",
        "Nissing at Chirao"
    ],
    "Kurukshetra": [
        "Babain",
        "Ladwa",
        "Pehowa",
        "Shahabad",
        "Thanesar"
    ],
    "Mahendragarh": [
        "Ateli",
        "Kanina",
        "Mahendergarh",
        "Nangal Choudhary",
        "Narnaul"
    ],
    "Nuh": [
        "Ferozepur Jhirka",
        "Nagina",
        "Nuh",
        "Punhana",
        "Taoru"
    ],
    "Palwal": [
        "Hassanpur",
        "Hathin",
        "Hodal",
        "Palwal"
    ],
    "Panchkula": [
        "Barwala (Pkl)",
        "Morni",
        "Pinjore",
        "Raipur Rani"
    ],
    "Panipat": [
        "Bapauli",
        "Israna",
        "Madlauda",
        "Panipat",
        "Samalkha"
    ],
    "Rewari": [
        "Bawal",
        "Jatusana",
        "Khol at Rewari",
        "Nahar",
        "Rewari"
    ],
    "Rohtak": [
        "Kalanaur",
        "Lakhan Majra",
        "Meham",
        "Rohtak",
        "Sampla"
    ],
    "Sirsa": [
        "Baragudha",
        "Dabwali",
        "Ellenabad",
        "Nathusari Chopta",
        "Odhan",
        "Rania",
        "Sirsa"
    ],
    "Sonipat": [
        "Ganaur",
        "Gohana",
        "Kathura",
        "Kharkhoda",
        "Mundlana",
        "Rai",
        "Sonipat"
    ],
    "Yamuna Nagar": [
        "Bilaspur",
        "Chhachhrauli",
        "Jagadhri",
        "Saraswati Nagar",
        "Radaur",
        "Sadhaura"
    ]
}

export const blockMap = {
    "Ambala-I (City)": [
        "GSSS BAKNOUR",
        "GSSS BALDEV NAGAR, A/CITY",
        "GSSS BALLANA",
        "GSSS CHHAPRA",
        "GSSS DURANA",
        "GSSS ISMAILPUR",
        "GSSS JALBERA",
        "GSSS JANSUI",
        "GSSS MAJRI",
        "GSSS MOHRA",
        "GSSS MOHRI BHANOKHERI",
        "GSSS NAGGAL",
        "GSSS NANYOLA",
        "GSSS PREM NAGAR, A/CITY",
        "GSSS SHAHPUR"
    ],
    "Ambala-II (Cantt)": [
        "GSSS BABYAL",
        "GSSS BAKRA MARKET",
        "GSSS BC BAZAR A/CANTT.",
        "GSSS BOH",
        "GSSS GARNALA",
        "GSSS GHEL",
        "GSSS KANWLA",
        "GSSS PANJOKHRA",
        "GSSS RAMBAGH ROAD,A/CANTT",
        "GSSS RAMPUR SARSHERI",
        "GSSS SAPEHRA",
        "GSSS SULTANPUR"
    ],
    "Barara": [
        "GMSSSS BARARA",
        "GSSS ADHOYA",
        "GSSS DHANAURA",
        "GSSS DHEEN",
        "GSSS HOLI",
        "GSSS MANKA-MANKI",
        "GSSS MULLANA",
        "GSSS RAJOKHERI",
        "GSSS TANDWAL",
        "GSSS THAMBER",
        "GSSS UGALA",
        "GSSS ZAFFARPUR"
    ],
    "Naraingarh": [
        "GSSS AKBARPUR",
        "GSSS BARAGAON",
        "GSSS BHARERI KALAN",
        "GSSS BHUREWALA",
        "GSSS DERA",
        "GSSS GADHAULI",
        "GSSS JEOLI",
        "GSSS KANJALA",
        "GSSS KATHEMAJRA",
        "GSSS LAHA",
        "GSSS NAGLA RAJPUTANA",
        "GSSS NARAINGARH",
        "GSSS SHAHPUR NURHAD"
    ],
    "Saha": [
        "GHS TALHERI GUJRAN",
        "GSSS BIHTA",
        "GSSS GOKALGARH",
        "GSSS KALPI",
        "GSSS KESRI",
        "GSSS NAHONI",
        "GSSS PASIALA",
        "GSSS PILKHANI",
        "GSSS SAHA",
        "GSSS SAMBHALKHA",
        "GSSS SAMLEHRI"
    ],
    "Shehzadpur": [
        "GGSSS SHAZADPUR",
        "GSSS BARI BASSI",
        "GSSS BHAROG",
        "GSSS DHANANA",
        "GSSS JATWAR",
        "GSSS KAKKARMAJRA",
        "GSSS KARASAN",
        "GSSS KORWAN KHURD",
        "GSSS PATREHRI",
        "GSSS RAIWALI",
        "GSSS SHAZADPUR"
    ],
    "Bawani Khera": [
        "GSSS Alakhpura",
        "GSSS BARSI",
        "GSSS BAWANI KHERA",
        "GSSS JAMALPUR",
        "GSSS KUNGAR",
        "GSSS RATERA",
        "GSSS SUI"
    ],
    "Behal": [
        "GHS PAJU",
        "GSSS BEHAL",
        "GSSS BIDHNOI",
        "GSSS CHAHAR KALAN",
        "GSSS MANDHOLI KALAN",
        "GSSS OBRA",
        "GSSS PATWAN"
    ],
    "Bhiwani": [
        "GGSSS BHIWANI",
        "GGSSS DINOD",
        "GGSSS LOHAR BHIWANI CITY",
        "GSSS BAMLA",
        "GSSS BAPORA",
        "GSSS BHIWANI",
        "GSSS CHANG",
        "GSSS DHANANA",
        "GSSS DHIRANA",
        "GSSS GOLPURA",
        "GSSS HALUWAS",
        "GSSS KAUNT",
        "GSSS KHARAK KALAN",
        "GSSS KITLANA",
        "GSSS MANADHANA",
        "GSSS MANHERU",
        "GSSS MITATHAL",
        "GSSS MUNDHAL KHURD",
        "GSSS NEEMRIWALI",
        "GSSS PALUWAS",
        "GSSS SAI",
        "GSSS SANGA",
        "GSSS TIGRANA"
    ],
    "Kairu": [
        "GSSS DEVRALA",
        "GSSS DHABDHANI",
        "GSSS JUI KHURD",
        "GSSS KAIRU",
        "GSSS KASUMBHI",
        "GSSS LEGHAN",
        "GSSS LOHANI",
        "GSSS SUNGARPUR"
    ],
    "Loharu": [
        "GGSSS GAGARWAS",
        "GSSS BARALU",
        "GSSS BARDU CHAINA",
        "GSSS BISALWAS",
        "GSSS BUDHERA",
        "GSSS DHIGAWA JATTAN",
        "GSSS LOHARU",
        "GSSS PAHARI",
        "GSSS SINGHANI",
        "GSSS SOHANSARA",
        "SSCJR GSSS GIGNOW"
    ],
    "Siwani": [
        "GGSSS SIWANI",
        "GSSS BARWA",
        "GSSS BHERA",
        "GSSS GURERA",
        "GSSS JHUMPA KHURD",
        "GSSS KALOD GUDHA",
        "GSSS MITHI",
        "GSSS NALOI",
        "GSSS SIWANI MANDI"
    ],
    "Tosham": [
        "GGSSS DHANI MAHU",
        "GHS CHAPPAR RANGRAN",
        "GMSSSS TOSHAM",
        "GSSS DULHERI",
        "GSSS ISHARWAL",
        "GSSS KHANAK",
        "GSSS KHARKARI JHANVARI",
        "GSSS MIRAN",
        "GSSS PATODI",
        "GSSS RODHAN"
    ],
    "Badhra": [
        "GGSSS BADHRA",
        "GHS RAMBASS",
        "GSSS BADHRA",
        "GSSS BERLA",
        "GSSS CHANDWAS",
        "GSSS DOHKA MAUJI",
        "GSSS DWARKA",
        "GSSS HARODI",
        "GSSS KADMA",
        "GSSS KAKROLI SARDARA",
        "GSSS KARI DHARNI",
        "GSSS PICHOPA KALAN"
    ],
    "Baund Kalan": [
        "GGSSS DHANI PHOGAT",
        "GSSS BAUND KALAN",
        "GSSS BHAGESHWARI",
        "GSSS IMLOTA",
        "GSSS MAURI",
        "GSSS MISRI",
        "GSSS RANILA",
        "GSSS SAMASPUR",
        "GSSS SANJARWAS",
        "GSSS SANWAR",
        "GSSS SARUPGARH SANTOR"
    ],
    "Dadri": [
        "GGSSS CH. DADRI",
        "GGSSS CHARKHI",
        "GSSS ADAMPUR DADHI",
        "GSSS ATELA",
        "GSSS BIRHI KALAN",
        "GSSS CH. DADRI",
        "GSSS CHAPPAR",
        "GSSS CHIRIYA",
        "GSSS JAWA",
        "GSSS JHOJHU KALAN",
        "GSSS MANDOLA",
        "GSSS N.B. Rajputtan",
        "GSSS PAINTAWAS KALAN"
    ],
    "Ballabgarh": [
        "GHS GHARORA",
        "GHS SAGARPUR",
        "GMSSSS FARIDABAD SECTOR 55",
        "GSSS ARUA",
        "GSSS BALLABGARH",
        "GSSS DAYALPUR",
        "GSSS FATEHPUR BILLOCH",
        "GSSS FATEHPUR TAGGA",
        "GSSS JHARSAINTLY",
        "GSSS KAURALI",
        "GSSS MIRTZAPUR",
        "GSSS MOHNA",
        "GSSS PANHERA KHURD",
        "GSSS SAMAYPUR",
        "GSSS SEC7 SIHI",
        "GSSS SEEKRI",
        "GSSS UNCHAGAON"
    ],
    "Faridabad": [
        "GHS NANGLA GUJRAN",
        "GSSS AJRONDA",
        "GSSS ANANGPUR",
        "GSSS BHUPANI",
        "GSSS DHAUJ",
        "GSSS FARIDABAD OLD",
        "GSSS JASANA",
        "GSSS KHERI KALAN",
        "GSSS M.M.PUR",
        "GSSS NIT NO.1",
        "GSSS NIT NO.3",
        "GSSS PALI",
        "GSSS SARAI KHAWAJA",
        "GSSS SARAN",
        "GSSS SEHATPUR",
        "GSSS TIGAON"
    ],
    "Bhattu Kalan": [
        "GSSS BHATTU KALAN",
        "GSSS BODIWALI",
        "GSSS KHABRA KALAN",
        "GSSS KIRDHAN",
        "GSSS MEHUWALA",
        "GSSS PILI MANDORI",
        "GSSS RAMSARA",
        "GSSS SEKHUPUR DAROLI"
    ],
    "Bhuna": [
        "GSSS BHUNA",
        "GSSS BUWAN",
        "GSSS DHOLU",
        "GSSS GORAKHPUR",
        "GSSS JANDLI KALAN",
        "GSSS LEHRIAN",
        "GSSS NEHLA",
        "SSMS GGSSS SANIANA"
    ],
    "Fatehabad": [
        "GGSSS FATEHABAD",
        "GHS AHLI NOORKI MAJRA",
        "GSSS AYALKI",
        "GSSS BADOPAL",
        "GSSS BANGAON",
        "GSSS BHATTU KHURD",
        "GSSS BHIRDANA",
        "GSSS BIGHAR",
        "GSSS CHINDER",
        "GSSS DARIYAPUR",
        "GSSS FATEHABAD",
        "GSSS HIZRAWAN KHURD",
        "GSSS JHALNIA",
        "GSSS KHARA KHERI",
        "GSSS M.P.ROHI",
        "GSSS NAGPUR"
    ],
    "Jakhal": [
        "GSSS CHANDPURA",
        "GSSS JAKHAL MANDI",
        "GSSS KARANDI",
        "GSSS MEYOND KALAN",
        "GSSS SHAKARPURA",
        "GSSS SIDHANI"
    ],
    "Ratia": [
        "GSSS AHERWAN",
        "GSSS ALAWALWAS",
        "GSSS BALIALA",
        "GSSS HAROLI",
        "GSSS HASANGA",
        "GSSS LAMBA",
        "GSSS MADH",
        "GSSS MAHMRA",
        "GSSS MP SOTTER",
        "GSSS NANGAL",
        "GSSS RATIA",
        "GSSS RATTA KHERA"
    ],
    "Tohana": [
        "GMSSSS INDACHHOI",
        "GSSS DHARSUL KALAN",
        "GSSS GAJUWALA",
        "GSSS HAIDERWALA",
        "GSSS JAMALPUR SHEKHAN",
        "GSSS KANHERI",
        "GSSS PARTA",
        "GSSS TOHANA",
        "GSSS TOHANA MANDI"
    ],
    "Farukh Nagar": [
        "GSSS BHANGROLA",
        "GSSS BUDHERA",
        "GSSS F NAGAR",
        "GSSS F NAGAR-G",
        "GSSS JAMAL PUR",
        "GSSS JATOLA",
        "GSSS JONIAWAS",
        "GSSS Karola",
        "GSSS KHANDEWLA",
        "GSSS KHENTAWAS",
        "GSSS MOKALWAS",
        "GSSS PATLI",
        "GSSS SIWARI"
    ],
    "Gurugram": [
        "GGSSS JACOBPURA",
        "GMSSS SANSKRITI SCHOOL SEC-43",
        "GSSS BASAI",
        "GSSS BHANDWARI",
        "GSSS BHIMGARH KHERI",
        "GSSS CARTERPURI",
        "GSSS CHAKKARPUR",
        "GSSS DAULTABAD",
        "GSSS DHANKOT",
        "GSSS GARHI HARSARU",
        "GSSS ISLAMPUR",
        "GSSS JHARSA",
        "GSSS KADIPUR",
        "GSSS KASAN",
        "GSSS KHANDSA",
        "GSSS KHERKI DAULA",
        "GSSS MANESAR",
        "GSSS NAWADA F.PUR",
        "GSSS SARHAUL",
        "GSSS SEC-4/7",
        "GSSS SHIKOHPUR",
        "GSSS WAZIRABAD"
    ],
    "Pataudi": [
        "GGSSS HAILYMANDI",
        "GGSSS PATAUDI",
        "GSSS BASPADAMKA",
        "GSSS BHORA KALAN",
        "GSSS KHALILPUR",
        "GSSS KHOR",
        "GSSS LOKRA",
        "GSSS NANU KALAN",
        "GSSS NOORGARH",
        "GSSS PANCHGAON",
        "GSSS PATAUDI",
        "GSSS PATHERHERI",
        "GSSS SHERPUR",
        "GSSS SIDHARWALI",
        "GSSS UNCHA MAJRA"
    ],
    "Sohna": [
        "GGSSS Badshahpur",
        "GSSS ABHEYPUR",
        "GSSS DARBARIPUR",
        "GSSS DAULAH",
        "GSSS GHAMROJ ALIPUR",
        "GSSS GHANGOLA",
        "GSSS HARCHANDPUR",
        "GSSS HAZIPUR",
        "GSSS KADARPUR",
        "GSSS KHERLA",
        "GSSS KHERLI LALA",
        "GSSS SOHNA-B",
        "GSSS SOHNA-G",
        "GSSS TEEKLI",
        "SHAHEED SUBEDAR KUMARPAL SINGH RAGHAV GSSS BHONDSI"
    ],
    "Adampur": [
        "GSSS ADAMPUR",
        "GSSS ASRAWAN",
        "GSSS DAROLI",
        "GSSS GHURSAL",
        "GSSS KOHLI",
        "GSSS MODAKHERA",
        "GSSS MOHABATPUR",
        "GSSS SADALPUR",
        "GSSS SISWAL"
    ],
    "Agroha": [
        "GGSSS AGROHA",
        "GHS BHANA",
        "GSSS DURJANPUR",
        "GSSS KALIRAWAN",
        "GSSS KIRMARA",
        "GSSS KULERI",
        "GSSS LANDHARI",
        "GSSS NANGTHALA"
    ],
    "Barwala (Hsr)": [
        "GGSSS BARWALA",
        "GSSS ANAJ MANDI",
        "GSSS BADHWAR",
        "GSSS BALAK",
        "GSSS BARWALA",
        "GSSS BHABALPUR",
        "GSSS BIYANA KHERA",
        "GSSS DHANI KHAN BHADUR",
        "GSSS GABIPUR",
        "GSSS JUGLAN",
        "GSSS KHARAK PUNIA",
        "GSSS KHEDAR",
        "GSSS MATLODA",
        "GSSS PANIHARI",
        "GSSS RAJLI",
        "GSSS SARSOD BICHPARI SCHOOL"
    ],
    "Hansi-I": [
        "GGSSS GHIRAI",
        "GGSSS Hansi",
        "GGSSS NRM GARHI",
        "GHS HAZAMPUR",
        "GMSSSS SISAI",
        "GSSS BHATLA",
        "GSSS DATTA",
        "GSSS DHANA KHURD",
        "GSSS DHANDHERI",
        "GSSS HANSI",
        "GSSS JAMAWARI",
        "GSSS PUTHI MANGAL KHAN",
        "GSSS RAMPURA GAON",
        "GSSS SINGHWA RAGHO",
        "GSSS SORKHI",
        "GSSS SULTANPUR",
        "GSSS UMRA"
    ],
    "Hansi-II": [
        "GGSSS KHARBLA KHERA",
        "GSSS BASS",
        "GSSS BHATOL KHARKARA",
        "GSSS KHANDAKHERI",
        "GSSS PUTHI SAMAIN",
        "GSSS SINGHWA KHAS",
        "GSSS UGALAN"
    ],
    "Hisar-I": [
        "GGSSS BADYA BRAHMANAN",
        "GGSSS GANGWA",
        "GGSSS MANGALI",
        "GSSS CHIROD",
        "GSSS DABRA",
        "GSSS GANGWA",
        "GSSS HISAR",
        "GSSS HISAR MODEL TOWN",
        "GSSS MANGALI",
        "GSSS MAYYAR",
        "GSSS MIRJAPUR",
        "GSSS NALWA",
        "GSSS NIANA",
        "GSSS PATEL NAGAR HISAR",
        "GSSS SAHARWA",
        "GSSS SATROD KHURD",
        "GSSS SHIKARPUR",
        "GSSS TALWANDI RANA",
        "GSSS TALWANDI RUKKA"
    ],
    "Hisar-II": [
        "GGSSS HISAR",
        "GSSS ARYANAGAR",
        "GSSS BALSAMAND",
        "GSSS BANDAHERI",
        "GSSS BHIWANI ROHILLAN",
        "GSSS CHOUDHARIWAS",
        "GSSS D BLOCK",
        "GSSS DHANI PEERANWALI",
        "GSSS DOBHI",
        "GSSS JAKHOD KHERA",
        "GSSS KALWAS",
        "GSSS KHARIA",
        "GSSS Kirtan",
        "GSSS MUKLAN",
        "GSSS NEOLI KALAN",
        "GSSS NEOLI KHURD",
        "GSSS RAWALWAS KALAN",
        "GSSS SARSANA",
        "GSSS SHAHPUR"
    ],
    "Narnaund": [
        "GGSSS KHERI JALAB CHANDRO DEVI",
        "GSSS BHAINI AMIRPUR",
        "GSSS KAPRO",
        "GSSS KOTH KALAN",
        "GSSS LOHARI RAGHO",
        "GSSS MIRCH PUR",
        "GSSS NARNAUND",
        "GSSS PALI",
        "GSSS PETWAR",
        "GSSS RAKHI SHAHPUR"
    ],
    "Uklana": [
        "GGSSS UKLANA MANDI",
        "GSSS BITHMARA",
        "GSSS CHAMAR KHERA",
        "GSSS DAULATPUR",
        "GSSS KINALA",
        "GSSS LITANI",
        "GSSS PABRA",
        "GSSS PARBHUWALA",
        "GSSS UKLANA MANDI"
    ],
    "Bahadurgarh": [
        "GGSSS NUNA MAJRA",
        "GSSS ASAUDHA TODRAN",
        "GSSS BADLI",
        "GSSS BAHADURGARH",
        "GSSS BUPANIA",
        "GSSS CHHARA",
        "GSSS DULHERA",
        "GSSS KANONDA",
        "GSSS LUKSAR",
        "GSSS MATAN",
        "GSSS MUNDA KHERA",
        "GSSS PARNALA",
        "GSSS ROHAD",
        "GSSS SAN KHOL"
    ],
    "Beri": [
        "GSSS ACHHEJ PAHARIPUR",
        "GSSS BERI",
        "GSSS DHANDLAN",
        "GSSS DIGHAL",
        "GSSS MADANA KALAN",
        "GSSS MAJRA D",
        "LSL GSSS JAHAJGARH"
    ],
    "Jhajjar": [
        "GGSSS SILANA",
        "GSSS DADRI TOE",
        "GSSS DAWLA",
        "GSSS DUJANA",
        "GSSS JAHANGIRPUR",
        "GSSS JHAJJAR",
        "GSSS KABLANA",
        "GSSS MACHHROLI",
        "GSSS PATAUDA",
        "GSSS PELPA",
        "GSSS TALAO"
    ],
    "Matanhail": [
        "GMSSSS LADAIN",
        "GSSS BAHU",
        "GSSS CHHUCHAKWAS",
        "GSSS KHACHHRAULI",
        "GSSS KHANPUR KHURD",
        "GSSS KHETAWAS",
        "GSSS MATANHAIL",
        "GSSS SASRAULI"
    ],
    "Salhawas": [
        "GSSS BHURAWAS",
        "GSSS DHAKLA",
        "GSSS KASNI",
        "GSSS KHUDAN",
        "GSSS SUBANA"
    ],
    "Alewa": [
        "GMSSS NAGURAN",
        "GSSS ALEWA",
        "GSSS DAHOLA",
        "GSSS PEGAN",
        "GSSS RAI CHAND WALA"
    ],
    "Jind": [
        "GGSSS SINDHVI KHERA",
        "GMSSS Defence Colony Jind",
        "GSSS AHIRKA",
        "GSSS BISHANPURA",
        "GSSS DALAMWALA",
        "GSSS GHIMANA",
        "GSSS IKKAS",
        "GSSS JHANJ KALAN",
        "GSSS KANDELA",
        "GSSS KHARAK RAMJI",
        "GSSS MANOHARPUR",
        "GSSS NIDANI",
        "GSSS RAM RAI",
        "GSSS SANGATPURA"
    ],
    "Julana": [
        "GMSSS JULANA",
        "GSSS Fatehgarh",
        "GSSS GARHWALI KHERA",
        "GSSS GATAULI",
        "GSSS KILA ZAFARGARH",
        "GSSS KINANA",
        "GSSS LAJWANA KALAN",
        "GSSS SHAMLO KALAN"
    ],
    "Narwana": [
        "GMSSSS BELARKHA",
        "GSSS AMARGARH",
        "GSSS BHANA BRAHMANAN",
        "GSSS DANODA  KALAN",
        "GSSS DHAMTAN SAHIB",
        "GSSS Frain Kalan",
        "GSSS NARWANA",
        "GSSS PHULIAN KALAN",
        "GSSS PIPALTHA",
        "GSSS SINGHOWAL",
        "GSSS Ujhana"
    ],
    "Pillukhera": [
        "GSSS BUDHA KHERA",
        "GSSS DHATRATH",
        "GSSS Gangoli",
        "GSSS MORKHI",
        "GSSS PILLU KHERA MANDI",
        "GSSS RETAULI"
    ],
    "Safidon": [
        "GSSS BHUSLANA",
        "GSSS KARKHANA",
        "GSSS MUANA",
        "GSSS PAJU KALAN",
        "GSSS SAFIDON CITY",
        "GSSS SIWANA MAL"
    ],
    "Uchana": [
        "GGSSS DUMERKHA KALAN",
        "GSSS BADANPUR",
        "GSSS BARODA",
        "GSSS CHHATTAR",
        "GSSS KABARCHHA",
        "GSSS Kakrod",
        "GSSS Karsindhu",
        "GSSS Kasuhan",
        "GSSS UCHANA KALAN",
        "GSSS UCHANA KHURD"
    ],
    "Guhla at Cheeka": [
        "GSSS AGONDH",
        "GSSS ARNOULI",
        "GSSS BALBEHRA",
        "GSSS BHAGAL",
        "GSSS BHUNA",
        "GSSS CHEEKA",
        "GSSS DABA",
        "GSSS GAGARPUR",
        "GSSS GUHLA",
        "GSSS PEEDAL",
        "GSSS SEON MAJRA"
    ],
    "Kaithal": [
        "GGSSS DEOBAN",
        "GGSSS KAITHAL",
        "GMSSSS KEORAK",
        "GSSS CHANDANA",
        "GSSS DIWAL",
        "GSSS GEONG",
        "GSSS HARSOLA",
        "GSSS KAITHAL",
        "GSSS PADLA",
        "GSSS PATTI AFGAN",
        "GSSS SANGHAN",
        "GSSS SHERGARH",
        "GSSS TEEK"
    ],
    "Kalayat": [
        "GSSS CHOUSHALA",
        "GSSS DHUNDWA KOLEKHAN",
        "GSSS KAILRAM",
        "GSSS KALAYAT",
        "GSSS KAMALPUR",
        "GSSS KHADALWA",
        "GSSS KURAR"
    ],
    "Pundri": [
        "GSSS BAKAL",
        "GSSS DHAND",
        "GSSS HABRI",
        "GSSS KAUL",
        "GSSS PABNAWA",
        "GSSS PAI",
        "GSSS PHARAL",
        "GSSS PUNDRI",
        "GSSS RASINA",
        "GSSS SAKRA",
        "GSSS TEONTHA"
    ],
    "Rajaund": [
        "GHS KOTRA",
        "GSSS BHANA",
        "GSSS JAKHOULI",
        "GSSS KITHANA",
        "GSSS RAJOUND",
        "GSSS ROHERA",
        "GSSS SERHADA"
    ],
    "Siwan": [
        "GGSSS SIWAN",
        "GSSS KANGTHALI",
        "GSSS KAWARTAN",
        "GSSS KHARAK",
        "GSSS KHERI GULAM ALI",
        "GSSS LADANA CHAKKU",
        "GSSS SIWAN"
    ],
    "Assandh": [
        "GSSS ARDANA",
        "GSSS ASSANDH",
        "GSSS BAHRI",
        "GSSS BALLAH",
        "GSSS CHOCHRA",
        "GSSS G.T. POPRAN",
        "GSSS GOLI",
        "GSSS JALMANA",
        "GSSS KHERISARFALI",
        "GSSS PADHA",
        "GSSS PHAPHRANA",
        "GSSS RAHRA",
        "GSSS RATTAK",
        "GSSS SALWAN",
        "GSSS UPLANA"
    ],
    "Gharaunda": [
        "GSSS ARIANPURA",
        "GSSS BAL RANGRAN",
        "GSSS BARSAT",
        "GSSS CHAURA",
        "GSSS DEVIPUR",
        "GSSS FARIDPUR",
        "GSSS GAGSINA",
        "GSSS GARHI KHAJOOR",
        "GSSS GHARAUNDA",
        "GSSS KAIMLA",
        "GSSS KOHAND",
        "GSSS KUTAIL",
        "GSSS KUTANA",
        "GSSS MUNAK",
        "GSSS PHURLAK"
    ],
    "Indri": [
        "GGSSS INDRI",
        "GHS BHOJI KHALSA",
        "GHS HANORI",
        "GHS MURADGARH",
        "GHS UDANA",
        "GSSS BHADSON",
        "GSSS BIANA",
        "GSSS BIBIPUR JATTAN",
        "GSSS DABKOLI KALAN",
        "GSSS GARHI BIRBAL",
        "GSSS GARHI JATTAN",
        "GSSS INDRI (BOYS)",
        "GSSS KALRI JAGIR",
        "GSSS KALSOURA",
        "GSSS KHANPUR",
        "GSSS KHERI MAAN SINGH",
        "GSSS NAGLA RORAN",
        "GSSS PATHERA"
    ],
    "Karnal": [
        "GGSSS PREM NAGAR KARNAL",
        "GGSSS RLY. ROAD, KARNAL",
        "GSSS BARAGAON",
        "GSSS DABARKI KALAN",
        "GSSS DAHA",
        "GSSS DARAR",
        "GSSS DHAKWALA GUJRAN",
        "GSSS GHEER",
        "GSSS KACHHWA",
        "GSSS KARNAL",
        "GSSS KUNJPURA",
        "GSSS MANGALPUR",
        "GSSS MODEL TOWN KARNAL",
        "GSSS MOHIUDINPUR",
        "GSSS SANGOHA",
        "GSSS SHEIKHPURA SOHANA",
        "GSSS SUBHRI",
        "GSSS TIKRI",
        "GSSS UCHANA",
        "GSSS UNCHA SAMANA"
    ],
    "Nilokheri": [
        "GMSSSS TARAORI",
        "GSSS ANJANTHALI",
        "GSSS BHAINI KHURD",
        "GSSS KOER MAJRA",
        "GSSS MOHRI JAGIR",
        "GSSS NIGDHU",
        "GSSS NILOKHERI",
        "GSSS PADHANA",
        "GSSS RAISON",
        "GSSS SAGGA",
        "GSSS SAMANA BAHU",
        "GSSS SAMBHI",
        "GSSS SANWAT",
        "GSSS SHAMGARH",
        "GSSS SIKRI",
        "GSSS TARAORI"
    ],
    "Nissing at Chirao": [
        "GSSS BASTLI",
        "GSSS BRASS",
        "GSSS CHIRAO",
        "GSSS DACHER",
        "GSSS GONDER",
        "GSSS JUNDLA",
        "GSSS KATLAHERI",
        "GSSS KHERI NARU",
        "GSSS MANJURA",
        "GSSS NISSING"
    ],
    "Babain": [
        "GHS BIR KALWA",
        "GHS KASITHAL",
        "GSSS BABAIN",
        "GSSS MANGOLI JATTAN"
    ],
    "Ladwa": [
        "GGSSS BARAUT",
        "GGSSS LADWA",
        "GSSS BANI",
        "GSSS BARAUNDA",
        "GSSS GUDHA",
        "GSSS KHAIRI",
        "GSSS LADWA",
        "GSSS LOHARA",
        "GSSS MEHRA",
        "GSSS PRAHLADPUR"
    ],
    "Pehowa": [
        "GGSSS PEHOWA",
        "GSSS BAKHLI",
        "GSSS BODHNI",
        "GSSS GUMTHALA GARU",
        "GSSS HARIGARH BHORAKH",
        "GSSS KALSA",
        "GSSS MURTZAPUR",
        "GSSS PEHOWA",
        "GSSS SANDHOLI",
        "GSSS SARSA",
        "GSSS SEONSAR",
        "GSSS THANA",
        "GSSS THASKA MIRANJI",
        "GSSS URNAICHA",
        "SHAHID JASWINDER SINGH GSSS TALHERI"
    ],
    "Shahabad": [
        "GHS SURAKHPUR",
        "GMSSSS ISMAILABAD",
        "GSSS CHARUNI JATTAN",
        "GSSS HARIPUR",
        "GSSS JHANDHERI",
        "GSSS JHANSA",
        "GSSS KALSANA",
        "GSSS KHARINDWA",
        "GSSS LANDI",
        "GSSS NALVI",
        "GSSS RAWA",
        "GSSS SAMBALKHI",
        "GSSS SHAHBAD",
        "GSSS SHARIFGARH",
        "GSSS THOL"
    ],
    "Thanesar": [
        "GGSSS PARTAPGARH",
        "GGSSS THANESAR",
        "GHS Lukhi",
        "GHS TIGRI KHALSA",
        "GMSSSS KURUKSHETRA",
        "GSSS AJRANA KALAN",
        "GSSS AMIN",
        "GSSS BALAHI",
        "GSSS BEHOLI",
        "GSSS BHIWANI KHERA",
        "GSSS BHUSTHALA",
        "GSSS DEVIDASPURA",
        "GSSS DHURALA",
        "GSSS DUDHLA MORTHLA",
        "GSSS HATHIRA",
        "GSSS JYOTISAR",
        "GSSS KAMODA",
        "GSSS KANIPLA",
        "GSSS MATHANA",
        "GSSS MIRZAPUR",
        "GSSS PIPLI",
        "GSSS UMRI"
    ],
    "Ateli": [
        "GGSSS GUJJARWAS",
        "GHS TAJPUR",
        "GHS TIGRA",
        "GSSS ATELI",
        "GSSS BACHHOD",
        "GSSS BIHALI",
        "GSSS BOCHARIA",
        "GSSS CHANDPURA",
        "GSSS NEERPUR",
        "GSSS RATTA KALAN",
        "GSSS SIHMA",
        "GSSS SILARPUR",
        "Shaheed Man Singh GSSS Kheri Kanti"
    ],
    "Kanina": [
        "GSSS BAGHOT",
        "GSSS BAWANIA",
        "GSSS BEWAL",
        "GSSS BHOJAWAS",
        "GSSS BUCHAWAS",
        "GSSS DHANAUNDA",
        "GSSS DONGARA AHIR",
        "GSSS KANINA",
        "GSSS KHERI TALWANA",
        "GSSS NANGAL MOHANPUR",
        "GSSS Pathera",
        "GSSS RAMBASS",
        "GSSS SEHORE"
    ],
    "Mahendergarh": [
        "GGSSS MAHENDERGARH",
        "GHS BHAGDANA",
        "GMSSSS MAHENDERGARH",
        "GSSS AKODA",
        "GSSS BAIRAWAS",
        "GSSS BERI",
        "GSSS BUDIN",
        "GSSS DIGROTA",
        "GSSS DULANA",
        "GSSS JARWA",
        "GSSS KHATODRA",
        "GSSS KHUDANA",
        "GSSS KOTHAL KALAN",
        "GSSS MALRA BASS",
        "GSSS MANDOLA",
        "GSSS NANWAN",
        "GSSS NIMBI",
        "GSSS PALI",
        "GSSS PATHARWA",
        "GSSS SATNALI",
        "GSSS SURETI PILANIA"
    ],
    "Nangal Choudhary": [
        "GSSS BANIHARI",
        "GSSS BAYAL",
        "GSSS BHUNGKHARKA",
        "GSSS BUDHWAL",
        "GSSS DHOLERA",
        "GSSS GANWARI JAT",
        "GSSS KAMANIA",
        "GSSS KANWI",
        "GSSS MANDI",
        "GSSS NANGAL CHOUDHARY",
        "GSSS NANGAL DARGU",
        "GSSS NAYAN",
        "GSSS NIYAMANTPUR",
        "GSSS SEKA",
        "GSSS SHAHBAJPUR",
        "GSSS SIROHI BAHALI"
    ],
    "Narnaul": [
        "GHS NANGAL KATHA",
        "GMSSS NARNAUL",
        "GSSS A/MOKHUTA",
        "GSSS DOCHANA",
        "GSSS GEHLI",
        "GSSS HUDINA",
        "GSSS JAILAF",
        "GSSS JORASI",
        "GSSS KORIAWAS",
        "GSSS KRISHAN NAGAR",
        "GSSS MAROLI",
        "GSSS NIWAZ NAGAR",
        "GSSS NIZAMPUR",
        "GSSS PATIKRA"
    ],
    "Ferozepur Jhirka": [
        "GGSSS AHMAD BAS",
        "GGSSS F.P. JHIRKA",
        "GHS BUBALHERI",
        "GHS DOHA",
        "GHS MAHOON",
        "GHS NEEMKHERA",
        "GSSS AGON",
        "GSSS BADED",
        "GSSS BHAKROJI",
        "GSSS BIWAN",
        "GSSS F.P. JHIRKA",
        "GSSS PATHKHORI",
        "GSSS RANIYALA",
        "GSSS RAWLI",
        "GSSS SAKRAS"
    ],
    "Nagina": [
        "GGSSS NAGINA",
        "GHS BASAI KHANZADA",
        "GHS BHADAS",
        "GHS MARORA",
        "GHS RANIYALA PATAKPUR",
        "GHS UMRA",
        "GSSS BADARPUR",
        "GSSS BAZIDPUR",
        "GSSS MANDIKHERA",
        "GSSS MOOLTHAN",
        "GSSS NAGINA"
    ],
    "Nuh": [
        "GHS BIBIPUR",
        "GHS KURTHALA",
        "GMSSSS NUH",
        "GSSS  KK PUR",
        "GSSS  Ujina",
        "GSSS ADBAR",
        "GSSS ATTA BAROTA",
        "GSSS CHHAPERA",
        "GSSS DEWLA NANGLI",
        "GSSS FEROZPUR NAMAK",
        "GSSS GHASERA",
        "GSSS INDRI",
        "GSSS KAIRAKA",
        "GSSS KANWARSHIKA",
        "GSSS KHERLA",
        "GSSS KHORE BASAI",
        "GSSS MALAB",
        "GSSS MEOLI KALAN",
        "GSSS REWASAN",
        "GSSS Tapkan"
    ],
    "Punhana": [
        "GGSSS PINAGWAN",
        "GGSSS PUNHANA",
        "GMSSSS SIROLI",
        "GSSS BICHORE",
        "GSSS BISRU",
        "GSSS DUDOLI",
        "GSSS HATHAN GAON",
        "GSSS JAMALGARH",
        "GSSS LOHINGA KALAN",
        "GSSS NAI",
        "GSSS NIWANA",
        "GSSS OTHA",
        "GSSS PINANGWAN",
        "GSSS PUNHANA",
        "GSSS RAIPUR",
        "GSSS SHAHCHOKHA",
        "GSSS SHIKRAWA",
        "GSSS SINGAR"
    ],
    "Taoru": [
        "GGSSS TAORU",
        "GHS PADHENI",
        "GSSS BISSAR AKBAR PUR",
        "GSSS CHHARORA",
        "GSSS DHULAWAT",
        "GSSS GURNAWAT",
        "GSSS HASANPUR",
        "GSSS JAURASI",
        "GSSS KALWARI",
        "GSSS M.P. AHIR",
        "GSSS PANCHGAON",
        "GSSS PATUKA",
        "GSSS RAHERI",
        "GSSS RATHIWAS",
        "GSSS Subaseri",
        "GSSS SUNDH",
        "GSSS TAORU"
    ],
    "Hassanpur": [
        "GGSSS HASSANPUR",
        "GSSS BARAULI",
        "GSSS GULAWAD",
        "GSSS HASSANPUR",
        "GSSS KHANBI",
        "GSSS KUSHAK",
        "GSSS PINGOR"
    ],
    "Hathin": [
        "GGSSS BAHIN",
        "GGSSS HATHIN",
        "GHS HURITHAL",
        "GSSS ALI MEO",
        "GSSS CHHAINSA",
        "GSSS GEHLAB",
        "GSSS GHARROT",
        "GSSS HATHIN",
        "GSSS MALAI",
        "GSSS MANDKOLA",
        "GSSS UTTAWAR"
    ],
    "Hodal": [
        "GGSSS AURANGABAD",
        "GGSSS BHIDUKI",
        "GSSS AURANGABAD",
        "GSSS BAMNIKHERA",
        "GSSS BANCHARI",
        "GSSS BHULWANA",
        "GSSS DEEGHOT",
        "GSSS HODAL",
        "GSSS SIHA",
        "GSSS SONDHAD"
    ],
    "Palwal": [
        "GGSSS ALAWALPUR",
        "GGSSS PALWAL CAMP",
        "GGSSS PALWAL CITY",
        "GMSSSS DHATIR",
        "GSSS AHARWAN",
        "GSSS ALAWALPUR",
        "GSSS ALLIKA",
        "GSSS AMARPUR",
        "GSSS ASAWTA",
        "GSSS ASOATI",
        "GSSS BAGPUR",
        "GSSS BASANTGARH",
        "GSSS CHANDHUT",
        "GSSS DUDOLA",
        "GSSS GHORI",
        "GSSS JAINDAPUR",
        "GSSS KARNA",
        "GSSS PALWAL",
        "GSSS PIRTHLA",
        "GSSS TIKRI BRAHMAN"
    ],
    "Barwala (Pkl)": [
        "GGSSS BARWALA",
        "GMSSSS BATOUR",
        "GSSS BARWALA",
        "GSSS BUNGA",
        "GSSS KHATAULI",
        "GSSS KOT",
        "GSSS PARWALA",
        "GSSS RAMGARH",
        "GSSS RATTEWALI"
    ],
    "Morni": [
        "GMSSSS MORNI",
        "GSSS BALDWALA",
        "GSSS Bhuri",
        "GSSS KOTI",
        "GSSS MANDHANA",
        "GSSS THANDOG",
        "GSSS Tikkar Hills"
    ],
    "Pinjore": [
        "GMSSS (SANSKRITI) SEC-20, PKL",
        "GMSSS (SANSKRITI) SEC-26, PKL",
        "GMSSSS (SARTHAK) SEC-12 A, PKL",
        "GSSS BARGODAM",
        "GSSS BASOULAN",
        "GSSS BHORIAN",
        "GSSS CHIKEN",
        "GSSS DHATOGRA",
        "GSSS GHAGGARBIR",
        "GSSS KALKA",
        "GSSS Karanpur",
        "GSSS MALLAH",
        "GSSS NANAKPUR",
        "GSSS Nava Nagar",
        "GSSS PAPLOHA",
        "GSSS PINJORE",
        "GSSS RAJJIPUR",
        "GSSS SEC-19 PKL",
        "GSSS SEC-6 PKL",
        "GSSS SEC-7 PKL",
        "GSSS SUKETRI"
    ],
    "Raipur Rani": [
        "GHS MAULI",
        "GSSS BHOOD",
        "GSSS GARHI KOTAHA",
        "GSSS HANGOLA",
        "GSSS RAIPUR RANI",
        "GSSS RAMPUR THADEON",
        "GSSS TODA"
    ],
    "Bapauli": [
        "GGSSS BAPOLI",
        "GSSS CHHAJPUR KALAN",
        "GSSS GARHI BASIC",
        "GSSS GOELA KHURD",
        "GSSS JALALPUR-1",
        "GSSS SANOLI KHURD",
        "GSSS UJHA"
    ],
    "Israna": [
        "GSSS BUANA LAKHU",
        "GSSS DAHAR",
        "GSSS ISRANA",
        "GSSS KURANA",
        "GSSS MANDI",
        "GSSS NAULTHA"
    ],
    "Madlauda": [
        "GSSS LOHARI",
        "GSSS MADLAUDA",
        "GSSS NARA",
        "GSSS RAIR KALAN",
        "GSSS SHODAPUR",
        "GSSS SITHANA",
        "GSSS URLANA KALAN"
    ],
    "Panipat": [
        "GGSSS M.TOWN",
        "GGSSS SHIV NAGAR PANIPAT",
        "GMSSSS G T ROAD PANIPAT",
        "GSSS BABAIL",
        "GSSS BARAULI",
        "GSSS CHANDOLI",
        "GSSS JATTAL",
        "GSSS JHATTIPUR",
        "GSSS KABRI",
        "GSSS KRISHAN PURA",
        "GSSS RAJAKHERI",
        "GSSS SIWAH",
        "GSSS TEHSIL CAMP",
        "GSSS UGRAKHERI"
    ],
    "Samalkha": [
        "GSSS  MACCHRAULI",
        "GSSS CHULKANA",
        "GSSS HATHWALA",
        "GSSS KIWANA",
        "GSSS MANANA",
        "GSSS PATTIKALYANA",
        "GSSS RAKSERA",
        "GSSS SAMALKHA"
    ],
    "Bawal": [
        "GGSSS BAWAL",
        "GSSS ASIAKI PANCHORE SAMPLI",
        "GSSS BAGTHALA",
        "GSSS BALAWAS",
        "GSSS BANIPUR",
        "GSSS BAWAL",
        "GSSS GARHI BOLNI",
        "GSSS GUJAR MAJRI",
        "GSSS JHABUA",
        "GSSS MOHANPUR",
        "GSSS NANGAL SHAHBAJ PUR",
        "GSSS NANGAL TEJU",
        "GSSS PRANPURA",
        "GSSS RAJGARH",
        "GSSS SANGWARI",
        "GSSS SULKHA"
    ],
    "Jatusana": [
        "GSSS ASIAKI GORAWAS",
        "GSSS BERLI KHURD",
        "GSSS BORIA KAMALPUR",
        "GSSS D.G. PURA",
        "GSSS Dahina Gohtra Tappa",
        "GSSS DARAULI",
        "GSSS JATUSANA",
        "GSSS KANWALI",
        "GSSS MOTLA KALAN",
        "GSSS NANGAL PATHANI",
        "GSSS PALHAWAS",
        "GSSS SUMA KHERA",
        "SAHEED RAJENDER SINGH GSSS MASTAPUR"
    ],
    "Khol at Rewari": [
        "GHS MUNDI",
        "GSSS KHOL",
        "GSSS KHORI",
        "GSSS KUND",
        "GSSS MANDOLA",
        "GSSS Mayan",
        "GSSS MM BHALKHI",
        "GSSS NANGAL JAMALPUR",
        "GSSS PALI",
        "GSSS Pithrawas",
        "GSSS SIHA"
    ],
    "Nahar": [
        "GSSS BAWWA",
        "GSSS GUDIANI",
        "GSSS JHAL",
        "GSSS JHARODHA",
        "GSSS KOSLI",
        "GSSS LOOKHI",
        "GSSS LULA AHIR",
        "GSSS NAHAR",
        "GSSS RATTANTHAL"
    ],
    "Rewari": [
        "GGSSS MAJRA SHEORAJ",
        "GGSSS REWARI",
        "GMSSSS TATARPUR ISTMURAR",
        "GSSS BHARAWAS",
        "GSSS BHUDPUR",
        "GSSS BIKANER",
        "GSSS BITHWANA",
        "GSSS DHARUHERA",
        "GSSS GOKALGARH",
        "GSSS HANSAKA",
        "GSSS KAKORIA",
        "GSSS KAPRIWAS",
        "GSSS MASANI",
        "GSSS NANDRAMPUR BASS",
        "GSSS NANGLI GODHA",
        "GSSS SAHARANWAS",
        "GSSS SHAHBAJPUR PADIAWAS",
        "GSSS TURKIAWAS"
    ],
    "Kalanaur": [
        "GGSSS KALANAUR",
        "GMSSSS KALANAUR",
        "GSSS BANIYANI",
        "GSSS KAHNAUR",
        "GSSS LAHLI",
        "GSSS NIGANA",
        "GSSS SUNDANA"
    ],
    "Lakhan Majra": [
        "GSSS GHARAWATHI",
        "GSSS KHARAK JATAN",
        "GSSS LAKHAN MAJRA",
        "GSSS TITOLI"
    ],
    "Meham": [
        "GGSSS MEHAM",
        "GSSS AJAIB",
        "GSSS FARMANA",
        "GSSS Madina",
        "GSSS MEHAM",
        "GSSS MOKHRA",
        "GSSS NINDANA",
        "GSSS SAIMAN"
    ],
    "Rohtak": [
        "GGSSS BOHAR",
        "GGSSS GANDHI NAGAR, ROHTAK",
        "GGSSS MODEL TOWN, ROHTAK",
        "GGSSS ROHTAK",
        "GMSSSS SANGHI",
        "GSSS BAHU AKBARPUR",
        "GSSS BHAGWATIPUR",
        "GSSS BHALOUT",
        "GSSS BRAHMANWAS",
        "GSSS CHAMARIAN",
        "GSSS GANDHI NAGAR, ROHTAK",
        "GSSS HUMAYUNPUR",
        "GSSS KHIDWALI",
        "GSSS KILOI",
        "GSSS MAKROLI KALAN",
        "GSSS RITOLI",
        "GSSS ROHTAK"
    ],
    "Sampla": [
        "GGSSS HASSANGARH",
        "GSSS DATTAUR",
        "GSSS ISMAILA",
        "GSSS KHARAWAR",
        "GSSS PAKASMA",
        "GSSS SAMCHANA",
        "GSSS SAMPLA"
    ],
    "Baragudha": [
        "GHS KHAI SHERGARH",
        "GHS MATTAR",
        "GHS PANJUANA",
        "GSSS ALIKAN",
        "GSSS BARAGUDHA",
        "GSSS BUPP",
        "GSSS JHORAR ROHI",
        "GSSS NEJADELA KHURD",
        "GSSS PHAGGU",
        "GSSS RAGHUANA",
        "GSSS RORI",
        "GSSS SUKHCHAIN"
    ],
    "Dabwali": [
        "GGSSS DABWALI",
        "GSSS ABUB SHAHAR",
        "GSSS ASA KHERA",
        "GSSS BIJJUWALI",
        "GSSS CHAUTALA",
        "GSSS DABWALI",
        "GSSS DABWALI VILLAGE",
        "GSSS DESUJODHA",
        "GSSS GANGA",
        "GSSS GORIWALA",
        "GSSS KALUANA",
        "GSSS LOHGARH",
        "GSSS MANGIANA",
        "GSSS MASITAN",
        "GSSS RISALIA KHERA"
    ],
    "Ellenabad": [
        "GHS MITHANPURA",
        "GSSS BHURATWALA",
        "GSSS DHOLPALIA",
        "GSSS ELLENABAD",
        "GSSS KARIWALA",
        "GSSS KUTTABADH",
        "GSSS MIRJAPUR",
        "GSSS MOJU KHERA",
        "GSSS TALWARA KHURD"
    ],
    "Nathusari Chopta": [
        "GHS ALI MOHAMMAD",
        "GHS MODIA KHERA",
        "GSSS ARNIANWALI",
        "GSSS DARBA KALAN",
        "GSSS DING",
        "GSSS GUDIA KHERA",
        "GSSS JAMAL",
        "GSSS JODHKAN",
        "GSSS JOGIWALA",
        "GSSS KAGDANA",
        "GSSS NATHUSARI CHOPTA",
        "GSSS NEHRANA",
        "GSSS RAMPURA DHILLON",
        "GSSS RUPAWAS",
        "GSSS SHAHPURIA",
        "GSSS SHERPURA"
    ],
    "Odhan": [
        "GHS DADU",
        "GSSS JALALANA",
        "GSSS KALANWALI MANDI",
        "GSSS MITHRI",
        "GSSS NAURANG",
        "GSSS ODHAN",
        "GSSS PANNIWALA MOTA",
        "GSSS PIPLI"
    ],
    "Rania": [
        "GGSSS RANIA",
        "GHS DHUDHIYANWALI",
        "GHS NATHORE",
        "GSSS BAHIA",
        "GSSS BALASAR",
        "GSSS BANI",
        "GSSS CHAKKAN",
        "GSSS DHOTTAR",
        "GSSS JODHPURIA",
        "GSSS KEHARWALA",
        "GSSS KHARIA",
        "GSSS KUSSAR",
        "GSSS MAUJDEEN",
        "GSSS NAKORA",
        "GSSS OTTU",
        "GSSS RANIA"
    ],
    "Sirsa": [
        "GGSSS KOTLI",
        "GGSSS SIRSA",
        "GHS CHAMAL",
        "GMSSSS SIRSA",
        "GSSS BHAROKHAN",
        "GSSS CHATTARGARH PATTI",
        "GSSS DARBI",
        "GSSS FARWAIN KALAN",
        "GSSS JHORARNALI",
        "GSSS Kelnia",
        "GSSS KHAIRPUR",
        "GSSS MANGALA",
        "GSSS NEZADELA KALAN",
        "GSSS PATLI DABAR",
        "GSSS PHOOLKAN",
        "GSSS SUCHAN"
    ],
    "Ganaur": [
        "GGSSS DATAULI",
        "GMSSSS GANAUR",
        "GSSS BEGA",
        "GSSS BHIGAN",
        "GSSS GARHI JHANJARA",
        "GSSS KAILANA",
        "GSSS KHERI GUJJAR",
        "GSSS KHUBRU",
        "GSSS PABNERA",
        "GSSS PUGTHALA",
        "GSSS PURKASH",
        "GSSS RAJLU GARHI",
        "GSSS RAJPUR",
        "GSSS SANDAL KALAN",
        "GSSS SANPERA"
    ],
    "Gohana": [
        "CHAUDHARY SHYAM CHAND GSSS LATH",
        "GSSS ANWALI",
        "GSSS BAROTA",
        "GSSS BHAINSWAL KALAN",
        "GSSS GARHI UJALE KHAN",
        "GSSS GOHANA CITY",
        "GSSS RABHRA",
        "GSSS SARAGTHAL"
    ],
    "Kathura": [
        "GGSSS MADINA",
        "GSSS BANWASA",
        "GSSS BHAINSWAN KHURD",
        "GSSS BHAWAR",
        "GSSS KATHURA"
    ],
    "Kharkhoda": [
        "GGSSS KIROLI PAHLADPUR",
        "GGSSS ROHNA",
        "GSSS BIDHLAN",
        "GSSS FARMANA",
        "GSSS GORAR",
        "GSSS KHARKHODA",
        "GSSS MATINDU",
        "GSSS ROHAT",
        "GSSS SAIDPUR"
    ],
    "Mundlana": [
        "GSSS BARODA",
        "GSSS BICHPARI",
        "GSSS BUTANA",
        "GSSS CHIDANA",
        "GSSS JAGSI",
        "GSSS MAHMOODPUR",
        "GSSS MUNDLANA"
    ],
    "Rai": [
        "GGSSS NAHRA",
        "GGSSS NAHRI",
        "GSSS A.P. BAROTA",
        "GSSS ATERNA",
        "GSSS BEESWAMEEL",
        "GSSS GARHI BALA",
        "GSSS JAKHOLI",
        "GSSS JANTI KALAN",
        "GSSS KHEWRA",
        "GSSS KUNDLI"
    ],
    "Sonipat": [
        "GGSSS SONEPAT",
        "GHS MOHANA",
        "GSSS ASADPUR NANDNAUR",
        "GSSS BARWASNI",
        "GSSS BAYANPUR LAHRARA",
        "GSSS BHATGAON",
        "GSSS Garhi Brahaman",
        "GSSS JATWARA",
        "GSSS JUAN",
        "GSSS KAKROI",
        "GSSS KAREWARI",
        "GSSS KUMASPUR",
        "GSSS MAHRA",
        "GSSS MODEL TOWN",
        "GSSS MURTHAL",
        "GSSS TAJPUR"
    ],
    "Bilaspur": [
        "GHS KATHGARH",
        "GMSSSS BILASPUR",
        "GSSS BHERTHAL",
        "GSSS BIHTA",
        "GSSS BILASPUR",
        "GSSS DHANOURA",
        "GSSS FATEHGARH TUMBI",
        "GSSS MACHHROULI",
        "GSSS MUGALWALI",
        "GSSS MUSSIMBAL",
        "GSSS PABNI KALAN"
    ],
    "Chhachhrauli": [
        "GGSSS CHHACHHRAULI",
        "GGSSS KHIZRABAD",
        "GHS KHILLANWALA",
        "GHS MALIKPUR BANGAR",
        "GHS MUNDA KHERA",
        "GHS TAHARPUR KALAN",
        "GSSS Arriyawala",
        "GSSS BAKKARWALA",
        "GSSS CHHACHHRAULI",
        "GSSS CHUHARPUR KALAN",
        "GSSS DEODHAR",
        "GSSS JAIDHAR",
        "GSSS Kalesar",
        "GSSS KHADRI",
        "GSSS KHARWAN",
        "GSSS KHIZRABAD",
        "GSSS KOT KALSIA B",
        "GSSS LAKKAR",
        "GSSS LALHARI KALAN",
        "GSSS LEDI",
        "GSSS SALEMPUR KOHI"
    ],
    "Jagadhri": [
        "GGSSS JAGADHRI",
        "GGSSS SABJI MANDI YNR",
        "GSSS AURANGABAD",
        "GSSS BHATOULI",
        "GSSS BURIA",
        "GSSS Camp",
        "GSSS DAMLA",
        "GSSS Hamida",
        "GSSS HARIPUR KAMBOJ",
        "GSSS HARNAUL",
        "GSSS JAG.W/SHOP",
        "GSSS JAGADHRI(BOYS)",
        "GSSS MANDOLI",
        "GSSS Mehlawali",
        "GSSS Model Colony",
        "GSSS NAGAL",
        "GSSS Sabapur",
        "GSSS SNV MODEL TOWN"
    ],
    "Saraswati Nagar": [
        "GMSSSS MUSTAFABAD",
        "GSSS BHAMBHOL",
        "GSSS GUNDIANA",
        "GSSS JAMALPUR",
        "GSSS KALAWAR",
        "GSSS SARAN",
        "GSSS SIALBA",
        "GSSS TALAKAUR"
    ],
    "Radaur": [
        "GHS BAPA",
        "GHS NACHRON",
        "GSSS ALAHAR",
        "GSSS ANTTAWA",
        "GSSS CHAMRORI",
        "GSSS GHILLOUR",
        "GSSS GUMTAHLA RAO",
        "GSSS JATHLANA",
        "GSSS KHURDBAN",
        "GSSS RADAUR",
        "GSSS TOPRA KALAN"
    ],
    "Sadhaura": [
        "GSSS KOTLA",
        "GSSS LAHARPUR",
        "GSSS SADHAURA",
        "GSSS SALEHPUR",
        "GSSS SARAWAN"
    ]
}

export const districts = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamuna Nagar"
]

export const blocks = [
    "Ambala-I (City)",
    "Ambala-II (Cantt)",
    "Barara",
    "Naraingarh",
    "Saha",
    "Shehzadpur",
    "Bawani Khera",
    "Behal",
    "Bhiwani",
    "Kairu",
    "Loharu",
    "Siwani",
    "Tosham",
    "Badhra",
    "Baund Kalan",
    "Dadri",
    "Ballabgarh",
    "Faridabad",
    "Bhattu Kalan",
    "Bhuna",
    "Fatehabad",
    "Jakhal",
    "Ratia",
    "Tohana",
    "Farukh Nagar",
    "Gurugram",
    "Pataudi",
    "Sohna",
    "Adampur",
    "Agroha",
    "Barwala (Hsr)",
    "Hansi-I",
    "Hansi-II",
    "Hisar-I",
    "Hisar-II",
    "Narnaund",
    "Uklana",
    "Bahadurgarh",
    "Beri",
    "Jhajjar",
    "Matanhail",
    "Salhawas",
    "Alewa",
    "Jind",
    "Julana",
    "Narwana",
    "Pillukhera",
    "Safidon",
    "Uchana",
    "Guhla at Cheeka",
    "Kaithal",
    "Kalayat",
    "Pundri",
    "Rajaund",
    "Siwan",
    "Assandh",
    "Gharaunda",
    "Indri",
    "Karnal",
    "Nilokheri",
    "Nissing at Chirao",
    "Babain",
    "Ladwa",
    "Pehowa",
    "Shahabad",
    "Thanesar",
    "Ateli",
    "Kanina",
    "Mahendergarh",
    "Nangal Choudhary",
    "Narnaul",
    "Ferozepur Jhirka",
    "Nagina",
    "Nuh",
    "Punhana",
    "Taoru",
    "Hassanpur",
    "Hathin",
    "Hodal",
    "Palwal",
    "Barwala (Pkl)",
    "Morni",
    "Pinjore",
    "Raipur Rani",
    "Bapauli",
    "Israna",
    "Madlauda",
    "Panipat",
    "Samalkha",
    "Bawal",
    "Jatusana",
    "Khol at Rewari",
    "Nahar",
    "Rewari",
    "Kalanaur",
    "Lakhan Majra",
    "Meham",
    "Rohtak",
    "Sampla",
    "Baragudha",
    "Dabwali",
    "Ellenabad",
    "Nathusari Chopta",
    "Odhan",
    "Rania",
    "Sirsa",
    "Ganaur",
    "Gohana",
    "Kathura",
    "Kharkhoda",
    "Mundlana",
    "Rai",
    "Sonipat",
    "Bilaspur",
    "Chhachhrauli",
    "Jagadhri",
    "Saraswati Nagar",
    "Radaur",
    "Sadhaura"
]

export const clusters = [
    "GSSS BAKNOUR",
    "GSSS BALDEV NAGAR, A/CITY",
    "GSSS BALLANA",
    "GSSS CHHAPRA",
    "GSSS DURANA",
    "GSSS ISMAILPUR",
    "GSSS JALBERA",
    "GSSS JANSUI",
    "GSSS MAJRI",
    "GSSS MOHRA",
    "GSSS MOHRI BHANOKHERI",
    "GSSS NAGGAL",
    "GSSS NANYOLA",
    "GSSS PREM NAGAR, A/CITY",
    "GSSS SHAHPUR",
    "GSSS BABYAL",
    "GSSS BAKRA MARKET",
    "GSSS BC BAZAR A/CANTT.",
    "GSSS BOH",
    "GSSS GARNALA",
    "GSSS GHEL",
    "GSSS KANWLA",
    "GSSS PANJOKHRA",
    "GSSS RAMBAGH ROAD,A/CANTT",
    "GSSS RAMPUR SARSHERI",
    "GSSS SAPEHRA",
    "GSSS SULTANPUR",
    "GMSSSS BARARA",
    "GSSS ADHOYA",
    "GSSS DHANAURA",
    "GSSS DHEEN",
    "GSSS HOLI",
    "GSSS MANKA-MANKI",
    "GSSS MULLANA",
    "GSSS RAJOKHERI",
    "GSSS TANDWAL",
    "GSSS THAMBER",
    "GSSS UGALA",
    "GSSS ZAFFARPUR",
    "GSSS AKBARPUR",
    "GSSS BARAGAON",
    "GSSS BHARERI KALAN",
    "GSSS BHUREWALA",
    "GSSS DERA",
    "GSSS GADHAULI",
    "GSSS JEOLI",
    "GSSS KANJALA",
    "GSSS KATHEMAJRA",
    "GSSS LAHA",
    "GSSS NAGLA RAJPUTANA",
    "GSSS NARAINGARH",
    "GSSS SHAHPUR NURHAD",
    "GHS TALHERI GUJRAN",
    "GSSS BIHTA",
    "GSSS GOKALGARH",
    "GSSS KALPI",
    "GSSS KESRI",
    "GSSS NAHONI",
    "GSSS PASIALA",
    "GSSS PILKHANI",
    "GSSS SAHA",
    "GSSS SAMBHALKHA",
    "GSSS SAMLEHRI",
    "GGSSS SHAZADPUR",
    "GSSS BARI BASSI",
    "GSSS BHAROG",
    "GSSS DHANANA",
    "GSSS JATWAR",
    "GSSS KAKKARMAJRA",
    "GSSS KARASAN",
    "GSSS KORWAN KHURD",
    "GSSS PATREHRI",
    "GSSS RAIWALI",
    "GSSS SHAZADPUR",
    "GSSS Alakhpura",
    "GSSS BARSI",
    "GSSS BAWANI KHERA",
    "GSSS JAMALPUR",
    "GSSS KUNGAR",
    "GSSS RATERA",
    "GSSS SUI",
    "GHS PAJU",
    "GSSS BEHAL",
    "GSSS BIDHNOI",
    "GSSS CHAHAR KALAN",
    "GSSS MANDHOLI KALAN",
    "GSSS OBRA",
    "GSSS PATWAN",
    "GGSSS BHIWANI",
    "GGSSS DINOD",
    "GGSSS LOHAR BHIWANI CITY",
    "GSSS BAMLA",
    "GSSS BAPORA",
    "GSSS BHIWANI",
    "GSSS CHANG",
    "GSSS DHIRANA",
    "GSSS GOLPURA",
    "GSSS HALUWAS",
    "GSSS KAUNT",
    "GSSS KHARAK KALAN",
    "GSSS KITLANA",
    "GSSS MANADHANA",
    "GSSS MANHERU",
    "GSSS MITATHAL",
    "GSSS MUNDHAL KHURD",
    "GSSS NEEMRIWALI",
    "GSSS PALUWAS",
    "GSSS SAI",
    "GSSS SANGA",
    "GSSS TIGRANA",
    "GSSS DEVRALA",
    "GSSS DHABDHANI",
    "GSSS JUI KHURD",
    "GSSS KAIRU",
    "GSSS KASUMBHI",
    "GSSS LEGHAN",
    "GSSS LOHANI",
    "GSSS SUNGARPUR",
    "GGSSS GAGARWAS",
    "GSSS BARALU",
    "GSSS BARDU CHAINA",
    "GSSS BISALWAS",
    "GSSS BUDHERA",
    "GSSS DHIGAWA JATTAN",
    "GSSS LOHARU",
    "GSSS PAHARI",
    "GSSS SINGHANI",
    "GSSS SOHANSARA",
    "SSCJR GSSS GIGNOW",
    "GGSSS SIWANI",
    "GSSS BARWA",
    "GSSS BHERA",
    "GSSS GURERA",
    "GSSS JHUMPA KHURD",
    "GSSS KALOD GUDHA",
    "GSSS MITHI",
    "GSSS NALOI",
    "GSSS SIWANI MANDI",
    "GGSSS DHANI MAHU",
    "GHS CHAPPAR RANGRAN",
    "GMSSSS TOSHAM",
    "GSSS DULHERI",
    "GSSS ISHARWAL",
    "GSSS KHANAK",
    "GSSS KHARKARI JHANVARI",
    "GSSS MIRAN",
    "GSSS PATODI",
    "GSSS RODHAN",
    "GGSSS BADHRA",
    "GHS RAMBASS",
    "GSSS BADHRA",
    "GSSS BERLA",
    "GSSS CHANDWAS",
    "GSSS DOHKA MAUJI",
    "GSSS DWARKA",
    "GSSS HARODI",
    "GSSS KADMA",
    "GSSS KAKROLI SARDARA",
    "GSSS KARI DHARNI",
    "GSSS PICHOPA KALAN",
    "GGSSS DHANI PHOGAT",
    "GSSS BAUND KALAN",
    "GSSS BHAGESHWARI",
    "GSSS IMLOTA",
    "GSSS MAURI",
    "GSSS MISRI",
    "GSSS RANILA",
    "GSSS SAMASPUR",
    "GSSS SANJARWAS",
    "GSSS SANWAR",
    "GSSS SARUPGARH SANTOR",
    "GGSSS CH. DADRI",
    "GGSSS CHARKHI",
    "GSSS ADAMPUR DADHI",
    "GSSS ATELA",
    "GSSS BIRHI KALAN",
    "GSSS CH. DADRI",
    "GSSS CHAPPAR",
    "GSSS CHIRIYA",
    "GSSS JAWA",
    "GSSS JHOJHU KALAN",
    "GSSS MANDOLA",
    "GSSS N.B. Rajputtan",
    "GSSS PAINTAWAS KALAN",
    "GHS GHARORA",
    "GHS SAGARPUR",
    "GMSSSS FARIDABAD SECTOR 55",
    "GSSS ARUA",
    "GSSS BALLABGARH",
    "GSSS DAYALPUR",
    "GSSS FATEHPUR BILLOCH",
    "GSSS FATEHPUR TAGGA",
    "GSSS JHARSAINTLY",
    "GSSS KAURALI",
    "GSSS MIRTZAPUR",
    "GSSS MOHNA",
    "GSSS PANHERA KHURD",
    "GSSS SAMAYPUR",
    "GSSS SEC7 SIHI",
    "GSSS SEEKRI",
    "GSSS UNCHAGAON",
    "GHS NANGLA GUJRAN",
    "GSSS AJRONDA",
    "GSSS ANANGPUR",
    "GSSS BHUPANI",
    "GSSS DHAUJ",
    "GSSS FARIDABAD OLD",
    "GSSS JASANA",
    "GSSS KHERI KALAN",
    "GSSS M.M.PUR",
    "GSSS NIT NO.1",
    "GSSS NIT NO.3",
    "GSSS PALI",
    "GSSS SARAI KHAWAJA",
    "GSSS SARAN",
    "GSSS SEHATPUR",
    "GSSS TIGAON",
    "GSSS BHATTU KALAN",
    "GSSS BODIWALI",
    "GSSS KHABRA KALAN",
    "GSSS KIRDHAN",
    "GSSS MEHUWALA",
    "GSSS PILI MANDORI",
    "GSSS RAMSARA",
    "GSSS SEKHUPUR DAROLI",
    "GSSS BHUNA",
    "GSSS BUWAN",
    "GSSS DHOLU",
    "GSSS GORAKHPUR",
    "GSSS JANDLI KALAN",
    "GSSS LEHRIAN",
    "GSSS NEHLA",
    "SSMS GGSSS SANIANA",
    "GGSSS FATEHABAD",
    "GHS AHLI NOORKI MAJRA",
    "GSSS AYALKI",
    "GSSS BADOPAL",
    "GSSS BANGAON",
    "GSSS BHATTU KHURD",
    "GSSS BHIRDANA",
    "GSSS BIGHAR",
    "GSSS CHINDER",
    "GSSS DARIYAPUR",
    "GSSS FATEHABAD",
    "GSSS HIZRAWAN KHURD",
    "GSSS JHALNIA",
    "GSSS KHARA KHERI",
    "GSSS M.P.ROHI",
    "GSSS NAGPUR",
    "GSSS CHANDPURA",
    "GSSS JAKHAL MANDI",
    "GSSS KARANDI",
    "GSSS MEYOND KALAN",
    "GSSS SHAKARPURA",
    "GSSS SIDHANI",
    "GSSS AHERWAN",
    "GSSS ALAWALWAS",
    "GSSS BALIALA",
    "GSSS HAROLI",
    "GSSS HASANGA",
    "GSSS LAMBA",
    "GSSS MADH",
    "GSSS MAHMRA",
    "GSSS MP SOTTER",
    "GSSS NANGAL",
    "GSSS RATIA",
    "GSSS RATTA KHERA",
    "GMSSSS INDACHHOI",
    "GSSS DHARSUL KALAN",
    "GSSS GAJUWALA",
    "GSSS HAIDERWALA",
    "GSSS JAMALPUR SHEKHAN",
    "GSSS KANHERI",
    "GSSS PARTA",
    "GSSS TOHANA",
    "GSSS TOHANA MANDI",
    "GSSS BHANGROLA",
    "GSSS F NAGAR",
    "GSSS F NAGAR-G",
    "GSSS JAMAL PUR",
    "GSSS JATOLA",
    "GSSS JONIAWAS",
    "GSSS Karola",
    "GSSS KHANDEWLA",
    "GSSS KHENTAWAS",
    "GSSS MOKALWAS",
    "GSSS PATLI",
    "GSSS SIWARI",
    "GGSSS JACOBPURA",
    "GMSSS SANSKRITI SCHOOL SEC-43",
    "GSSS BASAI",
    "GSSS BHANDWARI",
    "GSSS BHIMGARH KHERI",
    "GSSS CARTERPURI",
    "GSSS CHAKKARPUR",
    "GSSS DAULTABAD",
    "GSSS DHANKOT",
    "GSSS GARHI HARSARU",
    "GSSS ISLAMPUR",
    "GSSS JHARSA",
    "GSSS KADIPUR",
    "GSSS KASAN",
    "GSSS KHANDSA",
    "GSSS KHERKI DAULA",
    "GSSS MANESAR",
    "GSSS NAWADA F.PUR",
    "GSSS SARHAUL",
    "GSSS SEC-4/7",
    "GSSS SHIKOHPUR",
    "GSSS WAZIRABAD",
    "GGSSS HAILYMANDI",
    "GGSSS PATAUDI",
    "GSSS BASPADAMKA",
    "GSSS BHORA KALAN",
    "GSSS KHALILPUR",
    "GSSS KHOR",
    "GSSS LOKRA",
    "GSSS NANU KALAN",
    "GSSS NOORGARH",
    "GSSS PANCHGAON",
    "GSSS PATAUDI",
    "GSSS PATHERHERI",
    "GSSS SHERPUR",
    "GSSS SIDHARWALI",
    "GSSS UNCHA MAJRA",
    "GGSSS Badshahpur",
    "GSSS ABHEYPUR",
    "GSSS DARBARIPUR",
    "GSSS DAULAH",
    "GSSS GHAMROJ ALIPUR",
    "GSSS GHANGOLA",
    "GSSS HARCHANDPUR",
    "GSSS HAZIPUR",
    "GSSS KADARPUR",
    "GSSS KHERLA",
    "GSSS KHERLI LALA",
    "GSSS SOHNA-B",
    "GSSS SOHNA-G",
    "GSSS TEEKLI",
    "SHAHEED SUBEDAR KUMARPAL SINGH RAGHAV GSSS BHONDSI",
    "GSSS ADAMPUR",
    "GSSS ASRAWAN",
    "GSSS DAROLI",
    "GSSS GHURSAL",
    "GSSS KOHLI",
    "GSSS MODAKHERA",
    "GSSS MOHABATPUR",
    "GSSS SADALPUR",
    "GSSS SISWAL",
    "GGSSS AGROHA",
    "GHS BHANA",
    "GSSS DURJANPUR",
    "GSSS KALIRAWAN",
    "GSSS KIRMARA",
    "GSSS KULERI",
    "GSSS LANDHARI",
    "GSSS NANGTHALA",
    "GGSSS BARWALA",
    "GSSS ANAJ MANDI",
    "GSSS BADHWAR",
    "GSSS BALAK",
    "GSSS BARWALA",
    "GSSS BHABALPUR",
    "GSSS BIYANA KHERA",
    "GSSS DHANI KHAN BHADUR",
    "GSSS GABIPUR",
    "GSSS JUGLAN",
    "GSSS KHARAK PUNIA",
    "GSSS KHEDAR",
    "GSSS MATLODA",
    "GSSS PANIHARI",
    "GSSS RAJLI",
    "GSSS SARSOD BICHPARI SCHOOL",
    "GGSSS GHIRAI",
    "GGSSS Hansi",
    "GGSSS NRM GARHI",
    "GHS HAZAMPUR",
    "GMSSSS SISAI",
    "GSSS BHATLA",
    "GSSS DATTA",
    "GSSS DHANA KHURD",
    "GSSS DHANDHERI",
    "GSSS HANSI",
    "GSSS JAMAWARI",
    "GSSS PUTHI MANGAL KHAN",
    "GSSS RAMPURA GAON",
    "GSSS SINGHWA RAGHO",
    "GSSS SORKHI",
    "GSSS UMRA",
    "GGSSS KHARBLA KHERA",
    "GSSS BASS",
    "GSSS BHATOL KHARKARA",
    "GSSS KHANDAKHERI",
    "GSSS PUTHI SAMAIN",
    "GSSS SINGHWA KHAS",
    "GSSS UGALAN",
    "GGSSS BADYA BRAHMANAN",
    "GGSSS GANGWA",
    "GGSSS MANGALI",
    "GSSS CHIROD",
    "GSSS DABRA",
    "GSSS GANGWA",
    "GSSS HISAR",
    "GSSS HISAR MODEL TOWN",
    "GSSS MANGALI",
    "GSSS MAYYAR",
    "GSSS MIRJAPUR",
    "GSSS NALWA",
    "GSSS NIANA",
    "GSSS PATEL NAGAR HISAR",
    "GSSS SAHARWA",
    "GSSS SATROD KHURD",
    "GSSS SHIKARPUR",
    "GSSS TALWANDI RANA",
    "GSSS TALWANDI RUKKA",
    "GGSSS HISAR",
    "GSSS ARYANAGAR",
    "GSSS BALSAMAND",
    "GSSS BANDAHERI",
    "GSSS BHIWANI ROHILLAN",
    "GSSS CHOUDHARIWAS",
    "GSSS D BLOCK",
    "GSSS DHANI PEERANWALI",
    "GSSS DOBHI",
    "GSSS JAKHOD KHERA",
    "GSSS KALWAS",
    "GSSS KHARIA",
    "GSSS Kirtan",
    "GSSS MUKLAN",
    "GSSS NEOLI KALAN",
    "GSSS NEOLI KHURD",
    "GSSS RAWALWAS KALAN",
    "GSSS SARSANA",
    "GGSSS KHERI JALAB CHANDRO DEVI",
    "GSSS BHAINI AMIRPUR",
    "GSSS KAPRO",
    "GSSS KOTH KALAN",
    "GSSS LOHARI RAGHO",
    "GSSS MIRCH PUR",
    "GSSS NARNAUND",
    "GSSS PETWAR",
    "GSSS RAKHI SHAHPUR",
    "GGSSS UKLANA MANDI",
    "GSSS BITHMARA",
    "GSSS CHAMAR KHERA",
    "GSSS DAULATPUR",
    "GSSS KINALA",
    "GSSS LITANI",
    "GSSS PABRA",
    "GSSS PARBHUWALA",
    "GSSS UKLANA MANDI",
    "GGSSS NUNA MAJRA",
    "GSSS ASAUDHA TODRAN",
    "GSSS BADLI",
    "GSSS BAHADURGARH",
    "GSSS BUPANIA",
    "GSSS CHHARA",
    "GSSS DULHERA",
    "GSSS KANONDA",
    "GSSS LUKSAR",
    "GSSS MATAN",
    "GSSS MUNDA KHERA",
    "GSSS PARNALA",
    "GSSS ROHAD",
    "GSSS SAN KHOL",
    "GSSS ACHHEJ PAHARIPUR",
    "GSSS BERI",
    "GSSS DHANDLAN",
    "GSSS DIGHAL",
    "GSSS MADANA KALAN",
    "GSSS MAJRA D",
    "LSL GSSS JAHAJGARH",
    "GGSSS SILANA",
    "GSSS DADRI TOE",
    "GSSS DAWLA",
    "GSSS DUJANA",
    "GSSS JAHANGIRPUR",
    "GSSS JHAJJAR",
    "GSSS KABLANA",
    "GSSS MACHHROLI",
    "GSSS PATAUDA",
    "GSSS PELPA",
    "GSSS TALAO",
    "GMSSSS LADAIN",
    "GSSS BAHU",
    "GSSS CHHUCHAKWAS",
    "GSSS KHACHHRAULI",
    "GSSS KHANPUR KHURD",
    "GSSS KHETAWAS",
    "GSSS MATANHAIL",
    "GSSS SASRAULI",
    "GSSS BHURAWAS",
    "GSSS DHAKLA",
    "GSSS KASNI",
    "GSSS KHUDAN",
    "GSSS SUBANA",
    "GMSSS NAGURAN",
    "GSSS ALEWA",
    "GSSS DAHOLA",
    "GSSS PEGAN",
    "GSSS RAI CHAND WALA",
    "GGSSS SINDHVI KHERA",
    "GMSSS Defence Colony Jind",
    "GSSS AHIRKA",
    "GSSS BISHANPURA",
    "GSSS DALAMWALA",
    "GSSS GHIMANA",
    "GSSS IKKAS",
    "GSSS JHANJ KALAN",
    "GSSS KANDELA",
    "GSSS KHARAK RAMJI",
    "GSSS MANOHARPUR",
    "GSSS NIDANI",
    "GSSS RAM RAI",
    "GSSS SANGATPURA",
    "GMSSS JULANA",
    "GSSS Fatehgarh",
    "GSSS GARHWALI KHERA",
    "GSSS GATAULI",
    "GSSS KILA ZAFARGARH",
    "GSSS KINANA",
    "GSSS LAJWANA KALAN",
    "GSSS SHAMLO KALAN",
    "GMSSSS BELARKHA",
    "GSSS AMARGARH",
    "GSSS BHANA BRAHMANAN",
    "GSSS DANODA  KALAN",
    "GSSS DHAMTAN SAHIB",
    "GSSS Frain Kalan",
    "GSSS NARWANA",
    "GSSS PHULIAN KALAN",
    "GSSS PIPALTHA",
    "GSSS SINGHOWAL",
    "GSSS Ujhana",
    "GSSS BUDHA KHERA",
    "GSSS DHATRATH",
    "GSSS Gangoli",
    "GSSS MORKHI",
    "GSSS PILLU KHERA MANDI",
    "GSSS RETAULI",
    "GSSS BHUSLANA",
    "GSSS KARKHANA",
    "GSSS MUANA",
    "GSSS PAJU KALAN",
    "GSSS SAFIDON CITY",
    "GSSS SIWANA MAL",
    "GGSSS DUMERKHA KALAN",
    "GSSS BADANPUR",
    "GSSS BARODA",
    "GSSS CHHATTAR",
    "GSSS KABARCHHA",
    "GSSS Kakrod",
    "GSSS Karsindhu",
    "GSSS Kasuhan",
    "GSSS UCHANA KALAN",
    "GSSS UCHANA KHURD",
    "GSSS AGONDH",
    "GSSS ARNOULI",
    "GSSS BALBEHRA",
    "GSSS BHAGAL",
    "GSSS CHEEKA",
    "GSSS DABA",
    "GSSS GAGARPUR",
    "GSSS GUHLA",
    "GSSS PEEDAL",
    "GSSS SEON MAJRA",
    "GGSSS DEOBAN",
    "GGSSS KAITHAL",
    "GMSSSS KEORAK",
    "GSSS CHANDANA",
    "GSSS DIWAL",
    "GSSS GEONG",
    "GSSS HARSOLA",
    "GSSS KAITHAL",
    "GSSS PADLA",
    "GSSS PATTI AFGAN",
    "GSSS SANGHAN",
    "GSSS SHERGARH",
    "GSSS TEEK",
    "GSSS CHOUSHALA",
    "GSSS DHUNDWA KOLEKHAN",
    "GSSS KAILRAM",
    "GSSS KALAYAT",
    "GSSS KAMALPUR",
    "GSSS KHADALWA",
    "GSSS KURAR",
    "GSSS BAKAL",
    "GSSS DHAND",
    "GSSS HABRI",
    "GSSS KAUL",
    "GSSS PABNAWA",
    "GSSS PAI",
    "GSSS PHARAL",
    "GSSS PUNDRI",
    "GSSS RASINA",
    "GSSS SAKRA",
    "GSSS TEONTHA",
    "GHS KOTRA",
    "GSSS BHANA",
    "GSSS JAKHOULI",
    "GSSS KITHANA",
    "GSSS RAJOUND",
    "GSSS ROHERA",
    "GSSS SERHADA",
    "GGSSS SIWAN",
    "GSSS KANGTHALI",
    "GSSS KAWARTAN",
    "GSSS KHARAK",
    "GSSS KHERI GULAM ALI",
    "GSSS LADANA CHAKKU",
    "GSSS SIWAN",
    "GSSS ARDANA",
    "GSSS ASSANDH",
    "GSSS BAHRI",
    "GSSS BALLAH",
    "GSSS CHOCHRA",
    "GSSS G.T. POPRAN",
    "GSSS GOLI",
    "GSSS JALMANA",
    "GSSS KHERISARFALI",
    "GSSS PADHA",
    "GSSS PHAPHRANA",
    "GSSS RAHRA",
    "GSSS RATTAK",
    "GSSS SALWAN",
    "GSSS UPLANA",
    "GSSS ARIANPURA",
    "GSSS BAL RANGRAN",
    "GSSS BARSAT",
    "GSSS CHAURA",
    "GSSS DEVIPUR",
    "GSSS FARIDPUR",
    "GSSS GAGSINA",
    "GSSS GARHI KHAJOOR",
    "GSSS GHARAUNDA",
    "GSSS KAIMLA",
    "GSSS KOHAND",
    "GSSS KUTAIL",
    "GSSS KUTANA",
    "GSSS MUNAK",
    "GSSS PHURLAK",
    "GGSSS INDRI",
    "GHS BHOJI KHALSA",
    "GHS HANORI",
    "GHS MURADGARH",
    "GHS UDANA",
    "GSSS BHADSON",
    "GSSS BIANA",
    "GSSS BIBIPUR JATTAN",
    "GSSS DABKOLI KALAN",
    "GSSS GARHI BIRBAL",
    "GSSS GARHI JATTAN",
    "GSSS INDRI (BOYS)",
    "GSSS KALRI JAGIR",
    "GSSS KALSOURA",
    "GSSS KHANPUR",
    "GSSS KHERI MAAN SINGH",
    "GSSS NAGLA RORAN",
    "GSSS PATHERA",
    "GGSSS PREM NAGAR KARNAL",
    "GGSSS RLY. ROAD, KARNAL",
    "GSSS DABARKI KALAN",
    "GSSS DAHA",
    "GSSS DARAR",
    "GSSS DHAKWALA GUJRAN",
    "GSSS GHEER",
    "GSSS KACHHWA",
    "GSSS KARNAL",
    "GSSS KUNJPURA",
    "GSSS MANGALPUR",
    "GSSS MODEL TOWN KARNAL",
    "GSSS MOHIUDINPUR",
    "GSSS SANGOHA",
    "GSSS SHEIKHPURA SOHANA",
    "GSSS SUBHRI",
    "GSSS TIKRI",
    "GSSS UCHANA",
    "GSSS UNCHA SAMANA",
    "GMSSSS TARAORI",
    "GSSS ANJANTHALI",
    "GSSS BHAINI KHURD",
    "GSSS KOER MAJRA",
    "GSSS MOHRI JAGIR",
    "GSSS NIGDHU",
    "GSSS NILOKHERI",
    "GSSS PADHANA",
    "GSSS RAISON",
    "GSSS SAGGA",
    "GSSS SAMANA BAHU",
    "GSSS SAMBHI",
    "GSSS SANWAT",
    "GSSS SHAMGARH",
    "GSSS SIKRI",
    "GSSS TARAORI",
    "GSSS BASTLI",
    "GSSS BRASS",
    "GSSS CHIRAO",
    "GSSS DACHER",
    "GSSS GONDER",
    "GSSS JUNDLA",
    "GSSS KATLAHERI",
    "GSSS KHERI NARU",
    "GSSS MANJURA",
    "GSSS NISSING",
    "GHS BIR KALWA",
    "GHS KASITHAL",
    "GSSS BABAIN",
    "GSSS MANGOLI JATTAN",
    "GGSSS BARAUT",
    "GGSSS LADWA",
    "GSSS BANI",
    "GSSS BARAUNDA",
    "GSSS GUDHA",
    "GSSS KHAIRI",
    "GSSS LADWA",
    "GSSS LOHARA",
    "GSSS MEHRA",
    "GSSS PRAHLADPUR",
    "GGSSS PEHOWA",
    "GSSS BAKHLI",
    "GSSS BODHNI",
    "GSSS GUMTHALA GARU",
    "GSSS HARIGARH BHORAKH",
    "GSSS KALSA",
    "GSSS MURTZAPUR",
    "GSSS PEHOWA",
    "GSSS SANDHOLI",
    "GSSS SARSA",
    "GSSS SEONSAR",
    "GSSS THANA",
    "GSSS THASKA MIRANJI",
    "GSSS URNAICHA",
    "SHAHID JASWINDER SINGH GSSS TALHERI",
    "GHS SURAKHPUR",
    "GMSSSS ISMAILABAD",
    "GSSS CHARUNI JATTAN",
    "GSSS HARIPUR",
    "GSSS JHANDHERI",
    "GSSS JHANSA",
    "GSSS KALSANA",
    "GSSS KHARINDWA",
    "GSSS LANDI",
    "GSSS NALVI",
    "GSSS RAWA",
    "GSSS SAMBALKHI",
    "GSSS SHAHBAD",
    "GSSS SHARIFGARH",
    "GSSS THOL",
    "GGSSS PARTAPGARH",
    "GGSSS THANESAR",
    "GHS Lukhi",
    "GHS TIGRI KHALSA",
    "GMSSSS KURUKSHETRA",
    "GSSS AJRANA KALAN",
    "GSSS AMIN",
    "GSSS BALAHI",
    "GSSS BEHOLI",
    "GSSS BHIWANI KHERA",
    "GSSS BHUSTHALA",
    "GSSS DEVIDASPURA",
    "GSSS DHURALA",
    "GSSS DUDHLA MORTHLA",
    "GSSS HATHIRA",
    "GSSS JYOTISAR",
    "GSSS KAMODA",
    "GSSS KANIPLA",
    "GSSS MATHANA",
    "GSSS MIRZAPUR",
    "GSSS PIPLI",
    "GSSS UMRI",
    "GGSSS GUJJARWAS",
    "GHS TAJPUR",
    "GHS TIGRA",
    "GSSS ATELI",
    "GSSS BACHHOD",
    "GSSS BIHALI",
    "GSSS BOCHARIA",
    "GSSS NEERPUR",
    "GSSS RATTA KALAN",
    "GSSS SIHMA",
    "GSSS SILARPUR",
    "Shaheed Man Singh GSSS Kheri Kanti",
    "GSSS BAGHOT",
    "GSSS BAWANIA",
    "GSSS BEWAL",
    "GSSS BHOJAWAS",
    "GSSS BUCHAWAS",
    "GSSS DHANAUNDA",
    "GSSS DONGARA AHIR",
    "GSSS KANINA",
    "GSSS KHERI TALWANA",
    "GSSS NANGAL MOHANPUR",
    "GSSS Pathera",
    "GSSS RAMBASS",
    "GSSS SEHORE",
    "GGSSS MAHENDERGARH",
    "GHS BHAGDANA",
    "GMSSSS MAHENDERGARH",
    "GSSS AKODA",
    "GSSS BAIRAWAS",
    "GSSS BUDIN",
    "GSSS DIGROTA",
    "GSSS DULANA",
    "GSSS JARWA",
    "GSSS KHATODRA",
    "GSSS KHUDANA",
    "GSSS KOTHAL KALAN",
    "GSSS MALRA BASS",
    "GSSS NANWAN",
    "GSSS NIMBI",
    "GSSS PATHARWA",
    "GSSS SATNALI",
    "GSSS SURETI PILANIA",
    "GSSS BANIHARI",
    "GSSS BAYAL",
    "GSSS BHUNGKHARKA",
    "GSSS BUDHWAL",
    "GSSS DHOLERA",
    "GSSS GANWARI JAT",
    "GSSS KAMANIA",
    "GSSS KANWI",
    "GSSS MANDI",
    "GSSS NANGAL CHOUDHARY",
    "GSSS NANGAL DARGU",
    "GSSS NAYAN",
    "GSSS NIYAMANTPUR",
    "GSSS SEKA",
    "GSSS SHAHBAJPUR",
    "GSSS SIROHI BAHALI",
    "GHS NANGAL KATHA",
    "GMSSS NARNAUL",
    "GSSS A/MOKHUTA",
    "GSSS DOCHANA",
    "GSSS GEHLI",
    "GSSS HUDINA",
    "GSSS JAILAF",
    "GSSS JORASI",
    "GSSS KORIAWAS",
    "GSSS KRISHAN NAGAR",
    "GSSS MAROLI",
    "GSSS NIWAZ NAGAR",
    "GSSS NIZAMPUR",
    "GSSS PATIKRA",
    "GGSSS AHMAD BAS",
    "GGSSS F.P. JHIRKA",
    "GHS BUBALHERI",
    "GHS DOHA",
    "GHS MAHOON",
    "GHS NEEMKHERA",
    "GSSS AGON",
    "GSSS BADED",
    "GSSS BHAKROJI",
    "GSSS BIWAN",
    "GSSS F.P. JHIRKA",
    "GSSS PATHKHORI",
    "GSSS RANIYALA",
    "GSSS RAWLI",
    "GSSS SAKRAS",
    "GGSSS NAGINA",
    "GHS BASAI KHANZADA",
    "GHS BHADAS",
    "GHS MARORA",
    "GHS RANIYALA PATAKPUR",
    "GHS UMRA",
    "GSSS BADARPUR",
    "GSSS BAZIDPUR",
    "GSSS MANDIKHERA",
    "GSSS MOOLTHAN",
    "GSSS NAGINA",
    "GHS BIBIPUR",
    "GHS KURTHALA",
    "GMSSSS NUH",
    "GSSS  KK PUR",
    "GSSS  Ujina",
    "GSSS ADBAR",
    "GSSS ATTA BAROTA",
    "GSSS CHHAPERA",
    "GSSS DEWLA NANGLI",
    "GSSS FEROZPUR NAMAK",
    "GSSS GHASERA",
    "GSSS INDRI",
    "GSSS KAIRAKA",
    "GSSS KANWARSHIKA",
    "GSSS KHORE BASAI",
    "GSSS MALAB",
    "GSSS MEOLI KALAN",
    "GSSS REWASAN",
    "GSSS Tapkan",
    "GGSSS PINAGWAN",
    "GGSSS PUNHANA",
    "GMSSSS SIROLI",
    "GSSS BICHORE",
    "GSSS BISRU",
    "GSSS DUDOLI",
    "GSSS HATHAN GAON",
    "GSSS JAMALGARH",
    "GSSS LOHINGA KALAN",
    "GSSS NAI",
    "GSSS NIWANA",
    "GSSS OTHA",
    "GSSS PINANGWAN",
    "GSSS PUNHANA",
    "GSSS RAIPUR",
    "GSSS SHAHCHOKHA",
    "GSSS SHIKRAWA",
    "GSSS SINGAR",
    "GGSSS TAORU",
    "GHS PADHENI",
    "GSSS BISSAR AKBAR PUR",
    "GSSS CHHARORA",
    "GSSS DHULAWAT",
    "GSSS GURNAWAT",
    "GSSS HASANPUR",
    "GSSS JAURASI",
    "GSSS KALWARI",
    "GSSS M.P. AHIR",
    "GSSS PATUKA",
    "GSSS RAHERI",
    "GSSS RATHIWAS",
    "GSSS Subaseri",
    "GSSS SUNDH",
    "GSSS TAORU",
    "GGSSS HASSANPUR",
    "GSSS BARAULI",
    "GSSS GULAWAD",
    "GSSS HASSANPUR",
    "GSSS KHANBI",
    "GSSS KUSHAK",
    "GSSS PINGOR",
    "GGSSS BAHIN",
    "GGSSS HATHIN",
    "GHS HURITHAL",
    "GSSS ALI MEO",
    "GSSS CHHAINSA",
    "GSSS GEHLAB",
    "GSSS GHARROT",
    "GSSS HATHIN",
    "GSSS MALAI",
    "GSSS MANDKOLA",
    "GSSS UTTAWAR",
    "GGSSS AURANGABAD",
    "GGSSS BHIDUKI",
    "GSSS AURANGABAD",
    "GSSS BAMNIKHERA",
    "GSSS BANCHARI",
    "GSSS BHULWANA",
    "GSSS DEEGHOT",
    "GSSS HODAL",
    "GSSS SIHA",
    "GSSS SONDHAD",
    "GGSSS ALAWALPUR",
    "GGSSS PALWAL CAMP",
    "GGSSS PALWAL CITY",
    "GMSSSS DHATIR",
    "GSSS AHARWAN",
    "GSSS ALAWALPUR",
    "GSSS ALLIKA",
    "GSSS AMARPUR",
    "GSSS ASAWTA",
    "GSSS ASOATI",
    "GSSS BAGPUR",
    "GSSS BASANTGARH",
    "GSSS CHANDHUT",
    "GSSS DUDOLA",
    "GSSS GHORI",
    "GSSS JAINDAPUR",
    "GSSS KARNA",
    "GSSS PALWAL",
    "GSSS PIRTHLA",
    "GSSS TIKRI BRAHMAN",
    "GMSSSS BATOUR",
    "GSSS BUNGA",
    "GSSS KHATAULI",
    "GSSS KOT",
    "GSSS PARWALA",
    "GSSS RAMGARH",
    "GSSS RATTEWALI",
    "GMSSSS MORNI",
    "GSSS BALDWALA",
    "GSSS Bhuri",
    "GSSS KOTI",
    "GSSS MANDHANA",
    "GSSS THANDOG",
    "GSSS Tikkar Hills",
    "GMSSS (SANSKRITI) SEC-20, PKL",
    "GMSSS (SANSKRITI) SEC-26, PKL",
    "GMSSSS (SARTHAK) SEC-12 A, PKL",
    "GSSS BARGODAM",
    "GSSS BASOULAN",
    "GSSS BHORIAN",
    "GSSS CHIKEN",
    "GSSS DHATOGRA",
    "GSSS GHAGGARBIR",
    "GSSS KALKA",
    "GSSS Karanpur",
    "GSSS MALLAH",
    "GSSS NANAKPUR",
    "GSSS Nava Nagar",
    "GSSS PAPLOHA",
    "GSSS PINJORE",
    "GSSS RAJJIPUR",
    "GSSS SEC-19 PKL",
    "GSSS SEC-6 PKL",
    "GSSS SEC-7 PKL",
    "GSSS SUKETRI",
    "GHS MAULI",
    "GSSS BHOOD",
    "GSSS GARHI KOTAHA",
    "GSSS HANGOLA",
    "GSSS RAIPUR RANI",
    "GSSS RAMPUR THADEON",
    "GSSS TODA",
    "GGSSS BAPOLI",
    "GSSS CHHAJPUR KALAN",
    "GSSS GARHI BASIC",
    "GSSS GOELA KHURD",
    "GSSS JALALPUR-1",
    "GSSS SANOLI KHURD",
    "GSSS UJHA",
    "GSSS BUANA LAKHU",
    "GSSS DAHAR",
    "GSSS ISRANA",
    "GSSS KURANA",
    "GSSS NAULTHA",
    "GSSS LOHARI",
    "GSSS MADLAUDA",
    "GSSS NARA",
    "GSSS RAIR KALAN",
    "GSSS SHODAPUR",
    "GSSS SITHANA",
    "GSSS URLANA KALAN",
    "GGSSS M.TOWN",
    "GGSSS SHIV NAGAR PANIPAT",
    "GMSSSS G T ROAD PANIPAT",
    "GSSS BABAIL",
    "GSSS CHANDOLI",
    "GSSS JATTAL",
    "GSSS JHATTIPUR",
    "GSSS KABRI",
    "GSSS KRISHAN PURA",
    "GSSS RAJAKHERI",
    "GSSS SIWAH",
    "GSSS TEHSIL CAMP",
    "GSSS UGRAKHERI",
    "GSSS  MACCHRAULI",
    "GSSS CHULKANA",
    "GSSS HATHWALA",
    "GSSS KIWANA",
    "GSSS MANANA",
    "GSSS PATTIKALYANA",
    "GSSS RAKSERA",
    "GSSS SAMALKHA",
    "GGSSS BAWAL",
    "GSSS ASIAKI PANCHORE SAMPLI",
    "GSSS BAGTHALA",
    "GSSS BALAWAS",
    "GSSS BANIPUR",
    "GSSS BAWAL",
    "GSSS GARHI BOLNI",
    "GSSS GUJAR MAJRI",
    "GSSS JHABUA",
    "GSSS MOHANPUR",
    "GSSS NANGAL SHAHBAJ PUR",
    "GSSS NANGAL TEJU",
    "GSSS PRANPURA",
    "GSSS RAJGARH",
    "GSSS SANGWARI",
    "GSSS SULKHA",
    "GSSS ASIAKI GORAWAS",
    "GSSS BERLI KHURD",
    "GSSS BORIA KAMALPUR",
    "GSSS D.G. PURA",
    "GSSS Dahina Gohtra Tappa",
    "GSSS DARAULI",
    "GSSS JATUSANA",
    "GSSS KANWALI",
    "GSSS MOTLA KALAN",
    "GSSS NANGAL PATHANI",
    "GSSS PALHAWAS",
    "GSSS SUMA KHERA",
    "SAHEED RAJENDER SINGH GSSS MASTAPUR",
    "GHS MUNDI",
    "GSSS KHOL",
    "GSSS KHORI",
    "GSSS KUND",
    "GSSS Mayan",
    "GSSS MM BHALKHI",
    "GSSS NANGAL JAMALPUR",
    "GSSS Pithrawas",
    "GSSS BAWWA",
    "GSSS GUDIANI",
    "GSSS JHAL",
    "GSSS JHARODHA",
    "GSSS KOSLI",
    "GSSS LOOKHI",
    "GSSS LULA AHIR",
    "GSSS NAHAR",
    "GSSS RATTANTHAL",
    "GGSSS MAJRA SHEORAJ",
    "GGSSS REWARI",
    "GMSSSS TATARPUR ISTMURAR",
    "GSSS BHARAWAS",
    "GSSS BHUDPUR",
    "GSSS BIKANER",
    "GSSS BITHWANA",
    "GSSS DHARUHERA",
    "GSSS HANSAKA",
    "GSSS KAKORIA",
    "GSSS KAPRIWAS",
    "GSSS MASANI",
    "GSSS NANDRAMPUR BASS",
    "GSSS NANGLI GODHA",
    "GSSS SAHARANWAS",
    "GSSS SHAHBAJPUR PADIAWAS",
    "GSSS TURKIAWAS",
    "GGSSS KALANAUR",
    "GMSSSS KALANAUR",
    "GSSS BANIYANI",
    "GSSS KAHNAUR",
    "GSSS LAHLI",
    "GSSS NIGANA",
    "GSSS SUNDANA",
    "GSSS GHARAWATHI",
    "GSSS KHARAK JATAN",
    "GSSS LAKHAN MAJRA",
    "GSSS TITOLI",
    "GGSSS MEHAM",
    "GSSS AJAIB",
    "GSSS FARMANA",
    "GSSS Madina",
    "GSSS MEHAM",
    "GSSS MOKHRA",
    "GSSS NINDANA",
    "GSSS SAIMAN",
    "GGSSS BOHAR",
    "GGSSS GANDHI NAGAR, ROHTAK",
    "GGSSS MODEL TOWN, ROHTAK",
    "GGSSS ROHTAK",
    "GMSSSS SANGHI",
    "GSSS BAHU AKBARPUR",
    "GSSS BHAGWATIPUR",
    "GSSS BHALOUT",
    "GSSS BRAHMANWAS",
    "GSSS CHAMARIAN",
    "GSSS GANDHI NAGAR, ROHTAK",
    "GSSS HUMAYUNPUR",
    "GSSS KHIDWALI",
    "GSSS KILOI",
    "GSSS MAKROLI KALAN",
    "GSSS RITOLI",
    "GSSS ROHTAK",
    "GGSSS HASSANGARH",
    "GSSS DATTAUR",
    "GSSS ISMAILA",
    "GSSS KHARAWAR",
    "GSSS PAKASMA",
    "GSSS SAMCHANA",
    "GSSS SAMPLA",
    "GHS KHAI SHERGARH",
    "GHS MATTAR",
    "GHS PANJUANA",
    "GSSS ALIKAN",
    "GSSS BARAGUDHA",
    "GSSS BUPP",
    "GSSS JHORAR ROHI",
    "GSSS NEJADELA KHURD",
    "GSSS PHAGGU",
    "GSSS RAGHUANA",
    "GSSS RORI",
    "GSSS SUKHCHAIN",
    "GGSSS DABWALI",
    "GSSS ABUB SHAHAR",
    "GSSS ASA KHERA",
    "GSSS BIJJUWALI",
    "GSSS CHAUTALA",
    "GSSS DABWALI",
    "GSSS DABWALI VILLAGE",
    "GSSS DESUJODHA",
    "GSSS GANGA",
    "GSSS GORIWALA",
    "GSSS KALUANA",
    "GSSS LOHGARH",
    "GSSS MANGIANA",
    "GSSS MASITAN",
    "GSSS RISALIA KHERA",
    "GHS MITHANPURA",
    "GSSS BHURATWALA",
    "GSSS DHOLPALIA",
    "GSSS ELLENABAD",
    "GSSS KARIWALA",
    "GSSS KUTTABADH",
    "GSSS MOJU KHERA",
    "GSSS TALWARA KHURD",
    "GHS ALI MOHAMMAD",
    "GHS MODIA KHERA",
    "GSSS ARNIANWALI",
    "GSSS DARBA KALAN",
    "GSSS DING",
    "GSSS GUDIA KHERA",
    "GSSS JAMAL",
    "GSSS JODHKAN",
    "GSSS JOGIWALA",
    "GSSS KAGDANA",
    "GSSS NATHUSARI CHOPTA",
    "GSSS NEHRANA",
    "GSSS RAMPURA DHILLON",
    "GSSS RUPAWAS",
    "GSSS SHAHPURIA",
    "GSSS SHERPURA",
    "GHS DADU",
    "GSSS JALALANA",
    "GSSS KALANWALI MANDI",
    "GSSS MITHRI",
    "GSSS NAURANG",
    "GSSS ODHAN",
    "GSSS PANNIWALA MOTA",
    "GGSSS RANIA",
    "GHS DHUDHIYANWALI",
    "GHS NATHORE",
    "GSSS BAHIA",
    "GSSS BALASAR",
    "GSSS CHAKKAN",
    "GSSS DHOTTAR",
    "GSSS JODHPURIA",
    "GSSS KEHARWALA",
    "GSSS KUSSAR",
    "GSSS MAUJDEEN",
    "GSSS NAKORA",
    "GSSS OTTU",
    "GSSS RANIA",
    "GGSSS KOTLI",
    "GGSSS SIRSA",
    "GHS CHAMAL",
    "GMSSSS SIRSA",
    "GSSS BHAROKHAN",
    "GSSS CHATTARGARH PATTI",
    "GSSS DARBI",
    "GSSS FARWAIN KALAN",
    "GSSS JHORARNALI",
    "GSSS Kelnia",
    "GSSS KHAIRPUR",
    "GSSS MANGALA",
    "GSSS NEZADELA KALAN",
    "GSSS PATLI DABAR",
    "GSSS PHOOLKAN",
    "GSSS SUCHAN",
    "GGSSS DATAULI",
    "GMSSSS GANAUR",
    "GSSS BEGA",
    "GSSS BHIGAN",
    "GSSS GARHI JHANJARA",
    "GSSS KAILANA",
    "GSSS KHERI GUJJAR",
    "GSSS KHUBRU",
    "GSSS PABNERA",
    "GSSS PUGTHALA",
    "GSSS PURKASH",
    "GSSS RAJLU GARHI",
    "GSSS RAJPUR",
    "GSSS SANDAL KALAN",
    "GSSS SANPERA",
    "CHAUDHARY SHYAM CHAND GSSS LATH",
    "GSSS ANWALI",
    "GSSS BAROTA",
    "GSSS BHAINSWAL KALAN",
    "GSSS GARHI UJALE KHAN",
    "GSSS GOHANA CITY",
    "GSSS RABHRA",
    "GSSS SARAGTHAL",
    "GGSSS MADINA",
    "GSSS BANWASA",
    "GSSS BHAINSWAN KHURD",
    "GSSS BHAWAR",
    "GSSS KATHURA",
    "GGSSS KIROLI PAHLADPUR",
    "GGSSS ROHNA",
    "GSSS BIDHLAN",
    "GSSS GORAR",
    "GSSS KHARKHODA",
    "GSSS MATINDU",
    "GSSS ROHAT",
    "GSSS SAIDPUR",
    "GSSS BICHPARI",
    "GSSS BUTANA",
    "GSSS CHIDANA",
    "GSSS JAGSI",
    "GSSS MAHMOODPUR",
    "GSSS MUNDLANA",
    "GGSSS NAHRA",
    "GGSSS NAHRI",
    "GSSS A.P. BAROTA",
    "GSSS ATERNA",
    "GSSS BEESWAMEEL",
    "GSSS GARHI BALA",
    "GSSS JAKHOLI",
    "GSSS JANTI KALAN",
    "GSSS KHEWRA",
    "GSSS KUNDLI",
    "GGSSS SONEPAT",
    "GHS MOHANA",
    "GSSS ASADPUR NANDNAUR",
    "GSSS BARWASNI",
    "GSSS BAYANPUR LAHRARA",
    "GSSS BHATGAON",
    "GSSS Garhi Brahaman",
    "GSSS JATWARA",
    "GSSS JUAN",
    "GSSS KAKROI",
    "GSSS KAREWARI",
    "GSSS KUMASPUR",
    "GSSS MAHRA",
    "GSSS MODEL TOWN",
    "GSSS MURTHAL",
    "GSSS TAJPUR",
    "GHS KATHGARH",
    "GMSSSS BILASPUR",
    "GSSS BHERTHAL",
    "GSSS BILASPUR",
    "GSSS DHANOURA",
    "GSSS FATEHGARH TUMBI",
    "GSSS MACHHROULI",
    "GSSS MUGALWALI",
    "GSSS MUSSIMBAL",
    "GSSS PABNI KALAN",
    "GGSSS CHHACHHRAULI",
    "GGSSS KHIZRABAD",
    "GHS KHILLANWALA",
    "GHS MALIKPUR BANGAR",
    "GHS MUNDA KHERA",
    "GHS TAHARPUR KALAN",
    "GSSS Arriyawala",
    "GSSS BAKKARWALA",
    "GSSS CHHACHHRAULI",
    "GSSS CHUHARPUR KALAN",
    "GSSS DEODHAR",
    "GSSS JAIDHAR",
    "GSSS Kalesar",
    "GSSS KHADRI",
    "GSSS KHARWAN",
    "GSSS KHIZRABAD",
    "GSSS KOT KALSIA B",
    "GSSS LAKKAR",
    "GSSS LALHARI KALAN",
    "GSSS LEDI",
    "GSSS SALEMPUR KOHI",
    "GGSSS JAGADHRI",
    "GGSSS SABJI MANDI YNR",
    "GSSS BHATOULI",
    "GSSS BURIA",
    "GSSS Camp",
    "GSSS DAMLA",
    "GSSS Hamida",
    "GSSS HARIPUR KAMBOJ",
    "GSSS HARNAUL",
    "GSSS JAG.W/SHOP",
    "GSSS JAGADHRI(BOYS)",
    "GSSS MANDOLI",
    "GSSS Mehlawali",
    "GSSS Model Colony",
    "GSSS NAGAL",
    "GSSS Sabapur",
    "GSSS SNV MODEL TOWN",
    "GMSSSS MUSTAFABAD",
    "GSSS BHAMBHOL",
    "GSSS GUNDIANA",
    "GSSS KALAWAR",
    "GSSS SIALBA",
    "GSSS TALAKAUR",
    "GHS BAPA",
    "GHS NACHRON",
    "GSSS ALAHAR",
    "GSSS ANTTAWA",
    "GSSS CHAMRORI",
    "GSSS GHILLOUR",
    "GSSS GUMTAHLA RAO",
    "GSSS JATHLANA",
    "GSSS KHURDBAN",
    "GSSS RADAUR",
    "GSSS TOPRA KALAN",
    "GSSS KOTLA",
    "GSSS LAHARPUR",
    "GSSS SADHAURA",
    "GSSS SALEHPUR",
    "GSSS SARAWAN"
]