import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import JWTLoginTab from './JWTLoginTab';
import logo from '../../assets/logo.png';
import background from '../../assets/backgrounds/background.jpg';

const config = require('../../config');

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  relativeBackground: {
    color: '#122230',
  },
  fontBold: {
    fontWeight: 'bolder',
  },
}));

const Login = (props) => {
  const { userLogin, defaultUsername, defaultPassword } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        'flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0 h-full'
      )}
    >
      <div
        className={clsx(
          classes.relativeBackground,
          'flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left'
        )}
      >
        <div>
          <img className='w-400 mb-32' src={logo} alt='logo' />
        </div>

        <div>
          <Typography variant='h3' className='font-light'>
            <span className='font-bold'>
              {config.appMeta.loginPage.titleName}
            </span>
            {config.appMeta.loginPage.titleState}
          </Typography>
        </div>

        <div>
          <Typography
            variant='h4'
            color='inherit'
            className='max-w-512 mt-16 font-hairline'
          >
            {config.appMeta.loginPage.subTitle}
          </Typography>
        </div>
      </div>

      <div>
        <Card
          className='w-full max-w-400 mx-auto m-16 md:m-0'
          style={{ height: '100%', padding: '20px' }}
          square
        >
          <CardContent className='flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 '>
            <Typography variant='h6' className='text-center md:w-full mb-48'>
              LOGIN
            </Typography>

            <JWTLoginTab
              userLogin={userLogin}
              defaultUsername={defaultUsername}
              defaultPassword={defaultPassword}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Login;
