import React from 'react';
import {
  List,
  Datagrid,
  Filter,
  TextField,
  DateField,
  DateInput,
  SearchInput,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

// choices
const districtChoices = require('../../meta/district.json');
// const blockChoices = require('../../meta/block.json');
const designationChoices = require('../../meta/designation.json');
const temperatureChoices = require('../../meta/temperatures.json');

// school renderer
const schoolCodeRenderer = (choice) => {
  return choice != null ? `${choice.id} - ${choice.school_name}` : '';
};

/**
 * Filter
 * @param {*} props
 */
const SearchFilter = (props) => {
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;

  return (
    <Filter {...props}>
      <SearchInput
        placeholder='Search'
        source='q'
        className='searchBar'
        alwaysOn
      />
      <AutocompleteInput
        label='By Designation'
        source='employee_designation'
        choices={designationChoices}
        className='autocompleteInput'
      />
      <SelectInput
        label='By Min. Temperature'
        source='temperature'
        choices={temperatureChoices}
        className='selectInput'
        alwaysOn
      />
      <DateInput
        label='On Date'
        source='date_of_attendance'
        className='selectInput'
      />
      <ReferenceInput
        label='By School Code'
        source='school_code'
        reference='school'
        format={(v) => (!v ? null : v)}
        sort={{ field: 'school_code', order: 'ASC' }}
        filterToQuery={(searchText) => ({ school_code: searchText })}
        className='autocompleteInput'
        allowEmpty
        allowNull
      >
        <AutocompleteInput optionText={schoolCodeRenderer} />
      </ReferenceInput>
      {district === 'ALL' || district === '' ? (
        <AutocompleteInput
          label='By District'
          source='school.district'
          choices={districtChoices}
          className='autocompleteInput'
        />
      ) : (
        <></>
      )}
      <BooleanInput label='Is Present' source='isPresentInSchool' />
    </Filter>
  );
};

/**
 * List all school teachers
 * @param {*} props
 */
export const EmployeeDataList = (props) => (
  <List
    {...props}
    filters={<SearchFilter />}
    filterDefaultValues={{
      customEmployeeFilter: 'ALL',
      isPresentInSchool: true,
    }}
    // sort={{ field: 'school.district', order: 'DESC' }}
    title='Employee Temperature and Attendance Data List'
    className='customBox'
  >
    <Datagrid>
      {/* <TextField label='ID' source='id' /> */}
      <TextField label='Employee ID' source='employee_id' />
      <TextField label='Employee Name' source='employee_name' />
      <TextField label='Designation' source='employee_designation' />
      {/* <TextField label='School Code' source='school_code' /> */}
      <DateField label='Attendance Date' source='date_of_attendance' />
      {/* <TextField label='Is Present' source='isPresentInSchool' /> */}
      <TextField label='Attendance Status' source='attendance_status' />
      <TextField label='Temperature' source='temperature' />
      <TextField label='Name' source='school.school_name' />
      <TextField label='District' source='school.district' />
      <TextField label='Block' source='school.block' />
    </Datagrid>
  </List>
);
