import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  SearchInput,
  SelectInput,
  Filter,
  Pagination,
  useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { districtOptions, blockOptions } from '../../../meta/filterOption';

// choice mapping
const toChoices = (items) =>
  items && items.map((item) => ({ id: item, name: item }));

// block filter input
const BlockInput = (props) => {
  const { values } = useFormState();
  const { displayedFilters } = useListContext();
  return displayedFilters['data.district'] ? (
    <SelectInput
      choices={
        values.data && values.data.district
          ? toChoices(blockOptions[values.data.district])
          : []
      }
      {...props}
    />
  ) : (
    ''
  );
};

// search filter
const SearchFilter = (props) => {
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;

  const { displayedFilters, filterValues, setFilters } = useListContext();

  if (
    displayedFilters['data.block'] &&
    (displayedFilters['data.district'] === false ||
      displayedFilters['data.district'] === undefined)
  ) {
    displayedFilters['data.block'] = false;
    setFilters({});
  }

  const districtChange = (e) => {
    if (filterValues.data.district !== e.target.value) {
      setFilters({
        data: {
          district: e.target.value,
        },
      });
    }
  };

  const blockChange = (e) => {
    if (filterValues.data.block !== e.target.value) {
      setFilters({
        data: {
          district: filterValues.data.district,
          block: e.target.value,
        },
      });
    }
  };

  const userDistrict = [{ id: district, name: district }];
  return (
    <Filter {...props}>
      <SearchInput
        placeholder='Search by name*'
        source='data.name'
        className='searchBar'
        alwaysOn
      />
      <SelectInput
        label='By District'
        source='data.district'
        choices={
          district === 'ALL' || district === ''
            ? toChoices(districtOptions)
            : userDistrict
        }
        className='autocompleteInput'
        onChange={(e) => districtChange(e)}
        allowEmpty={false}
      />
      <BlockInput
        label='By Block'
        source='data.block'
        className='autocompleteInput'
        onChange={(e) => blockChange(e)}
        allowEmpty={false}
      />
      {/* <SchoolInput
        label='By School Name'
        source='data.school'
        reference='school'
        className='autocompleteInput'
        allowEmpty={false}
        allowNull
      /> */}
      {/* <ReferenceInput
        label='By School Name'
        source='data.school@_eq'
        reference='school'
        format={(v) => (!v ? null : v)}
        sort={{ field: 'school_code', order: 'ASC' }}
        filterToQuery={(searchText) => ({ school_name: searchText })}
        className='autocompleteInput'
        allowEmpty
        allowNull
      >
        <AutocompleteInput
          // optionValue='school_name'
          optionText={schoolCodeRenderer}
        />
      </ReferenceInput> */}
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 0px)',
    height: '86vh',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'scroll',
    marginLeft: '1rem',
  },
}));

export const CovidStudentList = (props) => {
  const classes = useStyles();
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;
  return (
    <div className={classes.root}>
      <List
        {...props}
        filters={<SearchFilter />}
        filterDefaultValues={
          district === 'ALL' || district === ''
            ? {}
            : {
                data: {
                  district,
                },
              }
        }
        pagination={<Pagination perPage={1} style={{ float: 'left' }} />}
        title='Reported COVID Positive Student'
        className='customBox'
      >
        <Datagrid>
          <TextField
            label='Reporting Person Designation'
            source='data[0].designation'
          />
          <TextField label='Reporting Person Name' source='data[0].name' />
          <ArrayField label='District' source='data'>
            <SingleFieldList>
              <TextField source='district' />
            </SingleFieldList>
          </ArrayField>
          <ArrayField label='Block' source='data'>
            <SingleFieldList>
              <TextField source='block' />
            </SingleFieldList>
          </ArrayField>
          <TextField label='School Name' source='data[0].school' />
          <TextField label='Grade' source='data[0].grade' />
          <TextField label='Section' source='data[0].section' />
          <TextField label='Student Details' source='data[0].student' />
          <DateField label='Test Date' source='data[0].test_date' />
          <DateField
            label='Last Present Date'
            source='data[0].last_present_date'
          />

          {/* <ImageField
            label='Report Image'
            source='data[0].report_image.filename'
            title='data[0].report_image.filename'
          /> */}
        </Datagrid>
      </List>
    </div>
  );
};
