import hasuraDataProvider from './hasuraDataProvider';
import jsonServerDataProvider from './jsonServerDataProvider';
import lb4DataProvider from './lb4Dataprovider';
import campaignDataProvider from './campaignDataProvider';
import groupDataProvider from './groupDataProvider';
import groupUserDataProvider from './groupUserDataProvider';
// import userDataProvider from './userDataProvider';
import userDataProviderSamiksha from './userDataProviderSamiksha';
import userDataProvider from './userDataProvider';

const config = require('../../config');

export default hasuraDataProvider.then((hDP) => {
  const dataProviderResources = {
    users: userDataProvider(config.fusionAuthConsole),
    userSamiksha: userDataProviderSamiksha(config.fusionAuth),
    'userSamiksha-school': userDataProviderSamiksha(config.fusionAuth),
    'userSamiksha-diet': userDataProviderSamiksha(config.fusionAuth),
    'userSamiksha-mentor': userDataProviderSamiksha(config.fusionAuth),
    default: hDP,
    locations: jsonServerDataProvider,
    roles: jsonServerDataProvider(config.fusionAuth.authServerBasePath),
    resources: jsonServerDataProvider(config.fusionAuth.authServerBasePath),
    permissions: jsonServerDataProvider(config.fusionAuth.authServerBasePath),
    application: jsonServerDataProvider(process.env.REACT_APP_AUTH_SERVER_URL),
    conversations: campaignDataProvider(config.fusionAuthCampaignManager),
    segments: groupDataProvider(config.fusionAuthCampaignManager),
    usersSegment: groupUserDataProvider({
      ...config.fusionAuthCampaignManager,
      avoidApplicationId: true,
      avoidToken: true,
    }),
    'ele-mentor-visits-v1s': lb4DataProvider,
    'ele-monitor-visits-v4s': lb4DataProvider,
    'ele-ssa-visits-v1s': lb4DataProvider,
    'sampark-pro-visits-v1s': lb4DataProvider,
    'sat-visits': lb4DataProvider,
    'sat-visit2s': lb4DataProvider,
    'sec-mentor-visits-v1s': lb4DataProvider,
    'sec-monitor-visits-v1s': lb4DataProvider,
    'sec-ssa-visits-v1s': lb4DataProvider,
    'slo-visits-v1s': lb4DataProvider,
    'slo-visits-v2s': lb4DataProvider,
    'covid-positive-teacher-form-v2s': lb4DataProvider,
    'covid-positive-student-forms': lb4DataProvider,
    'ementfeedb-v2s': lb4DataProvider,
  };
  if (!dataProviderResources.default) {
    console.error('!! IMPORTANT !! Default config needed for data provider');
  }
  const methodDefinations = [
    'getList',
    'getOne',
    'getMany',
    'getManyReference',
    'update',
    'updateMany',
    'create',
    'delete',
    'deleteMany',
  ];
  const methods = {};
  methodDefinations.forEach((methodDefinition) => {
    methods[methodDefinition] = (resource, params) =>
      (dataProviderResources[resource] || dataProviderResources.default)[
        methodDefinition
      ](resource, params);
  });
  return methods;
});
