/* eslint-disable no-nested-ternary */
import React, { cloneElement } from 'react';
import {
  List,
  Create,
  Edit,
  TopToolbar,
  ListButton,
  CreateButton,
  EditButton,
  DeleteButton,
  Datagrid,
  SelectInput,
  Filter,
  TextField,
  FunctionField,
  SimpleForm,
  TextInput,
  required,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { Typography, Chip, makeStyles } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';

const config = require('../../config.json');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    border: '1px solid #ddd',
    '& div': {
      margin: '2px 0',
    },
  },
  customTable: {
    paddingLeft: '1.6rem',
  },
}));

/**
 * List Top Toolbar
 * @param {*} props
 */
const ResourceListActions = (props) => {
  const classes = useStyles();
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  } = props;
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      style={{ paddingTop: '10px' }}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <CreateButton basePath={basePath} className={classes.longMenu} />
    </TopToolbar>
  );
};

/**
 * Filter
 * @param {*} props
 */
const ResourceFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        source='applicationId'
        variant='outlined'
        className='selectInput'
        label='Applications'
        choices={config.applicationIdMap}
        allowEmpty={false}
      />
    </Filter>
  );
};

const CustomEditButton = (props) => (
  <EditButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);
const CustomDeleteButton = (props) => (
  <DeleteButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);

/**
 * Resource list
 * @param {*} props
 */
export const ResourceList = (props) => {
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  return (
    <List
      {...props}
      title='Resources'
      filters={<ResourceFilter />}
      actions={<ResourceListActions />}
      filterDefaultValues={{
        applicationId: config.fusionAuthConsole.fusionAuthApplicationId,
      }}
      bulkActionButtons={false}
      className={classes.customTable}
    >
      <Datagrid rowClick='edit'>
        <TextField label='Resource Name' source='name' />
        <FunctionField
          label='Application'
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={(record) => {
            if (
              (record && record.applicationId) ===
              config.fusionAuthConsole.fusionAuthApplicationId
            ) {
              return <Chip label='Saksham Samiksha' />;
            }
            return <Chip label='Admin Console' />;
          }}
        />
        <TextField label='Function' source='function' />
        {loaded ? (
          permissions.Resources &&
          permissions.Resources.indexOf('edit') > -1 ? (
            <CustomEditButton {...props} label='Edit' />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {loaded ? (
          permissions.Resources &&
          permissions.Resources.indexOf('delete') > -1 ? (
            <CustomDeleteButton {...props} label='Delete' />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Datagrid>
    </List>
  );
};

/**
 * Create new resource
 * @param {*} props
 */
export const ResourceCreate = (props) => (
  <Create {...props} title='Add New Resource' className='customBox'>
    <SimpleForm>
      <div className='flex flex-row' style={{ width: '70vw' }}>
        <TextInput
          label='Name'
          source='name'
          validate={[required()]}
          variant='outlined'
          style={{ width: '40%', marginRight: '1rem' }}
        />
        <SelectInput
          source='applicationId'
          variant='outlined'
          label='Application'
          className='selectInput'
          choices={config.applicationIdMap}
        />
      </div>
      <TextInput
        label='Function'
        source='function'
        validate={[required()]}
        variant='outlined'
        style={{ width: '40%' }}
      />
    </SimpleForm>
  </Create>
);

/**
 * Edit resource
 * @param {*} props
 */
// edit top toolbar
const ResourceEditAction = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const ResourceEditTitle = ({ record }) => {
  return (
    <>
      <Typography
        variant='h5'
        color='textPrimary'
        style={{ display: 'inline' }}
      >
        Edit{' '}
      </Typography>
      <Typography
        variant='h5'
        color='textSecondary'
        style={{ display: 'inline' }}
      >
        {record ? record.name : ''}
      </Typography>
    </>
  );
};

// edit form
export const ResourceEdit = (props) => {
  const { loaded, permissions } = usePermissions();
  return (
    <Edit
      {...props}
      title={<ResourceEditTitle />}
      actions={<ResourceEditAction />}
      className='customBox'
    >
      <SimpleForm
        toolbar={
          loaded ? (
            permissions.Resources &&
            permissions.Resources.indexOf('delete') > -1 ? undefined : (
              <EditNoDeleteToolbar />
            )
          ) : (
            <EditNoDeleteToolbar />
          )
        }
      >
        <div className='flex flex-row items-center' style={{ width: '70vw' }}>
          <TextInput
            label='Name'
            source='name'
            style={{ width: '30%', marginRight: '1rem' }}
            validate={[required()]}
            variant='outlined'
          />
          <SelectInput
            label='Application'
            source='applicationId'
            validate={[required()]}
            disabled
            choices={config.applicationIdMap}
            variant='outlined'
            style={{ width: '30%', marginRight: '1rem' }}
          />
        </div>
        <TextInput
          label='Function'
          source='function'
          validate={[required()]}
          variant='outlined'
          style={{ width: '30%' }}
        />
      </SimpleForm>
    </Edit>
  );
};
