import * as React from 'react';
import { useListContext } from 'react-admin';
import WcOutlinedIcon from '@material-ui/icons/WcOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import AccessibleForwardOutlinedIcon from '@material-ui/icons/AccessibleForwardOutlined';
import FilterList from './FilterList';
import FilterListItem from './FilterListItem';
import filterData from '../../meta/filterData';

const GenderFilter = () => (
  <div className='flex flex-col'>
    <FilterList label='Gender' icon={<WcOutlinedIcon />}>
      {filterData.gender.map((gender) => (
        <FilterListItem
          label={gender.name}
          key={gender.id}
          value={{ gender: gender.id }}
        />
      ))}
    </FilterList>
  </div>
);

const GradeNumberFilter = () => (
  <div className='flex flex-col'>
    <FilterList label='Grade Number' icon={<FormatListNumberedOutlinedIcon />}>
      <div className='grid grid-cols-3 grid-rows-4 selectedFilterAside'>
        {filterData.grades.map((grade) => (
          <FilterListItem
            label={grade.name}
            key={grade.id}
            multipleSelect
            value={{ grade: grade.id }}
          />
        ))}
      </div>
    </FilterList>
  </div>
);

const CategoryFilter = () => (
  <div className='flex flex-col'>
    <FilterList label='Category' icon={<ClassOutlinedIcon />}>
      <div className='grid-cols-3 grid-rows-2 selectedFilterAside'>
        {filterData.categories.map((category) => (
          <FilterListItem
            label={category.name}
            key={category.id}
            multipleSelect
            value={{ category: category.id }}
          />
        ))}
      </div>
    </FilterList>
  </div>
);

const SchoolTypeFilter = () => {
  const { filterValues = {} } = useListContext();
  const { school } = filterValues;
  const { location } = filterValues;
  return (
    <div className='flex flex-col'>
      <FilterList label='School Type' icon={<StoreOutlinedIcon />}>
        <div className='grid grid-cols-3 grid-rows-2 selectedFilterAside'>
          {filterData.schoolTypes.map((type) => (
            <FilterListItem
              label={type.name}
              key={type.id}
              multipleSelect
              innerTarget='type'
              target='school'
              value={{ school: { ...school, type: type.id }, ...location }}
            />
          ))}
        </div>
      </FilterList>
    </div>
  );
};

const SchoolSessionFilter = () => {
  const { filterValues = {} } = useListContext();
  const { school } = filterValues;
  const { location } = filterValues;
  return (
    <div className='flex flex-col'>
      <FilterList label='School Session' icon={<TodayOutlinedIcon />}>
        {filterData.schoolSessions.map((session) => (
          <FilterListItem
            label={session.name}
            key={session.id}
            innerTarget='session'
            target='school'
            value={{ school: { ...school, session: session.id }, ...location }}
          />
        ))}
      </FilterList>
    </div>
  );
};

const CWSNFilter = () => (
  <div className='flex flex-col'>
    <FilterList label='Is CWSN?' icon={<AccessibleForwardOutlinedIcon />}>
      {filterData.cwsn.map((boolValue) => (
        <FilterListItem
          label={boolValue.name}
          key={boolValue.id}
          value={{ is_cwsn: boolValue.id }}
        />
      ))}
    </FilterList>
  </div>
);

export {
  CategoryFilter,
  GenderFilter,
  SchoolTypeFilter,
  SchoolSessionFilter,
  GradeNumberFilter,
  CWSNFilter,
};
