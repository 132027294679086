import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  Filter,
  TextField,
  // SearchInput,
  // TextInput,
  DateInput,
  BooleanInput,
  SelectInput,
  // ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

// choices
const districtChoices = require('../../meta/district.json');
const temperatureChoices = require('../../meta/temperatures.json');

/**
 * Filter
 * @param {*} props
 */
const SearchFilter = (props) => {
  const userData = JSON.parse(
    localStorage.getItem('Dashboard-App/local_sql/user')
  );
  const {
    user: {
      data: {
        roleData: { district },
      },
    },
  } = userData;

  return (
    <Filter {...props}>
      {/* <SearchInput
        placeholder='Search by Student Name *'
        source='studentByStudent.name'
        className='searchBar'
        alwaysOn
      /> */}
      <SelectInput
        label='Min. Temperature'
        source='temperature'
        choices={temperatureChoices}
        className='autocompleteInput'
        alwaysOn
      />
      <DateInput label='On Date' source='date' className='autocompleteInput' />
      {district === 'ALL' || district === '' ? (
        <AutocompleteInput
          label='By District'
          source='studentByStudent.school.district'
          choices={districtChoices}
          className='autocompleteInput'
        />
      ) : (
        <></>
      )}
      <BooleanInput label='Is Present' source='isPresent' />
    </Filter>
  );
};

/**
 * List all school teachers
 * @param {*} props
 */
export const StudentDataList = (props) => {

  useEffect(() => {
    let reloaded = localStorage.getItem("fr");
    if (!reloaded) {
      window.location.reload();
      localStorage.setItem("fr", true)
    }
  }, [])

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      filterDefaultValues={{
        customStudentFilter: 'ALL',
        isPresent: true,
        temperature: '99',
      }}
      title='Students with High Temperature List'
      className='customBox'
    >
      <Datagrid>
        {/* <TextField label='ID' source='id' /> */}
        <TextField label='Name' source='studentByStudent.name' />
        <TextField label='Grade' source='studentByStudent.grade' />
        <TextField label='Section' source='studentByStudent.section' />
        <TextField label='SRN' source='student' />
        <TextField label='Date' source='date' />
        <TextField label='Temperature' source='temperature' />

        {/* <TextField label='Is Present' source='isPresent' /> */}
        <TextField label='School' source='studentByStudent.school.school_name' />
        <TextField label='District' source='studentByStudent.school.district' />
        <TextField label='Block' source='studentByStudent.school.block' />
      </Datagrid>
    </List>
  )
};
