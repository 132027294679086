/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  List,
  Create,
  Edit,
  TopToolbar,
  ListButton,
  EditButton,
  Pagination,
  ListActions,
  Datagrid,
  TextField,
  ReferenceField,
  ChipField,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  AutocompleteInput,
  ReferenceInput,
  SearchInput,
  Filter,
  required,
  email,
  number,
  minLength,
  regex,
  usePermissions,
} from 'react-admin';
import { Divider, makeStyles } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import PasswordChangeButton from './PasswordChangeButton';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';

// choices
const districtChoices = [
  { id: 'Ambala', name: 'Ambala' },
  { id: 'Bhiwani', name: 'Bhiwani' },
  { id: 'Charkhi Dadri', name: 'Charkhi Dadri' },
  { id: 'Faridabad', name: 'Faridabad' },
  { id: 'Fatehabad', name: 'Fatehabad' },
  { id: 'Gurugram', name: 'Gurugram' },
  { id: 'Hisar', name: 'Hisar' },
  { id: 'Jhajjar', name: 'Jhajjar' },
  { id: 'Jind', name: 'Jind' },
  { id: 'Kaithal', name: 'Kaithal' },
  { id: 'Karnal', name: 'Karnal' },
  { id: 'Kurukshetra', name: 'Kurukshetra' },
  { id: 'Mahendragarh', name: 'Mahendragarh' },
  { id: 'Nuh Mewat', name: 'Nuh Mewat' },
  { id: 'Palwal', name: 'Palwal' },
  { id: 'Panchkula', name: 'Panchkula' },
  { id: 'Panipat', name: 'Panipat' },
  { id: 'Rewari', name: 'Rewari' },
  { id: 'Rohtak', name: 'Rohtak' },
  { id: 'Sirsa', name: 'Sirsa' },
  { id: 'Sonipat', name: 'Sonipat' },
  { id: 'Yamunanagar', name: 'Yamuna Nagar' },
  { id: 'ALL', name: 'ALL' },
];

// validation
const validatePhone = regex(/^\d{10}$/, 'Must be a valid phone number');

const config = require('../../config');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    border: '1px solid #ddd',
    '& div': {
      margin: '2px 0',
    },
  },
  longMenu: {
    '& ul': {
      padding: '0px',
    },
    '& ul > a, & ul > button': {
      width: '100%',
      display: 'block !important',
      textAlign: 'left !important',
    },
  },
  customArrayInput: {
    '& p': {
      fontSize: '20px',
      fontWeight: 'bold',
      width: '1em',
      paddingTop: '1.3em',
    },
    '& p + section': {
      paddingTop: '1em',
    },
    '& section + span': {
      paddingTop: '2em',
    },
    '& ul > li:last-child button': {
      border: '1px solid #ccc',
      marginTop: '10px',
    },
  },
  customInput: {
    '& p': {
      display: 'none!important',
    },
  },
  customTable: {
    paddingLeft: '1.6rem',
  },
  textCenter: {
    textAlign: 'center',
  },
}));

export const UserList = (props) => {
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  // eslint-disable-next-line no-shadow
  const CustomEditButton = (props) => (
    <EditButton
      style={{ marginLeft: '-20px', color: '#757575' }}
      {...props}
      label=''
    />
  );
  return (
    <div>
      <List
        {...props}
        className={classes.customTable}
        filters={<UserFilter />}
        actions={<ListActions />}
        bulkActionButtons={false}
        title='Samarth Console User List'
        pagination={<Pagination perPage={1} style={{ float: 'left' }} />}
      >
        <Datagrid>
          <TextField source='username' />
          <TextField source='fullName' />
          <ReferenceField
            label='Roles'
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source={`data.${config.fusionAuthConsole.fusionAuthApplicationId}.roleId`}
            reference='roles'
          >
            <ChipField source='name' />
          </ReferenceField>
          <TextField source='mobilePhone' />
          {loaded ? (
            permissions['Manage Console Users'] &&
            permissions['Manage Console Users'].indexOf('edit') > -1 ? (
              <CustomEditButton {...props} label='Edit' />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Datagrid>
      </List>
    </div>
  );
};

/**
 * Create new user
 * @param {*} props
 */
export const UserCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props} title='Create New User' className={classes.customTable}>
      <SimpleForm>
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Username'
            source='username'
            validate={[required(), minLength(4)]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <ReferenceInput
            label='Role'
            source={`data.${config.fusionAuthConsole.fusionAuthApplicationId}.roleId`}
            filter={{
              applicationId: config.fusionAuthConsole.fusionAuthApplicationId,
            }}
            reference='roles'
            variant='outlined'
            style={{ width: '50%' }}
          >
            <AutocompleteInput
              optionText='name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='end'>▼</InputAdornment>
                ),
              }}
            />
          </ReferenceInput>
        </div>
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Name'
            source='fullName'
            validate={[required()]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <SelectInput
            label='District'
            source='data.roleData.district'
            choices={districtChoices}
            perPage={100}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
        </div>
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Email Id'
            source='email'
            validate={[email()]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <TextInput
            label='Mobile Phone'
            source='phone'
            validate={[number(), validatePhone]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
        </div>
        <PasswordInput
          label='Password'
          source='password'
          validate={[required(), minLength(8)]}
          variant='outlined'
          style={{ width: '45%', marginRight: '1rem' }}
        />
      </SimpleForm>
    </Create>
  );
};

/**
 * Edit user details
 * @param {*} props
 */
// edit top toolbar
const EditAction = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const UserTitle = ({ record }) => {
  return (
    <>
      <Typography
        variant='h5'
        color='textPrimary'
        style={{ display: 'inline' }}
      >
        Manage{' '}
      </Typography>
      <Typography
        variant='h5'
        color='textSecondary'
        style={{ display: 'inline' }}
      >
        {record ? record.name : 'user'}
      </Typography>
    </>
  );
};
// edit bottom toolbar
// const EditToolbar = (props) => (
//   <Toolbar {...props} classes={useStyles()}>
//     <SaveButton />
//     <DeleteButton />
//   </Toolbar>
// );

// edit form
export const UserEdit = (props) => {
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  return (
    <Edit
      {...props}
      title={<UserTitle />}
      actions={<EditAction />}
      // toolbar={<EditToolbar />}
      className={classes.customTable}
    >
      <SimpleForm
        toolbar={
          loaded ? (
            permissions['Manage Console Users'] &&
            permissions['Manage Console Users'].indexOf('delete') >
              -1 ? undefined : (
              <EditNoDeleteToolbar />
            )
          ) : (
            <EditNoDeleteToolbar />
          )
        }
      >
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Username'
            source='username'
            validate={[required(), minLength(4)]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <ReferenceInput
            label='Role'
            source={`data.${config.fusionAuthConsole.fusionAuthApplicationId}.roleId`}
            filter={{
              applicationId: config.fusionAuthConsole.fusionAuthApplicationId,
            }}
            reference='roles'
            variant='outlined'
            style={{ width: '50%' }}
          >
            <AutocompleteInput
              optionText='name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='end'>▼</InputAdornment>
                ),
              }}
            />
          </ReferenceInput>
        </div>
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Name'
            source='fullName'
            validate={[required()]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <SelectInput
            label='District'
            source='data.roleData.district'
            choices={districtChoices}
            perPage={100}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
        </div>
        <div className='flex flex-row' style={{ width: '70vw' }}>
          <TextInput
            label='Email Id'
            source='email'
            validate={[email()]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
          <TextInput
            label='Mobile Phone'
            source='phone'
            validate={[number(), validatePhone]}
            variant='outlined'
            style={{ width: '35%', marginRight: '1rem' }}
          />
        </div>
        <Divider />
        <PasswordChangeButton />
      </SimpleForm>
    </Edit>
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder='Search by username'
      source='globalSearch'
      className='searchBar'
      alwaysOn
    />
  </Filter>
);
