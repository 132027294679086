import { useState, useEffect } from 'react';
const util = require('util');
const axios = require('axios');
const config = require('../../config');

export default function useGroupMembers(groupId) {
  const [membersCount, setMembersCount] = useState(0);
  const client = axios.create({
    baseURL: config.fusionAuthCampaignManager.fusionAuthURL,
    headers: {
      Authorization: config.fusionAuthCampaignManager.fusionAuthAPIKey,
    },
  });
  useEffect(() => {
    async function fetchData() {
    try {
        const searchUsersInGroup = {
            search: {
              numberOfResults: 10000,
              queryString: `memberships.groupId:${groupId}`,          
              startRow: 0,
            },
          };
          const membersCount = await client.post(
            'api/user/search',
            searchUsersInGroup
          );
      
        if(membersCount && membersCount.data) {
            setMembersCount(membersCount.data.total);
          }        
    }
    catch {
        console.log('Error fetching member count.');
    }
  }
  fetchData();
}, []);    
  return membersCount;
}
