// in src/MyAppBar.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarVisibility } from 'react-admin';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';

// import LanguageSwitcher from '../../../../react-admin-base/components/ra-custom-compontents/ra.language-switcher.component';
import UserMenu from '../../../../react-admin-base/components/ra-custom-compontents/UserMenu';
import AdminBreadcrumbs from '../../../../react-admin-base/resources/AdminBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
  },
  spacer: {
    flex: 1,
  },
  divStyle: {
    height: 54,
  },
  appBarStyle: {
    backgroundColor: 'white',
  },
  toolBarStyle: {
    flexGrow: '1',
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      width: '1.5em',
      marginRight: '1em',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
// const appConfig = require('../../../../config');

const AppBarCustom = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const toggleSidebarOpen = () => {
    if (open) dispatch(setSidebarVisibility(false));
    else dispatch(setSidebarVisibility(true));
  };
  return (
    <div>
      <div className={classes.divStyle} />
      <AppBar
        id='fuse-toolbar'
        className={`flex relative z-10 main-header ${classes.appBarStyle}`}
        color='default'
        style={{ backgroundColor: 'white' }}
      >
        <Toolbar className={`p-0 ${classes.toolBarStyle}`}>
          <div className='flex flex-1 flex-col mt-16 pb-2'>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => {
                toggleSidebarOpen();
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant='h5'
              color='inherit'
              className={classes.title}
              id='react-admin-title'
            >
              {/* {appConfig.appMeta.name} */}
            </Typography>
            <AdminBreadcrumbs path={window.location.href} />
          </div>
          <div className='flex'>
            {/* <LanguageSwitcher /> */}
            <div className={classes.separator} />
            <UserMenu data={{ logout: props.logout }} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default React.memo(AppBarCustom);
