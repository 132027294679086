import React from 'react';
import Iframe from 'react-iframe';

const ElementaryStatePage = () => (
  <Iframe
    url='https://visualization.audit.samiksha.samagra.io/public/dashboard/427b80d2-0884-4f68-8141-b91585001315'
    width='100%'
    height='100%'
    display='initial'
    position='relative'
  />
);

export default ElementaryStatePage;
