import React, { useState, useEffect } from 'react';
import { usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { ListSubheader, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import UserNavbarHeader from './UserNavbarHeader';
import FuseNavVerticalCollapse from './FuseNavVerticalCollapse';
import FuseNavVerticalItem from './FuseNavVerticalItem';

// sidebar menu
const sidebarConfig = require('../../../sidebar-config');

const navbarWidth = 270;

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    // overflow: "hidden",
    flexDirection: 'column',
    flex: '1 1 auto',
    width: `${navbarWidth}px!important`,
    // minWidth: navbarWidth,
    // height: "100%",
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    // boxShadow: theme.shadows[3],
  },
  navbarContent: {
    flex: '1 1 auto',
  },
}));

const CustomSidebar = (props) => {
  const [activePath, setActivePath] = useState(null);

  const { location } = props;
  useEffect(() => {
    const pathname = location.pathname.replace(/\//, '');
    if (activePath !== pathname) {
      setActivePath(pathname);
    }
  }, [location, activePath]);

  return <NavbarWrapperLayout activePath={activePath} />;
};

const NavbarWrapperLayout = React.memo(function NavbarWrapperLayout({
  activePath,
}) {
  const config = {
    mode: 'fullwidth',
    scroll: 'content',
    navbar: { display: true, folded: false, position: 'left' },
    toolbar: { display: true, style: 'fixed', position: 'below' },
    footer: { display: true, style: 'fixed', position: 'below' },
    leftSidePanel: { display: true },
    rightSidePanel: { display: true },
  };

  // const navbar = { foldedOpen: false, mobileOpen: false };
  const classes = useStyles();
  const { folded } = config.navbar;
  const { loaded, permissions } = usePermissions();
  return (
    <>
      <div
        id='fuse-navbar'
        className={clsx(classes.wrapper, folded && classes.wrapperFolded)}
        style={{ backgroundColor: '#343A40' }}
      >
        <div
          className={clsx(classes.navbar, classes[config.navbar.position])}
          style={{ backgroundColor: '#343A40' }}
        >
          <div
            className={clsx(
              'flex flex-col overflow-hidden h-full',
              classes.navbarContent
            )}
          >
            <UserNavbarHeader />
            {sidebarConfig.map((item, index) => {
              if (item.title)
                return (
                  <ListSubheader
                    key={index}
                    className={clsx('list-subheader flex items-center')}
                  >
                    <span className='list-subheader-text uppercase text-12 text-white'>
                      {item.title}
                    </span>
                  </ListSubheader>
                );
              if (loaded) {
                if (item.children) {
                  if (
                    item.public ||
                    item.children.children ||
                    item.children.filter(
                      (child) =>
                        permissions[child.name] &&
                        permissions[child.name].length
                    ).length
                  )
                    return (
                      <FuseNavVerticalCollapse
                        key={index}
                        activePath={activePath}
                        item={item}
                        nestedLevel={0}
                        permissions={permissions}
                      />
                    );
                }
                if (
                  (permissions[item.name] && permissions[item.name].length) ||
                  item.public
                )
                  return (
                    <FuseNavVerticalItem
                      activePath={activePath}
                      key={index}
                      item={item}
                      nestedLevel={0}
                    />
                  );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
});

export default withRouter((props) => <CustomSidebar {...props} />);
