import React from 'react';

const AllComponents = () => (
  <>
    {/* <ContainedButtons/> */}
    {/* <TextButtons/> */}
    {/* <IconButtons/> */}
    {/* <IconLabelButtons/> */}
  </>
);

export default AllComponents;
