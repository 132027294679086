import React, { useMemo, useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import FuseAnimate from '../../fuse-base/@fuse/core/FuseAnimate';
import FuseAnimateGroup from '../../fuse-base/@fuse/core/FuseAnimateGroup';

const filteredData = require('../../meta/faq.json');

const useStyles = makeStyles((theme) => ({
  header: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  panel: {
    margin: 0,
    borderWidth: '1px 1px 0 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    '&:first-child': {
      borderRadius: '16px 16px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 16px 16px',
      borderWidth: '0 1px 1px 1px',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}));

function FaqPage() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  // eslint-disable-next-line no-shadow
  const toggleExpansion = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  return (
    <div className='w-full flex flex-col flex-auto'>
      <div
        className={clsx(
          classes.header,
          'flex flex-col flex-shrink-0 items-center justify-center text-center p-16 sm:p-24 h-100 sm:h-260'
        )}
      >
        <FuseAnimate
          animation='transition.slideUpIn'
          duration={400}
          delay={100}
        >
          <Typography color='inherit' className='text-36 sm:text-56 font-light'>
            We&lsquo;re here to help
          </Typography>
        </FuseAnimate>

        <FuseAnimate duration={400} delay={600}>
          <Typography
            variant='subtitle1'
            color='inherit'
            className='opacity-75 mt-8 sm:mt-16 mx-auto max-w-512'
          >
            Frequently asked questions
          </Typography>
        </FuseAnimate>
      </div>

      <div className='flex flex-col flex-1 flex-shrink-0 max-w-xl w-full mx-auto px-16 sm:px-24 py-24 sm:py-32'>
        {filteredData.length === 0 && (
          <div className='flex flex-auto items-center justify-center w-full h-full'>
            <Typography color='textSecondary' variant='h5'>
              There are no faqs!
            </Typography>
          </div>
        )}
        <FuseAnimateGroup
          enter={{
            animation: 'transition.slideUpBigIn',
          }}
        >
          {useMemo(() => {
            return filteredData.map((faq) => (
              <ExpansionPanel
                classes={{
                  root: classes.panel,
                  expanded: classes.expanded,
                }}
                key={faq.id}
                expanded={expanded === faq.id}
                onChange={toggleExpansion(faq.id)}
                elevation={0}
              >
                <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                  <div className='flex items-center'>
                    <Icon className='mr-8' color='action'>
                      help_outline
                    </Icon>
                    <Typography className=''>{faq.question}</Typography>
                  </div>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Typography className=''>{faq.answer}</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ));
          }, [classes, expanded])}
        </FuseAnimateGroup>
      </div>
    </div>
  );
}

export default FaqPage;
