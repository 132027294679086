import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { useDataProvider } from 'react-admin';
import { clientGQL } from '../../../dataProviders/clientGQL';

const AddMentorComponent = (props) => {
    const { setAddMentors, mappedMentors, setMappedMentors, schoolDetails } = props;
    const [searchtext, setSearchText] = useState("");
    const [foundUsers, setFoundUsers] = useState([]);
    const isMobile = window.innerWidth < 769;
    const dataProvider = useDataProvider();

    const getUsersFromFusionAuth = async () => {
        let res = await dataProvider.getList("userSamiksha-mentor", {
            pagination: { perPage: 50, page: 1 },
            sort: { field: "name", order: "asc" },
            filter: { username: searchtext, active: true },
        })
        if (res.data) setFoundUsers(res.data)
    }

    const mapUserToSchool = async (user) => {
        try {
            const userObj = {
                school_code: schoolDetails.id,
                mentor_username: user.username,
                mentor_block: user.data.roleData.block,
                mentor_mobile: user.mobilePhone
            }
            let res = await clientGQL(
                `
            mutation ($object: [school_mentor_mapping_insert_input!]! = {}) {
                insert_school_mentor_mapping(objects: $object) {
                  returning {
                    id
                    mentor_block
                    mentor_mobile
                    mentor_username
                  }
                }
              }

            `,
                { object: userObj }
            )

            if (res?.data?.insert_school_mentor_mapping?.returning?.length) {
                let newMentors = [...mappedMentors, res?.data?.insert_school_mentor_mapping?.returning?.[0]]
                newMentors.sort((a, b) => { return a.mentor_username > b.mentor_username ? 1 : -1 })
                setMappedMentors(newMentors)
            }

        } catch (err) {
            console.log(err);
        }
    }

    const isMapped = (id) => {
        return mappedMentors.find(el => el.mentor_id == id);
    }

    useEffect(() => {
        if (searchtext)
            getUsersFromFusionAuth();
    }, [searchtext])

    return (
        <div className={styles.container}>
            <input type="text" className={styles.searchbar} autoFocus placeholder="Search users here..." value={searchtext} onChange={e => setSearchText(e.target.value)} />
            <div className={styles.userContainer}>
                <div className={styles.userHeader}>
                    <div>Username</div>
                    {!isMobile && <div>Name</div>}
                    {!isMobile && <div>Block</div>}
                    <div>Status</div>
                </div>
                <div className={styles.userData} >
                    {foundUsers?.map(el => <div className={styles.dataCell}>
                        <div>{el?.username}</div>
                        {!isMobile && <div>{el?.fullName}</div>}
                        {!isMobile && <div>{el?.data?.roleData?.block}</div>}
                        <div>{isMapped(el.id) ? <span style={{ color: '#007500' }}>Mapped ✅</span> : <span onClick={() => mapUserToSchool(el)} className={styles.addMentor}>Add ➕</span>}</div>
                    </div>)}
                </div>
            </div>
            <div onClick={() => setAddMentors(false)} className={styles.submitBtn}>Done</div>
        </div >

    );
}

export default AddMentorComponent;