import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { clientGQL } from '../../dataProviders/clientGQL';
import AddMentorComponent from './AddMentorComponent';
import styles from './index.module.scss';
import { useDataProvider } from 'react-admin';
import UserDetails from './UserDetails';

const ViewMentorMapping = (props) => {
    const { showModal, schoolDetails } = props;
    const [mappedMentors, setMappedMentors] = useState([]);
    const [filteredMentors, setFilteredMentors] = useState([]);
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [searchText, setSearchText] = useState('');
    const isMobile = window.innerWidth < 498;
    const dataProvider = useDataProvider();

    // Getting all the mapped users to the selected school
    const getMappedUsers = async (user) => {
        let res = await clientGQL(
            `
            query MyQuery {
                school_mentor_mapping(where: {school_code: {_eq: "${schoolDetails.id}"}}) {
                  id
                  mentor_block
                  mentor_mobile
                  mentor_username
                }
              }
              
            `
        )
        if (res?.data?.school_mentor_mapping) setMappedMentors(res?.data?.school_mentor_mapping);
    }

    const handleUserClick = async (mapping) => {
        // let res = await dataProvider.getOne('userSamiksha-mentor', { id: mapping.mentor_id });
        let res = await dataProvider.getList('userSamiksha-mentor', {
            pagination: {
                page: 1,
                perPage: 1
            },
            sort: {
                field: 'username',
                order: 'ASC'
            },
            filter: {
                mentorUsername: [mapping.mentor_username]
            }
        });
        if (res?.data?.[0]) { setUserDetails(res?.data?.[0]); setShowUserDetails(true) }
    }

    useEffect(() => {
        getMappedUsers();
    }, [])

    useEffect(() => {
        if (searchText) {
            setFilteredMentors(mappedMentors.filter(el => el.mentor_username.startsWith(searchText)))
        } else setFilteredMentors([]);
    }, [searchText])


    return (ReactDOM.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.modalContent + " animate__animated animate__backInUp"} style={showUserDetails ? { width: '30%' } : {}}>
                {!showUserDetails && <>
                    <div className={styles.mainContent}>
                        <p>{schoolDetails?.school_name}</p>
                        <div className={styles.divider}></div>
                        <p className={styles.closeIcon} onClick={() => showModal(false)}>x</p>
                        <input type="text" className={styles.searchbar} placeholder="Filter users here..." value={searchText} onChange={e => setSearchText(e.target.value)} />
                        <p className='animate__animated animate__fadeInUp' style={{ fontWeight: 600 }}>{isMobile ? 'Tap' : 'Click'} on any username to view their details</p>

                        <div className={styles.mentorContainer}>

                            {!filteredMentors?.length && !searchText && mappedMentors?.map(el =>
                                <div className={styles.mentorCell}
                                    onClick={() => handleUserClick(el)}
                                >
                                    <p>{el.mentor_username}</p>
                                </div>)}

                            {filteredMentors?.length ? filteredMentors?.map(el =>
                                <div className={styles.mentorCell}
                                    onClick={() => handleUserClick(el)}
                                >
                                    <p>{el.mentor_username}</p>
                                </div>) : null}

                            {!mappedMentors?.length && <p style={{ margin: 'auto' }}>No mentors mapped to this school</p>}

                        </div>
                    </div>
                </>}
                {showUserDetails && <UserDetails {...{ userDetails, setUserDetails, setShowUserDetails }} />}
            </div>
        </div >, document.body)
    );
}

export default ViewMentorMapping;