/* eslint-disable no-nested-ternary */
import React, { cloneElement, useState } from 'react';
import {
  List,
  Create,
  Edit,
  Toolbar,
  TopToolbar,
  ListButton,
  CreateButton,
  EditButton,
  SaveButton,
  DeleteButton,
  FormDataConsumer,
  Datagrid,
  SingleFieldList,
  AutocompleteInput,
  Filter,
  ArrayField,
  FunctionField,
  TextField,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  ArrayInput,
  CheckboxGroupInput,
  required,
  sanitizeListRestProps,
  useQuery,
  usePermissions,
} from 'react-admin';
import { Typography, Chip, makeStyles } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@material-ui/icons/ToggleOnOutlined';
import { green } from '@material-ui/core/colors';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    border: '1px solid #ddd',
    '& div': {
      margin: '2px 0',
    },
  },
  customArrayInput: {
    '& p': {
      fontSize: '20px',
      fontWeight: 'bold',
      width: '1em',
      paddingTop: '1.3em',
    },
    '& p + section': {
      paddingTop: '1em',
      display: 'flex',
      marginBottom: '1.6rem',
    },
    '& section + span': {
      paddingTop: '1.5em',
    },
    '& ul > li:last-child button': {
      border: '1px solid #ccc',
      marginTop: '10px',
    },
  },
  customInput: {
    '& p': {
      display: 'none!important',
    },
    '& div': {
      display: 'block!important',
    },
    '& div > label > span:last-child': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      lineHeight: 2.66,
      fontWeight: 400,
      letterSpacing: '0.08333em',
    },
  },
  customTable: {
    paddingLeft: '1.6rem',
  },
  textCenter: {
    textAlign: 'center',
  },
  autocompleteInput: {
    height: '35px',
    marginTop: '20px',
    '& > div': {
      fontSize: '1.6rem',
    },
    '& > div > fieldset': {
      border: '0',
    },
  },
}));

const config = require('../../config');

// permission choices
const permissionsStaticList = [
  { id: 'create', name: 'CREATE' },
  { id: 'list', name: 'READ' },
  { id: 'edit', name: 'UPDATE' },
  { id: 'delete', name: 'DELETE' },
];

const CustomEditButton = (props) => (
  <EditButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);
const CustomDeleteButton = (props) => (
  <DeleteButton
    style={{ marginLeft: '-20px', color: '#757575' }}
    {...props}
    label=''
  />
);

const Back = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);
/**
 * List Top Toolbar
 * @param {*} props
 */
const ListActions = (props) => {
  const classes = useStyles();
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  } = props;
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      style={{ paddingTop: '10px' }}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      {/* <CreateButton basePath={basePath} className={classes.longMenu} /> */}
    </TopToolbar>
  );
};

/**
 * Filter
 * @param {*} props
 */
const RoleFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        source='applicationId'
        variant='outlined'
        className='selectInput'
        label='Applications'
        choices={config.applicationIdMap}
        allowEmpty={false}
      />
    </Filter>
  );
};

/**
 * Role list
 * @param {*} props
 */
export const RoleList = (props) => {
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  return (
    <List
      {...props}
      title='Roles List'
      filters={<RoleFilter />}
      actions={<ListActions />}
      filterDefaultValues={{
        applicationId: config.fusionAuthConsole.fusionAuthApplicationId,
      }}
      bulkActionButtons={false}
      className={classes.customTable}
    >
      <Datagrid size='medium'>
        <TextField label='Role' source='name' />
        <FunctionField
          label='Application'
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={(record) => {
            if (
              (record && record.applicationId) ===
              config.fusionAuthConsole.fusionAuthApplicationId
            ) {
              return <Chip label='Saksham Samiksha' />;
            }
            return <Chip label='Admin Console' />;
          }}
        />
        <ArrayField
          label='Allowed Resources'
          source='allows'
          headerClassName={classes.textCenter}
        >
          <SingleFieldList>
            <FunctionField
              label='Allowed Resources'
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              // eslint-disable-next-line consistent-return
              render={(record) => {
                if (
                  record &&
                  record.resourceId &&
                  record.permissions &&
                  record.permissions.length
                )
                  return <Chip label={record.resourceId.name} />;
              }}
            />
          </SingleFieldList>
        </ArrayField>
        {loaded ? (
          permissions.Roles && permissions.Roles.indexOf('edit') > -1 ? (
            <CustomEditButton {...props} label='Edit' />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {loaded ? (
          permissions.Roles && permissions.Roles.indexOf('delete') > -1 ? (
            <CustomDeleteButton {...props} label='Delete' />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Datagrid>
    </List>
  );
};

/**
 * Create new roles
 * @param {*} props
 */
export const RoleCreate = (props) => {
  const RoleCreateToolbar = (props) => {
    const { invalid } = props;
    return (
      <Toolbar {...props}>
        <SaveButton
          label='Save and proceed'
          disabled={invalid}
          redirect='edit'
        />
      </Toolbar>
    );
  };

  return (
    <div className='pl-16 pt-16'>
      <Typography variant='h6'>Role Details</Typography>
      <Create {...props} title='Create Role' actions={<Back {...props} />}>
        <SimpleForm toolbar={<RoleCreateToolbar />}>
          <div className='flex flex-row items-center' style={{ width: '70vw' }}>
            <TextInput
              label='Role Name'
              source='name'
              validate={[required()]}
              variant='outlined'
              style={{ width: '45%', marginRight: '1em' }}
            />
            <SelectInput
              source='applicationId'
              variant='outlined'
              label='Application'
              validate={[required()]}
              className='selectInput'
              choices={config.applicationIdMap}
            />
          </div>
          <TextInput
            label='Role Description'
            source='description'
            variant='outlined'
            style={{ width: '45%' }}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

/**
 * Edit role details
 * @param {*} param0
 */
// edit top toolbar
const EditAction = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const RoleEditTitle = ({ record }) => {
  return (
    <>
      <Typography
        variant='h5'
        color='textPrimary'
        style={{ display: 'inline' }}
      >
        Edit{' '}
      </Typography>
      <Typography
        variant='h5'
        color='textSecondary'
        style={{ display: 'inline' }}
      >
        {record ? record.name : ''}
      </Typography>
    </>
  );
};

// edit form
export const RoleEdit = (props) => {
  const [application, setApplication] = useState(null);
  const [resourcesUpdated, setResourcesUpdated] = useState(false);
  const [resourcesRemoved, setResourcesRemoved] = useState(false);
  const { loaded, permissions } = usePermissions();
  const classes = useStyles();
  const { data } = useQuery({
    type: 'getList',
    resource: 'resources',
    payload: {
      pagination: {},
      sort: {},
      filter: { applicationId: application },
    },
  });

  const resources =
    data &&
    data.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });

  const resourcePermissionDefaultArray =
    data &&
    data.map((resource) => {
      return {
        permissions: [],
        resourceId: {
          _id: resource && resource.id,
          applicationId: resource && resource.applicationId,
        },
      };
    });

  const roleDefaultValue = { allows: resourcePermissionDefaultArray };
  return (
    <div className='pt-16 pl-16'>
      <Typography variant='h6'>Role Details</Typography>
      <Edit
        {...props}
        title={<RoleEditTitle />}
        actions={<EditAction />}
        transform={(data) => {
          if (data.allows) {
            data.allows.forEach((allow) => {
              const resourceIdString = allow.resourceId && allow.resourceId._id;
              allow.resourceId = resourceIdString;
            });
            return data;
          }
        }}
      >
        <SimpleForm
          initialValues={roleDefaultValue || []}
          toolbar={
            loaded ? (
              permissions.Roles &&
                permissions.Roles.indexOf('delete') > -1 ? undefined : (
                <EditNoDeleteToolbar />
              )
            ) : (
              <EditNoDeleteToolbar />
            )
          }
        >
          <div className='flex flex-row' style={{ width: '70vw' }}>
            <TextInput
              label='Role Name'
              source='name'
              validate={[required()]}
              variant='outlined'
              style={{ width: '45%', marginRight: '1rem' }}
            />
            <SelectInput
              label='Application'
              source='applicationId'
              validate={[required()]}
              disabled
              choices={config.applicationIdMap}
              variant='outlined'
              style={{ width: '30%', marginRight: '1rem' }}
            />
          </div>
          <TextInput
            label='Role Description'
            source='description'
            variant='outlined'
            style={{ width: '45%', marginRight: '1rem' }}
          />
          {/* resource-permission iterator */}
          <ArrayInput
            label='Manage Permissions'
            source='allows'
            variant='outlined'
            className={classes.customArrayInput}
          >
            <SimpleFormIterator disableAdd disableRemove>
              <AutocompleteInput
                source='resourceId._id'
                label=''
                choices={resources || []}
                className={classes.autocompleteInput}
                disabled
              />
              <CheckboxGroupInput
                label=''
                style={{ width: '30vw', marginLeft: '5rem', marginTop: '2rem' }}
                source='permissions'
                choices={permissionsStaticList}
                className={classes.customInput}
                options={{
                  icon: <ToggleOffOutlinedIcon />,
                  checkedIcon: (
                    <ToggleOnOutlinedIcon style={{ color: green[500] }} />
                  ),
                }}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData && formData.applicationId)
                setApplication(formData.applicationId);
              if (formData && formData.allows) {
                if (resourcePermissionDefaultArray && !resourcesUpdated) {
                  resourcePermissionDefaultArray.forEach((resource) => {
                    if (
                      formData.allows.findIndex(
                        (allow) =>
                          (allow.resourceId && allow.resourceId._id) ===
                          (resource.resourceId && resource.resourceId._id)
                      ) === -1 &&
                      (resource.resourceId &&
                        resource.resourceId.applicationId) === application
                    ) {
                      formData.allows.push(resource);
                    }
                    setResourcesUpdated(true);
                  });
                }
                if (!resourcesRemoved) {
                  formData.allows.forEach((allow, index) => {
                    if (!allow.resourceId) formData.allows.splice(index, 1);
                  });
                  setResourcesRemoved(true);
                }
              }
              return <></>;
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </div>
  );
};
