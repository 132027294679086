import { store } from '../e/utils/localsotage.service';
import { client } from './api-clients/users-client';
import * as crypto from 'crypto';
const CryptoJS = require('crypto-js');
const AES = require('crypto-js/aes');
const axios = require('axios');
const { FusionAuthClient } = require('../@fusionauth/node-client');
const base64Key = process.env.REACT_APP_BASE_64_KEY;

const decode = (bytestream) => {
  const decoder = new TextDecoder();
  return decoder.decode(bytestream);
};

const encode = (data) => {
  const encoder = new TextEncoder();
  return encoder.encode(data);
};

const generateIv = () => {
  return crypto.randomBytes(12);
};

const generateAad = () => {
  return crypto.randomBytes(16);
};

export const pack = (buffer) => {
  return Buffer.from(buffer).toString('base64');
};

export const unpack = (packed) => {
  return new Uint8Array(Buffer.from(packed, 'base64'));
};


function base64ToArrayBuffer(base64String) {
  const cleanedBase64String = base64String
    .replace(/[\r\n]+/g, '')
    .replace(/=/g, '');
  const binaryString = Buffer.from(cleanedBase64String, 'base64').toString(
    'binary',
  );

  const buffer = new ArrayBuffer(binaryString.length);
  const byteArray = new Uint8Array(buffer);

  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  return buffer;
}

function importCryptoKey(base64Key) {
  return new Promise((resolve, reject) => {
    try {
      const arrayBuffer = base64ToArrayBuffer(base64Key);
      const keyBuffer = Buffer.from(arrayBuffer);
      resolve(keyBuffer);
    } catch (error) {
      reject(error);
    }
  });
}

export const encrypt = async (data, base64Key) => {
  const key = await importCryptoKey(base64Key);
  const encoded = encode(data);
  const iv = generateIv();
  const aad = generateAad();
  const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
  cipher.setAAD(aad);
  const encrypted = Buffer.concat([cipher.update(encoded), cipher.final()]);
  const authTag = cipher.getAuthTag();
  return {
    cipher: encrypted,
    iv,
    authTag,
    aad
  };
};

export const decrypt = async (cipher, base64Key, iv, authTag, aad) => {
  const key = await importCryptoKey(base64Key);
  const decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
  decipher.setAuthTag(authTag);
  decipher.setAAD(aad);
  const decrypted = Buffer.concat([decipher.update(cipher), decipher.final()]);
  return decode(new Uint8Array(decrypted));
};


function authProvider(config) {
  const fusionAuthclient = new FusionAuthClient(
    config.fusionAuthAPIKey,
    config.fusionAuthURL
  );

  // eslint-disable-next-line consistent-return
  const getToken = async (userName, password) => {
    const userEncrypt = await encrypt(userName, base64Key);
    const passEncrypt = await encrypt(password, base64Key);
    const body = {
      password: `${pack(passEncrypt.cipher)}:${pack(passEncrypt.iv)}:${pack(passEncrypt.authTag)}:${pack(passEncrypt.aad)}`,
      loginId: `${pack(userEncrypt.cipher)}:${pack(userEncrypt.iv)}:${pack(userEncrypt.authTag)}:${pack(userEncrypt.aad)}`,
      applicationId: config.fusionAuthApplicationId,
    };
    const response = await client.post("/api/secure-login-samiksha", body);
    // const response = await client.post("/api/login", body);
    return response;
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const validateResponse = async (response) => {
    console.log("Login Reponse ->", { response })
    if (response.status === 201 || 200) {
      if (response?.data?.responseCode == 'FAILURE') {
        throw new Error(response?.data?.params?.errMsg)
      }
      // Check for timeStamp
      // let ts = response.headers.get('X-Timestamp');
      let ts = response?.data?.encryptedTimeStamp;
      ts = ts?.split(":");
      console.log("ts ->", ts)

      let cipher = Buffer.from(unpack(ts[0]));
      let iv = Buffer.from(unpack(ts[1]));
      let authTag = Buffer.from(unpack(ts[2]));
      let aad = Buffer.from(unpack(ts[3]));
      try {
        const dts = await decrypt(cipher, base64Key, iv, authTag, aad);
        const responseDate = new Date(dts);
        // await delay(4000)
        const currentDate = new Date();
        if (((currentDate.getTime() - responseDate.getTime()) / 1000) > 5) {
          // X-Timestamp header is greater than 5 seconds, failure login
          return Promise.reject(new Error('Timestamp is older than 5 seconds'));
        }

        console.log("Validated", response)
      } catch (err) {
        console.log(err);
      }

      // Check if the applicationID is there
      const isValidUser = response?.data?.result?.data?.user?.user?.registrations
        .map((s) => s.applicationId)
        .includes(config.fusionAuthApplicationId) || response.successResponse.user.registrations
          .map((s) => s.applicationId)
          .includes(config.fusionAuthApplicationId);

      console.log({ isValidUser })

      // const isAdmin = response.successResponse.user.registrations.filter(
      //   (s) => {
      //     if (s.roles !== undefined)
      //       return (
      //         s.roles.includes('Admin') || s.roles.includes('external_user')
      //       );
      //     return false;
      //   }
      // );
      console.log("Entering is valid user block")

      if (isValidUser) {
        if (response?.data?.result?.data?.user?.token !== undefined) {
          console.log("Inside first if")
          // Setting token to localstorage
          localStorage.setItem('authToken', response?.data?.result?.data?.user?.token)
          return store
            .setItem('user', response?.data?.result?.data?.user)
            .then(() => Promise.resolve('Successfully Logged In'))
            .catch((e) => Promise.reject(e));
        }
        if (response?.successResponse?.token !== undefined) {
          console.log("Inside second if")
          return store
            .setItem('user', response.successResponse)
            .then(() => Promise.resolve('Successfully Logged In'))
            .catch((e) => Promise.reject(e));
        }
        Promise.resolve('Successfully Logged In');
      } else {
        return Promise.reject(new Error('User Invalid'));
      }
    } else {
      return Promise.reject(new Error('Invalid Credentials'));
    }
  };

  return {
    login: (params) => {
      const { username, password } = params;
      // store.setItem('username', username);
      // store.setItem('password', password);
      return getToken(username, password)
        .then(validateResponse)
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    logout: () => {
      store.removeItem('user');
      localStorage.setItem('authToken', '')
      localStorage.setItem('fr', '')
      return Promise.resolve();
    },
    checkAuth: () => {
      return store.getItem('user').then((user) => {
        if (user) {
          return Promise.resolve();
          // return fusionAuthclient
          //   .retrieveUserUsingJWT(user.token)
          //   .then(validateResponse)
          //   .catch((e) => {
          //     return Promise.reject(e);
          //   });
        }
        return Promise.reject(new Error('User Credentials not found'));
      });
    },
    checkError: (error) => {
      const { status } = error;
      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getPermissions: () => {
      return store
        .getItem('user')
        .then(async (user) => {
          console.log(user)
          if (user) {
            // const roleId =
            //   user.user &&
            //   user.user.data &&
            //   user.user.data[config.fusionAuthApplicationIdTemp].roleId;

            // temp jugaad 
            let roleId = "5fbbc69b29ffb6772a5c63e8";
            const permissionsResponse = {
              data: {
                "Mentoring Feedback Survey": [
                  "list"
                ],
                "SAT Visit": [
                  "list"
                ],
                "Bot Data": [
                  "list"
                ],
                "Secondary Monitor Visit": [
                  "list"
                ],
                "School List": [
                  "edit",
                  "create",
                  "list"
                ],
                "Employee Temp. and Attendance": [
                  "list"
                ],
                "Samiksha Users": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Secondary SSA Visit": [
                  "list"
                ],
                "Manage Console Users": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Sampark Program Visit": [
                  "list"
                ],
                "User Segments": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "SAT Visit 2": [
                  "list"
                ],
                "SLO Visit V2": [
                  "list"
                ],
                "Roles": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Conversations": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Resources": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "SLO Visit V1": [
                  "list"
                ],
                "Students": [
                  "edit",
                  "create",
                  "list"
                ],
                "null": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Defaulter School": [
                  "list"
                ],
                "COVID Positive Students": [
                  "list"
                ],
                "FAQs": [
                  "list"
                ],
                "CMO Dashboard": [
                  "edit",
                  "delete",
                  "create",
                  "list"
                ],
                "Elementary Monitor Visit": [
                  "list"
                ],
                "Secondary Mentor Visit": [
                  "list"
                ],
                "Elementary Mentor Visit": [
                  "list"
                ],
                "Student Temp. and Attendance": [
                  "list"
                ],
                "COVID Positive Employees": [
                  "list"
                ],
                "Message Template": [
                  "create",
                  "list"
                ],
                "Elementary SSA Visit": [
                  "list"
                ]
              }
            }
            // const permissionsResponse = await axios.get(
            //   `${config.authServerBasePath}/allowedResources/${config.fusionAuthApplicationId}`,
            //   { params: { roleId } }
            // );
            store.setItem('permissions', permissionsResponse.data);

            return permissionsResponse
              ? Promise.resolve(permissionsResponse.data)
              : Promise.reject(new Error('error'));
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('User not available');
        })
        .catch((e) => Promise.reject(e));
    },
  };
}

export default authProvider;
