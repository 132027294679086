import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';

const UserDetails = (props) => {
    const { userDetails, setUserDetails, setShowUserDetails } = props;
    console.log(userDetails)

    const handleBack = () => {
        setUserDetails({});
        setShowUserDetails(false);
    }
    return (
        <div className={styles.container}>
            <p>{userDetails.username}</p>
            <div className={styles.divider}></div>
            <div className={styles.infoContainer}>
                <div className='animate__animated animate__fadeInLeft'>
                    <p>Name</p>
                    <p>Email </p>
                    <p>Status</p>
                    <p>Mobile </p>
                    <p>Designation</p>
                    <p>District</p>
                    <p>Block</p>
                    <p>Cluster</p>
                </div>
                <div className='animate__animated animate__fadeInRight'>
                    <p>{userDetails.fullName || "------"}</p>
                    <p>{userDetails.email || "------"}</p>
                    <p>{userDetails.active ? "Active" : 'Disabled'}</p>
                    <p>{userDetails.mobilePhone || "------"}</p>
                    <p>{userDetails.data?.roleData?.designation || "------"}</p>
                    <p>{userDetails?.data?.roleData?.district || "------"}</p>
                    <p>{userDetails.data?.roleData?.block || "------"}</p>
                    <p>{userDetails.data?.roleData?.cluster || "------"}</p>
                </div>
            </div>
            <div className={styles.submitBtn} onClick={handleBack}>Go Back</div>
        </div >

    );
}

export default UserDetails;