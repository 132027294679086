/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, cloneElement } from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SimpleForm,
  TextField,
  BooleanField,
  NumberInput,
  TextInput,
  SelectInput,
  SearchInput,
  CreateButton,
  BooleanInput,
  ExportButton,
  useDataProvider,
  downloadCSV,
  TopToolbar,
  FormDataConsumer,
  AutocompleteInput,
  required,
  usePermissions,
  FunctionField
} from 'react-admin';
import EditNoDeleteToolbar from './EditNoDeleteToolbar';
import Button from '@material-ui/core/Button'
import jsonExport from 'jsonexport/dist';
import { sanitizeListRestProps } from 'ra-core';
import { districts, blocks, clusters, districtMap, blockMap } from '../resources/LocationData';
import ManageTeacherMapping from './ManageTeacherMapping';
import PublishIcon from '@material-ui/icons/Publish';
import UpdateMentorsModal from './UpdateMentorsModal';
import SchoolMentorPopup from './SchoolMentorPopup';

import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewMentorMapping from './ViewMentorMapping';
// choices
const districtChoices = require('../../meta/district.json');
const blockChoices = require('../../meta/block.json');
const isMobile = window.innerWidth < 769;


const toChoices = (items) => {
  return items?.map((item) => ({ id: item, name: item })) || [];
}


/**
 * Filter
 * @param {*} props
 */
const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder='Search'
      source='q'
      className='searchBar'
      alwaysOn
    />
    <SelectInput
      label='By School Type'
      source='school_type'
      choices={[
        { id: 'Aarohi', name: 'Aarohi' },
        { id: 'GMSSSS', name: 'GMSSSS' },
        { id: 'High', name: 'High' },
        { id: 'KGBV', name: 'KGBV' },
        { id: 'Middle', name: 'Middle' },
        { id: 'Primary', name: 'Primary' },
        { id: 'Sr. Secondary', name: 'Sr. Secondary' },
      ]}
      className='autocompleteInput'
    />
    <AutocompleteInput
      label='By District'
      source='district'
      choices={districtChoices}
      className='autocompleteInput'
    />
    <AutocompleteInput
      label='By Block'
      source='block'
      choices={blockChoices}
      className='autocompleteInput'
    />
    <AutocompleteInput
      label='By Cluster'
      source='cluster'
      choices={clusters.sort().map(el => { return { id: el, name: el } })}
      className='autocompleteInput'
    />
    <SelectInput
      label='By Reason For Inactive'
      source='reason_for_inactive'
      choices={toChoices(['Closed', 'Merged'])}
      className='autocompleteInput'
    />
    <BooleanInput label='Is Active' source='is_active' />
  </Filter>
);

/**
 * List all schools
 * @param {*} props
 */
export const SchoolList = (props) => {
  const [importModal, showImportModal] = useState(false);
  const [schoolPopup, showSchoolPopup] = useState(false);
  const [mentorModal, showMentorModal] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState({});
  const exporter = records => {
    const recordsForExports = records.map(rec => {
      const recForExport = {
        'School Code': rec.id,
        'UDISE Code': rec?.udise_code,
        'School Name': rec?.school_name,
        'District': rec?.district,
        'Block': rec?.block,
        'Cluster': rec?.cluster,
        'School Type': rec?.school_type,
        'Status': rec?.is_active ? "Active" : "Disabled",
        'Reason For Inactive': rec?.reason_for_inactive,
        'Mentor(s) Username': rec?.school_mentor_mapping?.map(el => el.mentor_username)?.join(","),
        "Mentor(s) Block": rec?.school_mentor_mapping?.map(el => el.mentor_block)?.join(","),
        "Mentor(s) Mobile": rec?.school_mentor_mapping?.map(el => el.mentor_mobile)?.join(","),
      }
      return recForExport;
    });
    jsonExport(recordsForExports, {
      headers: ['School Code', 'UDISE Code', 'School Name', 'District', 'Block', 'Cluster', 'School Type', 'Status', 'Reason For Inactive']
    }, (err, csv) => {
      downloadCSV(csv, 'School List');
    });
  };

  const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ImportButton />
      <ExportButton maxResults={10000} />
    </TopToolbar>
  );

  const ImportButton = () => {
    return <span className='import-button' onClick={() => showImportModal(!importModal)}><PublishIcon style={{ height: isMobile ? 22 : 20, width: isMobile ? 22 : 20 }} /> &nbsp;{window.innerWidth < 769 ? '' : 'IMPORT'}</span>
  }

  useEffect(() => {
    let schoolPopupDone = sessionStorage.getItem("schoolPopupDone");
    if (!schoolPopupDone) {
      sessionStorage.setItem("schoolPopupDone", true);
      showSchoolPopup(true);
    }
  }, [])

  return <>
    {importModal && <UpdateMentorsModal {...{ showImportModal }} />}
    {schoolPopup && <SchoolMentorPopup {...{ showSchoolPopup }} />}
    {mentorModal && <ViewMentorMapping showModal={showMentorModal} schoolDetails={schoolDetails} />}
    <List
      {...props}
      filters={<SearchFilter />}
      actions={< ListActions />}
      sort={{ field: 'school_code', order: 'ASC' }}
      title='School List'
      exporter={exporter}
      className='customBox'
    >
      <Datagrid >
        <TextField label='School Code' source='id' />
        <TextField label='UDISE' source='udise_code' />
        <TextField label='School Name' source='school_name' />
        <TextField label='District' source='district' />
        <TextField label='Block' source='block' />
        <TextField label='Cluster' source='cluster' />
        <TextField label='School Type' source='school_type' />
        <FunctionField label='Status' source='is_active' render={(record) => { return <span>{record.is_active ? 'Active' : 'Disabled'}</span> }} />
        <FunctionField label='Mentors' render={(record) => { return <span style={{ cursor: 'pointer' }} onClick={() => { setSchoolDetails(record); showMentorModal(true); }}><VisibilityIcon /></span> }} />
        <TextField label='Reason For Inactive' source='reason_for_inactive' />
        <EditButton />
      </Datagrid>
    </List></>
}

/**
 * Create new school
 * @param {*} props
 */
export const SchoolCreate = (props) => {
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedBlock, setSelectedBlock] = useState();
  const [clusterChoices, setClusterChoices] = useState(toChoices(clusters));
  const [blockChoices, setBlockChoices] = useState(toChoices(blocks));
  const dataProvider = useDataProvider();

  const validateUdise = async (val) => {
    if (!val) return "Please enter a valid UDISE";
    const res = await dataProvider.getList("school", {
      pagination: { perPage: 1, page: 1 },
      sort: { field: "school_code", order: "asc" },
      filter: { udise_code: val },
    })
    if (res?.data?.length)
      return "A school already exists for this UDISE"
    return undefined;
  }

  useEffect(() => {
    if (selectedDistrict) {
      setBlockChoices(toChoices(districtMap[selectedDistrict]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedDistrict])

  useEffect(() => {
    if (selectedBlock) {
      setClusterChoices(toChoices(blockMap[selectedBlock]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedBlock])

  console.log(clusterChoices)

  return < Create {...props} className='customBox' >
    <SimpleForm>
      <TextInput
        label='School Code'
        source='school_code'
        validate={[required()]}
        variant='outlined'
      />
      <TextInput
        label='UDISE Code'
        source='udise_code'
        validate={validateUdise}
        variant='outlined'
      />
      <TextInput
        label='School Name'
        source='school_name'
        validate={[required()]}
        variant='outlined'
      />
      <SelectInput
        label='District'
        source='district'
        choices={toChoices(districts) || []}
        validate={[required()]}
        perPage={100}
        onChange={(e) => setSelectedDistrict(e.target.value)}
        variant='outlined'
      />
      <SelectInput
        label='Block'
        source='block'
        validate={[required()]}
        choices={blockChoices}
        onChange={(e) => setSelectedBlock(e.target.value)}
        perPage={200}
        variant='outlined'
      />
      <SelectInput
        label='Cluster'
        source='cluster'
        choices={clusterChoices}
        perPage={200}
        variant='outlined'
      />
      <SelectInput
        label='School Type'
        source='school_type'
        choices={[
          { id: 'Aarohi', name: 'Aarohi' },
          { id: 'GMSSSS', name: 'GMSSSS' },
          { id: 'High', name: 'High' },
          { id: 'KGBV', name: 'KGBV' },
          { id: 'Middle', name: 'Middle' },
          { id: 'Primary', name: 'Primary' },
          { id: 'Sr. Secondary', name: 'Sr. Secondary' },
        ]}
        validate={[required()]}
        variant='outlined'
      />
      <BooleanInput label='Is Active' source='is_active' defaultValue={true} disabled />
      {/* <FormDataConsumer>
        {({ formData }) => {
          console.log(formData)
          if (!formData.is_active)
            return <TextInput
              label='Reason For Inactive'
              source='reason_for_inactive'
              variant='outlined'
            />
        }}
      </FormDataConsumer> */}
    </SimpleForm>
  </Create >
};

/**
 * Edit school details
 * @param {*} props
 */
export const SchoolEdit = (props) => {
  const { loaded, permissions } = usePermissions();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedBlock, setSelectedBlock] = useState();
  const [clusterChoices, setClusterChoices] = useState(toChoices(clusters));
  const [blockChoices, setBlockChoices] = useState(toChoices(blocks));
  const dataProvider = useDataProvider();
  const initialUdise = useRef();
  const [mappingModal, showMappingModal] = useState(false);

  useEffect(() => {
    if (selectedDistrict) {
      setBlockChoices(toChoices(districtMap[selectedDistrict]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedDistrict])

  useEffect(() => {
    if (selectedBlock) {
      setClusterChoices(toChoices(blockMap[selectedBlock]))
    }
    else
      setBlockChoices(toChoices(blocks));
  }, [selectedBlock])

  const validateUdise = async (val) => {
    if (!val) return "Please enter a valid UDISE";
    if (!(/^\d+$/.test(val))) {
      return "UDISE can only be number";
    }
    const res = await dataProvider.getList("school", {
      pagination: { perPage: 1, page: 1 },
      sort: { field: "school_code", order: "asc" },
      filter: { udise_code: val },
    })
    if (res?.data?.length && val != initialUdise.current)
      return "A school already exists for this UDISE"
    return undefined;
  }

  return (
    <Edit {...props} className='customBox' mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          loaded ? (
            permissions['School List'] &&
              permissions['School List'].indexOf('delete') > -1 ? undefined : (
              <EditNoDeleteToolbar />
            )
          ) : (
            <EditNoDeleteToolbar />
          )
        }
      >
        <TextInput
          label='School Code'
          source='id'
          disabled
          variant='outlined'
        />
        <TextInput
          label='School Name'
          source='school_name'
          validate={[required()]}
          variant='outlined'
        />
        <TextInput
          label='UDISE Code'
          source='udise_code'
          validate={validateUdise}
          variant='outlined'
        />
        <SelectInput
          label='District'
          source='district'
          choices={toChoices(districts)}
          perPage={100}
          variant='outlined'
        />
        <SelectInput
          label='Block'
          source='block'
          choices={blockChoices}
          perPage={200}
          variant='outlined'
        />
        <SelectInput
          label='Cluster'
          source='cluster'
          choices={clusterChoices}
          perPage={200}
          variant='outlined'
        />
        <SelectInput
          label='School Type'
          source='school_type'
          choices={[
            { id: 'Aarohi', name: 'Aarohi' },
            { id: 'GMSSSS', name: 'GMSSSS' },
            { id: 'High', name: 'High' },
            { id: 'KGBV', name: 'KGBV' },
            { id: 'Middle', name: 'Middle' },
            { id: 'Primary', name: 'Primary' },
            { id: 'Sr. Secondary', name: 'Sr. Secondary' },
          ]}
          validate={[required()]}
          variant='outlined'
        />
        <BooleanInput label='Is Active' source='is_active' />
        <FormDataConsumer>
          {({ formData }) => {
            console.log(formData)
            if (!formData.is_active)
              return <SelectInput label='Reason For Inactive' validate={[required()]} variant="outlined" source='reason_for_inactive' choices={toChoices(['Merged', 'Closed'])} />

          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData?.udise_code && !initialUdise.current) {
              initialUdise.current = formData.udise_code
            }
            if (blockChoices.length > 0 && formData?.block) {
              const blockExists = blockChoices.filter(el => el.name == formData.block).length > 0;
              if (!blockExists) {
                setBlockChoices([...blockChoices, { id: formData.block, name: formData.block }])
              }
            }
          }}
        </FormDataConsumer>
        <Button variant='contained' onClick={() => showMappingModal(!mappingModal)}>Manage Mentor Mapping</Button>
        <FunctionField label=" " render={(record) => {

          return <>{mappingModal && <ManageTeacherMapping showModal={showMappingModal} schoolDetails={record} />}</>

        }} />
      </SimpleForm>
    </Edit>
  );
};
