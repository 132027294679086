import React, { createElement } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import FuseAnimate from '../../fuse-base/@fuse/core/FuseAnimate';
import { SearchIcon } from '../../fuse-base/components/all.components';

function UnauthorizedPage() {
  return (
    <div className='flex flex-col flex-1 items-center justify-center p-16'>
      <div className='max-w-512 text-center'>
        <FuseAnimate animation='transition.expandIn' delay={100}>
          <Typography variant='h1' color='black' className='font-medium mb-16'>
            403
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant='h5' color='textSecondary' className='mb-16'>
            You are not authorized to perform this action on the requested
            resource.
          </Typography>
        </FuseAnimate>

        <Paper
          className='flex items-center w-full h-56 p-16 mt-48 mb-16'
          elevation={1}
        >
          {createElement(SearchIcon)}
          <Input
            placeholder='Search for anything'
            className='px-16'
            disableUnderline
            fullWidth
            inputProps={{
              'aria-label': 'Search',
            }}
          />
        </Paper>

        <Link className='font-medium' to='/'>
          Go back to dashboard
        </Link>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
