import { useState, useEffect } from 'react';

const util = require('util');
const { parseString } = require('xml2js');
const DigestFetch = require('digest-fetch');

export default function useODKForms() {
  const [forms, setForms] = useState([]);
  const client = new DigestFetch('samagra', 'impact@scale', {
    logger: console,
  });

  useEffect(() => {
    client
      .fetch(
        'https://cors-anywhere.herokuapp.com/http://aggregate.cttsamagra.xyz:8080/formList'
      )
      .then((resp) => resp.text())
      .then(async (data) => {
        data = await util.promisify(parseString)(data);
        let flatForms = [];
        if (data && data.forms && data.forms.form) {
          flatForms = data.forms.form.map((f) => {
            return { name: f._, id: `${f.$ && f.$.url.split('=')[1]}` };
          });
        }
        setForms(flatForms);
      })
      .catch((e) => console.log('DIGEST FETCH', e));
  }, []);

  return forms;
}
